<template>
  <div class="content-md margin-auto mt-4">
    <v-skeleton-loader
      class="skeleton-loader--cloud-apps"
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.CLOUD_APPS_SETTINGS"
    >
      <template v-if="connectedServices.length">
        <div class="text-indigo-medium mb-4">
          {{ $t("accessPermissions.description") }}
        </div>
        <div v-for="item in connectedServices" :key="item.name">
          <div class="mt-4">
            <div class="settings-header-with-btn">
              <div class="mb-2 mt-4 d-flex align-center">
                <coro-icon :icon-name="item.name" class="service-icon--large mr-1"></coro-icon>
                <span class="subtitle1">{{
                  $t("accessPermissions.title", {
                    service: $t(`services.${item.name}`),
                  })
                }}</span>
              </div>
              <v-menu offset-y>
                <template v-slot:activator="{ props }">
                  <v-btn
                    color="primary"
                    rounded
                    size="large"
                    class="add-btn"
                    data-testid="access-restrictions-page-add-btn"
                    v-bind="props"
                  >
                    <v-icon color="white" icon="$add" class="mr-2"></v-icon>
                    <span>{{ $t("accessPermissions.addRestriction") }}</span>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="menuItem in addMenuItems(item.name)"
                    :key="menuItem.type"
                    @click="onAddActionClick(menuItem)"
                  >
                    <v-list-item-title data-testid="access-restrictions-add-all-users-action">
                      {{ $t(`accessPermissions.types.${menuItem.type}`) }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <v-progress-linear
              :style="{ visibility: loading[item.name] ? 'visible' : 'hidden' }"
              indeterminate
              height="2px"
            />
            <table-wrapper class="access-restrictions-table__wrapper">
              <v-table class="white" :class="{ 'table-no-data': !permissions[item.name]?.length }">
                <template #default>
                  <thead>
                    <tr>
                      <th></th>
                      <th class="text-left caption">
                        {{ $t("accessPermissions.table.headers.groups") }}
                      </th>
                      <th class="text-left caption">
                        {{ $t("accessPermissions.table.headers.restrictions") }}
                      </th>
                      <th class="text-left caption">
                        {{ $t("accessPermissions.table.headers.remediation") }}
                      </th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>
                  <draggable
                    v-model="permissions[item.name]"
                    item-key="restrictionId"
                    tag="tbody"
                    @end="onDropEvent(permissions[item.name], item.name)"
                    handle=".drag-handle"
                  >
                    <template v-slot:item="{ element, index }">
                      <tr>
                        <td><v-icon class="drag-handle" icon="$drag"></v-icon></td>
                        <td class="content-row">
                          <span
                            class="d-flex align-center"
                            v-if="element.monitoredScope.type === 'all'"
                          >
                            <coro-icon icon-name="coronet" class="service-icon mr-2" />
                            <span class="subtitle2 vertical-align-top">{{
                              $t("accessPermissions.table.allUsers")
                            }}</span>
                          </span>
                          <template v-else-if="element.monitoredScope.type === 'group'">
                            <span
                              v-for="(group, groupIndex) in element.monitoredScope.groups"
                              :key="group.groupId"
                            >
                              <span v-if="groupIndex !== 0" class="body2 vertical-align-top mr-2"
                                >,</span
                              >
                              <br v-if="groupIndex !== 0 && groupIndex % 2 === 0" />
                              <coro-icon :icon-name="group.serviceName" class="service-icon mr-2" />
                              <span class="subtitle2 vertical-align-top">{{
                                group.groupName
                              }}</span>
                            </span>
                          </template>
                          <template v-else>
                            <span
                              v-for="(user, userIndex) in element.monitoredScope.users"
                              :key="user.userId"
                            >
                              <span v-if="userIndex !== 0" class="body2 vertical-align-top mr-2"
                                >,
                              </span>
                              <br v-if="userIndex !== 0 && userIndex % 2 === 0" />
                              <span class="subtitle2 vertical-align-top">{{ user.email }}</span>
                            </span>
                          </template>
                        </td>
                        <td class="content-row">
                          <template v-if="element.restrictions.type === 'country'">
                            <span
                              v-for="(country, countryIndex) in element.restrictions
                                .allowedCountries"
                              :key="country"
                            >
                              <span v-if="countryIndex !== 0" class="body2">, </span>
                              <br v-if="countryIndex !== 0 && countryIndex % 2 === 0" />
                              <span class="body2">{{ getCountryOrStateName(country) }}</span>
                            </span>
                          </template>
                          <template v-else>
                            <span
                              v-for="(ip, ipIndex) in element.restrictions.allowedIps"
                              :key="ip"
                            >
                              <span v-if="ipIndex !== 0" class="body2">, </span>
                              <br v-if="ipIndex !== 0 && ipIndex % 2 === 0" />
                              <span class="body2">{{ ip }}</span>
                            </span>
                          </template>
                        </td>
                        <td class="content-row body2">
                          {{
                            $t(`accessPermissions.table.remediationTypes.${element.remediation}`)
                          }}
                        </td>
                        <td class="text-right" @click="$event.stopPropagation()">
                          <v-menu bottom left>
                            <template v-slot:activator="{ props }">
                              <v-btn
                                dark
                                icon
                                :data-testid="`access-restrictions-page-actions-btn-row-${index}`"
                                v-bind="props"
                              >
                                <v-icon icon="$dots"></v-icon>
                              </v-btn>
                            </template>

                            <v-list>
                              <v-list-item
                                v-for="action in itemActions"
                                :key="action"
                                :data-testid="`access-restrictions-page-restriction-${action}-action`"
                                @click="onActionClick(element, action)"
                              >
                                <v-list-item-title>
                                  {{ $t(`accessPermissions.actions.${action}`) }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </template>
                  </draggable>
                </template>
              </v-table>
            </table-wrapper>
          </div>
        </div>
      </template>
      <empty-state v-else class="margin-auto mt-12 w-65">
        <template #icon>
          <v-icon :size="80" icon="$cloudApps"> </v-icon>
        </template>
        <template #description>
          <div class="headline5 mb-3 mt-3">
            {{ $t("cloudSecurity.noItems.title") }}
          </div>
        </template>
        <template #subtitle>
          {{ $t("cloudSecurity.noItems.description") }}
        </template>
        <template #button>
          <v-btn
            class="mb-4"
            color="primary"
            rounded
            size="large"
            :to="{
              name: RouteName.CLOUD_APPS_PAGE,
              query: { noServicesConnected: 'true' },
            }"
          >
            {{ $t("cloudSecurity.noItems.connectBtn") }}
          </v-btn>
        </template>
      </empty-state>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import {
  AccessPermissionAction,
  AccessPermissionType,
  Service,
  ServiceAction,
} from "@/constants/cloud-apps";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";

import TableWrapper from "@/components/TableWrapper.vue";
import CoroIcon from "@/components/CoroIcon.vue";
import { useI18n } from "vue-i18n";
import EmptyState from "@/components/EmptyState.vue";
import { useRouter } from "vue-router";
import { useCloudAppsStore } from "@/_store/cloud-apps/cloud-apps.module";
import { storeToRefs } from "pinia";
import { defineComponent, onMounted } from "vue";

import { RouteName } from "@/constants/routes";
import {
  type AccessPermissionItem,
  useAccessPermissionsStore,
} from "@/_store/cloud-apps/access-permissions.module.ts";
import { useDialogsStore } from "@/_store/dialogs.module.ts";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  getCountryOrStateName,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils.ts";
import { RolePermissionsScope } from "@/_store/roles.module.ts";
import { SubscriptionModule } from "@/constants/workplaces.ts";
import AddAccessPermissionModal from "@/components/modals/AddAccessPermissionModal.vue";
import { ModalWidth } from "@/constants/modals.ts";
import { countriesList, usaStatesList } from "@/constants/countries.ts";
import draggable from "vuedraggable";

export default defineComponent({
  components: {
    EmptyState,
    TableWrapper,
    CoroIcon,
    draggable,
  },
  setup() {
    const i18n = useI18n();
    const router = useRouter();
    const cloudAppsStore = useCloudAppsStore();
    const { connectedServices, showSkeletonLoader } = storeToRefs(cloudAppsStore);
    const goToAccessPermissions = (service: Service) => {
      router.push({
        name: RouteName.CLOUD_SECURITY_ACCESS_PERMISSIONS_PAGE,
        query: { service },
      });
    };
    const accessPermissionsStore = useAccessPermissionsStore();
    const dialogStore = useDialogsStore();
    const { permissions, loading } = storeToRefs(accessPermissionsStore);
    const actionNotAllowed = isWorkspaceFrozenOrActionRestricted(
      RolePermissionsScope.PROTECTION,
      SubscriptionModule.CLOUD_SECURITY
    );

    onMounted(async () => {
      await cloudAppsStore.getServices();

      await Promise.all(
        connectedServices.value.map((service) =>
          accessPermissionsStore.getAccessPermissions(service.name)
        )
      );
    });

    const addMenuItems = (service: Service) => {
      return [
        {
          action: AccessPermissionAction.ADD,
          service,
          type: AccessPermissionType.ALL_USERS,
        },
        {
          action: AccessPermissionAction.ADD,
          service,
          type: AccessPermissionType.SPECIFIC_GROUPS,
        },
        {
          action: AccessPermissionAction.ADD,
          service,
          type: AccessPermissionType.SPECIFIC_USERS,
        },
      ];
    };

    const onAddActionClick = (item: {
      action: AccessPermissionAction;
      service: Service;
      type: AccessPermissionType;
    }) => {
      const dialogConfig = componentDialogsConfigConstructor({
        action: item.action,
        item: {
          type: item.type,
          serviceName: item.service,
        },
        component: AddAccessPermissionModal,
        scrollable: true,
        callback: accessPermissionsStore.addAccessPermissions,
        width: ModalWidth.LARGE,
        disable: actionNotAllowed,
      });
      dialogStore.openDialog({
        ...dialogConfig,
        header: {
          ...dialogConfig.header,
          title: i18n.t(`modals.${item.action}.title`, {
            type: i18n.t(`accessPermissions.types.${item.type}`),
          }),
        },
      });
    };

    const onActionClick = (item: AccessPermissionItem, action: AccessPermissionAction) => {
      switch (action) {
        case AccessPermissionAction.DELETE:
          dialogStore.openDialog(
            confirmationDialogsConfigConstructor({
              action,
              item,
              callback: () => accessPermissionsStore.deleteAccessPermissions(item),
              disable: actionNotAllowed,
            })
          );
          break;
        case AccessPermissionAction.EDIT:
          dialogStore.openDialog(
            componentDialogsConfigConstructor({
              action: action,
              item,
              component: AddAccessPermissionModal,
              scrollable: true,
              callback: accessPermissionsStore.editAccessPermissions,
              width: ModalWidth.LARGE,
              disable: actionNotAllowed,
            })
          );
          break;
        default:
          return;
      }
      return item;
    };

    const onDropEvent = async (permissionsList: AccessPermissionItem[], serviceName: Service) => {
      await accessPermissionsStore.updateAccessPermissionsOrder(permissionsList, serviceName);
    };

    return {
      onDropEvent,
      ServiceAction,
      coronetSkeletonLoaderTypes,
      connectedServices,
      showSkeletonLoader,
      RouteName,
      goToAccessPermissions,
      getCountryOrStateName,
      itemActions: [AccessPermissionAction.EDIT, AccessPermissionAction.DELETE],
      countriesList,
      usaStatesList,
      actionNotAllowed,
      permissions,
      loading,
      addMenuItems,
      onAddActionClick,
      onActionClick,
    };
  },
});
</script>

<style lang="scss" scoped>
.no-cloud-apps {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 620px;

  &__item {
    padding: 0 15px;
    max-width: 272px;
    box-sizing: initial;
  }
}

.service-icon {
  height: 48px;
  width: 48px;
}

.service-name {
  font-size: 18px;
  line-height: 24px;
}

.service-status {
  font-size: 16px;
}

.heading-text {
  line-height: 40px;
}

.table-no-data tbody {
  height: 70px;
}

:deep(*) {
  .service-icon {
    width: 24px;
    height: 24px;

    &--large {
      width: 40px;
      height: 40px;
    }
  }

  th {
    background-color: white !important;
  }
}
</style>
