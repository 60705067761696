<template>
  <div class="content-md margin-auto mt-4">
    <filter-wrapper
      class="mb-5 justify-end"
      :show-clear-button="showClearFiltersButton"
      @clear-filters-clicked="clearFilters(clearFiltersCallback)"
    >
      <v-autocomplete
        v-model="localFilters.workspaceId"
        class="workspace-autocomplete"
        :class="{ 'filter-active': localFilters.workspaceId?.length }"
        :placeholder="$t('modals.addMspAdminUser.workspace.label')"
        persistent-hint
        item-title="workspaceName"
        item-value="workspaceId"
        :hide-no-data="false"
        :no-filter="true"
        auto-select-first="exact"
        :menu-props="{
          maxHeight: '250',
        }"
        :items="mspAdminUserWorkspaces"
        :loading="workspacesLoading"
        variant="outlined"
        density="compact"
        rounded
        @update:search="onWorkspaceSearchUpdate($event)"
      ></v-autocomplete>
    </filter-wrapper>
    <v-skeleton-loader :loading="showSkeletonLoader" type="table" color="transparent">
      <table-wrapper>
        <v-data-table-server
          class="white"
          :headers="headers"
          v-model:expanded="expanded"
          :items="roles"
          :show-expand="true"
          :fixed-header="true"
          :loading="loading"
          :page="pagination.page + 1"
          :items-length="rolesTotal"
          @update:options="
            handlePaginationChange({ pageSize: $event.itemsPerPage, page: $event.page - 1 })
          "
          :items-per-page="pagination.pageSize"
          color="primary"
        >
          <template #item="{ item, toggleExpand, isExpanded, internalItem }">
            <tr>
              <td class="w-5">
                <v-icon
                  class="mr-2 cursor-pointer"
                  icon="$dropdown"
                  color="primary"
                  :class="{ rotated: isExpanded(internalItem) }"
                  @click="toggleExpand(internalItem)"
                />
              </td>
              <td class="subtitle2 w-45">{{ item.name }}</td>
              <td class="body2">{{ item.assigneesCount }}</td>
              <td>
                <div class="d-flex flex-column">
                  <span class="subtitle2">{{ item.workspaceDisplayName }}</span>
                  <span class="caption text-indigo-medium">{{ item.workspaceId }}</span>
                </div>
              </td>
              <td class="w-5">
                <v-menu bottom left>
                  <template v-slot:activator="{ props }">
                    <v-icon icon="$dots" class="item-clickable" v-bind="props"></v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(action, index) in getActions(item)"
                      :key="index"
                      :value="index"
                      @click="onMspActionClick(action, item)"
                    >
                      <v-list-item-title>
                        {{ $t(`manageWorkspaces.roles.table.actions.${action}`) }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-row="{ item }">
            <tr>
              <td></td>
              <td :colspan="3">
                <v-row class="my-0">
                  <v-col cols="6" class="border-divider pl-0">
                    <v-list>
                      <div class="subtitle1 pl-3">
                        {{ $t("manageWorkspaces.roles.mspPermissionsSectionTitle") }}
                      </div>
                      <role-details-section
                        v-for="scope in mspSectionScopes"
                        class="mb-10"
                        :scope="roleDetails[item.id]?.permissions[scope]"
                        :scope-name="scope"
                        :key="scope"
                        :show-modules-disabled-by-subscription="true"
                        :scope-title="$t(`manageWorkspaces.roles.scopes.${scope}`)"
                      />
                      <div class="subtitle1 pl-3">
                        {{ $t("manageWorkspaces.roles.workspacePermissionsSectionTitle") }}
                      </div>
                      <role-details-section
                        v-for="scope in workspaceRolesSectionScopes"
                        class="mb-10"
                        :scope="roleDetails[item.id]?.permissions[scope]"
                        :scope-name="scope"
                        :key="scope"
                        :show-modules-disabled-by-subscription="true"
                      />
                    </v-list>
                  </v-col>
                  <v-col cols="6" class="pl-4">
                    <div class="subtitle2 mt-3">
                      {{ $t("accessControlPage.roles.details.adminUsers") }}
                    </div>
                    <div
                      v-for="assignee in roleDetails[item.id]?.assignees?.items"
                      :key="assignee.id"
                      class="body2"
                    >
                      {{ assignee.name ? `${assignee.name} (${assignee.email})` : assignee.email }}
                    </div>
                  </v-col>
                </v-row>
              </td>
              <td></td>
            </tr>
          </template>
        </v-data-table-server>
      </table-wrapper>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import TableWrapper from "@/components/TableWrapper.vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { defineComponent, onMounted, type PropType, ref, type Ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import type { MspRolesAction, RoleType } from "@/constants/roles";
import { useRolesTab } from "@/composables/useRolesTab";
import {
  type MspRoleListItem,
  MspRolePermissionScopes,
  RolePermissionsScope,
} from "@/_store/roles.module";
import FilterWrapper from "@/components/FilterWrapper.vue";
import { useFilters } from "@/composables/useFilters";
import { FilterContext } from "@/_store/filters.module";
import { debounce } from "lodash";
import { useMspAdminUsersStore } from "@/_store/msp-admin-users.module";
import RoleDetailsSection from "@/components/roles/RoleDetailsSection.vue";

const workspaceRolesSectionScopes = [
  RolePermissionsScope.WORKSPACE_MANAGEMENT,
  RolePermissionsScope.PROTECTION,
];

const mspSectionScopes = [
  MspRolePermissionScopes.MSP_PORTAL_SCOPE,
  RolePermissionsScope.VIEWS,
  RolePermissionsScope.TICKETS,
];

export default defineComponent({
  props: {
    roleType: {
      type: String as PropType<RoleType>,
      required: true,
    },
  },
  components: {
    RoleDetailsSection,
    FilterWrapper,
    TableWrapper,
  },
  setup(props) {
    const i18n = useI18n();
    const { localFilters, showClearFiltersButton, clearFilters, filtersUpdating } = useFilters(
      FilterContext.MSP_ROLES
    );
    const workspacesLoading = ref(false);
    const mspAdminUserWorkspaces = ref<{ workspaceId: string; workspaceName: string }[]>([]);
    const mspAdminUsersStore = useMspAdminUsersStore();

    const {
      roles,
      roleDetails,
      expanded,
      showPagination,
      page,
      totalPages,
      loading,
      showSkeletonLoader,
      pagination,
      rolesTotal,
      handlePaginationChange,
      getActions,
      onMspActionClick,
      resetPagination,
      getRoles,
    } = useRolesTab<MspRolesAction>(props.roleType);

    const clearFiltersCallback = async () => {
      resetPagination();
      expanded.value = [];
    };

    const onWorkspaceSearchUpdate = debounce(function (searchString) {
      const foundWorkspace = mspAdminUserWorkspaces.value.find(
        (v) => v.workspaceId === localFilters.value.workspaceId
      );
      if (!searchString && !foundWorkspace?.workspaceName) {
        fetchMspAdminUserWorkspaces();
      }
      if (searchString !== foundWorkspace?.workspaceName) {
        fetchMspAdminUserWorkspaces(searchString);
      }
    }, 500);

    const fetchMspAdminUserWorkspaces = async (search: string = "") => {
      workspacesLoading.value = true;
      const data = await mspAdminUsersStore.searchMspAdminUserWorkspaces(0, search);
      mspAdminUserWorkspaces.value = data.items;
      workspacesLoading.value = false;
    };

    onMounted(async () => {
      await fetchMspAdminUserWorkspaces();
    });

    watch(
      filtersUpdating,
      async (updating: boolean) => {
        if (updating) {
          await getRoles();
          resetPagination();
          expanded.value = [];
        }
      },
      { deep: true }
    );

    return {
      coronetSkeletonLoaderTypes,
      roles: roles as Ref<MspRoleListItem[]>,
      workspaceRolesSectionScopes,
      mspSectionScopes,
      roleDetails,
      expanded,
      showPagination,
      page,
      totalPages,
      loading,
      showSkeletonLoader,
      pagination,
      rolesTotal,
      localFilters,
      showClearFiltersButton,
      workspacesLoading,
      mspAdminUserWorkspaces,
      filtersUpdating,
      clearFilters,
      handlePaginationChange,
      getActions,
      onMspActionClick,
      clearFiltersCallback,
      onWorkspaceSearchUpdate,
      headers: [
        { title: "", key: "data-table-expand", sortable: false },
        {
          title: i18n.t("manageWorkspaces.roles.table.headers.role"),
          key: "name",
          sortable: false,
        },
        {
          title: i18n.t("manageWorkspaces.roles.table.headers.assigneesCount"),
          key: "assigneesCount",
          sortable: false,
        },
        {
          title: i18n.t("manageWorkspaces.roles.table.headers.workspace"),
          key: "workspaceId",
          sortable: false,
        },
        { title: "", key: "actions", sortable: false },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
.workspace-autocomplete {
  width: 200px;
}

:deep(*) {
  .filter-wrapper__content {
    flex-grow: 0 !important;
  }
}
</style>
