<template>
  <div>
    <div v-if="ticketDetails.workspaceId || ticketDetails.workspaceName" class="mb-4">
      <div class="subtitle1">{{ $t("ticketDetails.customer") }}</div>
      <div v-if="ticketDetails.workspaceId" class="body1" data-testid="ticket-preview-last-seen">
        {{ ticketDetails.workspaceId }}
      </div>
      <span
        v-if="ticketDetails.workspaceName"
        class="body1 coro-link mb-4"
        @click="onWorkspaceNameClick()"
      >
        {{ ticketDetails.workspaceName }}
      </span>
    </div>
    <component
      :is="generalInfoComponent"
      :ticket-details="ticketDetails"
      :is-soc="isSoc"
    ></component>
  </div>
</template>

<script lang="ts">
import CloudAppTicketInfo from "./CloudAppTicketInfo.vue";
import DeviceTicketInfo from "./DeviceTicketInfo.vue";
import EmailTicketInfo from "./EmailTicketInfo.vue";
import UserTicketInfo from "./UserTicketInfo.vue";
import DataTicketInfo from "./DataTicketInfo.vue";
import EdrTicketInfo from "./EdrTicketInfo.vue";
import { type Component, computed, defineComponent, type PropType } from "vue";
import { TicketType } from "@/constants/tickets";
import type { TicketDetails } from "@/_store/tickets/tickets.module";
import { useRouter } from "vue-router";
import { RouteName } from "@/constants/routes.ts";
import SecurityAwarenessInfo from "@/components/tickets/ticket-preview-details/SecurityAwarenessInfo.vue";

const deviceTicketTypes = [
  TicketType.MALWARE_IN_DEVICE,
  TicketType.ENDPOINT_VULNERABILITY,
  TicketType.WIFI_PHISHING,
  TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_DEVICE,
  TicketType.WIFI_FORBIDDEN_NETWORK,
];

const userTicketTypes = [
  TicketType.ACCESS_PERMISSIONS_VIOLATION,
  TicketType.ABNORMAL_DATA_MANIPULATION,
  TicketType.SUSPECTED_BOT_ATTACKS,
  TicketType.ABNORMAL_ADMIN_ACTIVITY,
  TicketType.SUSPECTED_IDENTITY_COMPROMISE,
  TicketType.USER_INACTIVITY,
];

const cloudAppTicketTypes = [TicketType.MALWARE_IN_CLOUD_DRIVE];

const dataTicketTypes = [
  TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_EMAIL,
  TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_SHARING,
  TicketType.SUSPECTED_DATA_EXPOSURE_BY_EMAIL,
  TicketType.SUSPECTED_DATA_EXPOSURE_BY_SHARING,
];

const securityAwarenessTypes = [
  TicketType.SIMULATED_EMAIL_PHISHING,
  TicketType.SECURITY_TRAINING_FAILURE,
];

const emailTicketTypes = [TicketType.MALWARE_IN_EMAIL_ATTACHMENTS, TicketType.EMAIL_PHISHING];

const edrTicketTypes = [TicketType.EDR_DETECTION];

export default defineComponent({
  components: {
    CloudAppTicketInfo,
    DeviceTicketInfo,
    EmailTicketInfo,
    UserTicketInfo,
    DataTicketInfo,
    EdrTicketInfo,
    SecurityAwarenessInfo,
  },
  props: {
    ticketDetails: {
      type: Object as PropType<TicketDetails>,
      required: true,
    },
    isSoc: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const generalInfoComponent = computed<Component | null>(() => {
      const typeMap: Array<{ types: TicketType[]; infoComponent: Component }> = [
        { types: deviceTicketTypes, infoComponent: DeviceTicketInfo },
        { types: emailTicketTypes, infoComponent: EmailTicketInfo },
        { types: cloudAppTicketTypes, infoComponent: CloudAppTicketInfo },
        { types: userTicketTypes, infoComponent: UserTicketInfo },
        { types: dataTicketTypes, infoComponent: DataTicketInfo },
        { types: edrTicketTypes, infoComponent: EdrTicketInfo },
        { types: securityAwarenessTypes, infoComponent: SecurityAwarenessInfo },
      ];

      for (const { types, infoComponent } of typeMap) {
        if (types.includes(props.ticketDetails.eventType)) {
          return infoComponent;
        }
      }

      return null;
    });

    const onWorkspaceNameClick = async () => {
      await router.push({
        name: RouteName.DASHBOARD,
        query: { workspaceId: props.ticketDetails.workspaceId },
      });
    };

    return {
      generalInfoComponent,
      onWorkspaceNameClick,
    };
  },
});
</script>
