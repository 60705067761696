<template>
  <div>
    <div class="info-block px-3 py-1 mb-10 d-flex align-center">
      <v-icon size="24" icon="$warning" />
      <span class="subtitle2 ml-2" v-html="$t('modals.exportActivityLogs.disclaimer')" />
    </div>
    <div class="subtitle1 mb-1">
      {{ $t("modals.exportActivityLogs.subtitle") }}
    </div>
    <v-radio-group v-model="localValue.type" hide-details>
      <v-radio
        v-for="choice in [ExportChoice.ALL, ExportChoice.SPECIFIC]"
        :key="choice"
        class="mb-3"
        :value="choice"
        :ripple="false"
        :label="$t(`modals.exportActivityLogs.choices.${choice}`)"
      ></v-radio>
    </v-radio-group>
    <filter-wrapper :show-clear-button="false" class="mt-2">
      <div class="d-flex flex-column">
        <v-select
          v-model="localValue.filters.types"
          :items="activityLogTypes"
          :disabled="localValue.type === ExportChoice.ALL"
          bg-color="white"
          menu-icon="icon-triangle"
          item-value="id"
          item-title="name"
          :menu-props="{ maxHeight: '300' }"
          class="filter-menu filter mb-2"
          :class="{ 'filter-active': localValue.filters.types?.length }"
          :placeholder="$t('activityLogs.filters.types')"
          density="compact"
          variant="outlined"
          multiple
          rounded
          hide-details
          background-color="white"
          return-object
        >
          <template #selection="{ index }">
            <span v-if="index === 0" class="body2">{{ $t("activityLogs.filters.types") }}</span>
          </template>
          <template #label>
            <span v-if="localValue.filters.types?.length" class="filter-label">
              {{ localValue.filters.types.length }}
            </span>
          </template>
          <template #item="{ item, props }">
            <v-list-item v-bind="props" title="">
              <template v-slot:prepend="{ isActive }">
                <v-list-item-action start>
                  <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                </v-list-item-action>
              </template>

              <v-list-item-title title="">
                {{ $t(`activityLogs.filters.type.${item.raw}`) }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
        <date-range-picker
          v-model="localValue.filters.timeFrame"
          :disabled="localValue.type === ExportChoice.ALL"
          :preset-dates="presets"
          :placeholder="$t('activityLogs.filters.dateRange')"
          class="mr-2 filter"
          :teleport="true"
          hide-menu-arrow
          @click.stop.prevent
        ></date-range-picker>
      </div>
    </filter-wrapper>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType, ref, watch } from "vue";
import { ExportChoice, type ExportDialogPayload } from "@/_store/exports.module";
import DateRangePicker from "@/components/DateRangePicker.vue";
import { ActivityLogsType } from "@/constants/activity-logs";
import moment from "moment/moment";
import type { ActivityLogsFilters } from "@/_store/activity-logs.module";
import { defaultFiltersState } from "@/_store/filters.module";
import FilterWrapper from "@/components/FilterWrapper.vue";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { FilterWrapper, DateRangePicker },
  props: {
    config: {
      type: Object as PropType<DialogDataConfig>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();

    const presets = computed(() => [
      {
        label: i18n.t("dateRangePicker.today"),
        value: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
      {
        label: i18n.t("dateRangePicker.yesterday"),
        value: [
          moment().subtract(1, "days").format("YYYY-MM-DD"),
          moment().subtract(1, "days").format("YYYY-MM-DD"),
        ],
      },
      {
        label: i18n.t("dateRangePicker.lastSevenDays"),
        value: [moment().subtract(7, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
      {
        label: i18n.t("dateRangePicker.lastThirtyDays"),
        value: [moment().subtract(30, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
      {
        label: i18n.t("dateRangePicker.lastNinetyDays"),
        value: [moment().subtract(90, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
    ]);

    const localValue = ref<ExportDialogPayload<ActivityLogsFilters>>({
      type: ExportChoice.ALL,
      filters: {
        ...defaultFiltersState.activityLogsFilters,
      },
    });

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true, immediate: true }
    );

    return {
      localValue,
      ExportChoice,
      activityLogTypes: Object.values(ActivityLogsType),
      presets,
    };
  },
});
</script>

<style lang="scss" scoped>
.filter {
  width: 240px !important;
}

:deep(*) {
  .clear-btn {
    display: none !important;
  }
}
</style>
