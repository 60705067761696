import { createI18n } from "vue-i18n";
import { WorkspaceLocale } from "@/constants/workplaces";
import { en } from "@/translations/en";
import { es } from "@/translations/es";
import { it } from "@/translations/it.ts";

export const i18n = createI18n<false>({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: WorkspaceLocale.EN_US,
  fallbackLocale: WorkspaceLocale.EN_US,
  messages: {
    [WorkspaceLocale.EN_US]: en,
    [WorkspaceLocale.EN_GB]: en,
    [WorkspaceLocale.ES]: es,
    [WorkspaceLocale.IT]: it,
  },
});
