<template>
  <div class="content-md margin-auto" data-testid="dashboard-page-container">
    <div class="dashboard-row">
      <div class="dashboard-row__overview-widgets">
        <v-skeleton-loader
          :key="overviewWidgetsSkeletonLoader.toString()"
          :loading="overviewWidgetsSkeletonLoader"
          class="skeleton-loader--overview-widgets overview-widgets"
          :type="coronetSkeletonLoaderTypes.OVERVIEW_WIDGETS"
        >
          <overview-widget
            v-for="overviewWidget in enabledModulesOverviewWidgets"
            :key="overviewWidget.type"
            :data-testid="`overview-widget-${kebabCase(overviewWidget.type)}`"
            :item="overviewWidget"
            class="mb-2"
            @widget-clicked="onWidgetClick($event)"
          />
          <template v-if="disabledModulesVisible && showDisabledModules">
            <overview-widget
              v-for="overviewWidget in disabledModulesOverviewWidgets"
              :key="overviewWidget.type"
              :data-testid="`overview-widget-${kebabCase(overviewWidget.type)}`"
              :item="overviewWidget"
              class="mb-2"
              @widget-clicked="onWidgetClick($event)"
            />
          </template>
          <a
            v-if="displayShowMoreWidgetsButton(disabledModulesOverviewWidgets)"
            class="coro-link body2 mt-1"
            @click="disabledModulesVisible = !disabledModulesVisible"
          >
            {{
              disabledModulesVisible
                ? $t("dashboard.hideDisabledModules")
                : $t("dashboard.seeDisabledModules")
            }}
          </a>
          <overview-widget
            v-if="showPlaceholderOverviewWidget"
            :item="placeholderOverviewWidget"
            class="mb-2"
          >
            <template #title>
              {{ placeholderOverviewWidgetTitle }}
            </template>
          </overview-widget>
          <div class="hidden"></div>
        </v-skeleton-loader>
        <v-skeleton-loader
          key="addon-overview-widgets-skeleton-loader"
          :loading="overviewWidgetsSkeletonLoader"
          class="skeleton-loader--addon-overview-widgets overview-widgets"
          :type="coronetSkeletonLoaderTypes.OVERVIEW_WIDGETS"
        >
          <div v-if="addonsTitle" class="headline6 my-6">
            {{ addonsTitle }}
          </div>
          <overview-widget
            v-for="addonOverviewWidget in enabledAddonOverviewWidgets"
            :key="addonOverviewWidget.type"
            :data-testid="`overview-widget-${kebabCase(addonOverviewWidget.type)}`"
            :item="addonOverviewWidget"
            class="mb-2"
          />
          <template v-if="disabledAddonsVisible && showDisabledModules">
            <overview-widget
              v-for="addonOverviewWidget in disabledAddonOverviewWidgets"
              :key="addonOverviewWidget.type"
              :data-testid="`overview-widget-${kebabCase(addonOverviewWidget.type)}`"
              :item="addonOverviewWidget"
              class="mb-2"
            />
          </template>
          <a
            v-if="displayShowMoreWidgetsButton(disabledAddonOverviewWidgets)"
            class="d-inline-block coro-link body2 mt-1 mb-2"
            @click="disabledAddonsVisible = !disabledAddonsVisible"
          >
            {{
              disabledAddonsVisible
                ? $t("dashboard.hideDisabledAddons")
                : $t("dashboard.seeDisabledAddons")
            }}
          </a>
          <trial-banner></trial-banner>
          <div class="hidden"></div>
        </v-skeleton-loader>
      </div>
      <div class="dashboard-row__widgets">
        <template v-if="hasEnabledModules">
          <div v-for="(item, index) in widgets" :key="index">
            <coro-widget
              :data-widget-id="item.config.widgetId"
              :widget-config="item.config"
              class="mb-6"
            >
              <template #header>
                <component
                  :is="item.config.header.componentName"
                  :widget-config="item.config"
                  :header-config="getWidgetHeaderConfig(item.config)"
                />
              </template>
              <template #content>
                <component
                  :is="item.config.content.componentName"
                  v-if="item.config.content.componentName"
                  :ref="item.config.widgetId"
                  :widget-data="widgetsData[item.config.widgetId]"
                  :widget-config="item.config"
                />
              </template>
            </coro-widget>
          </div>
        </template>
        <v-skeleton-loader v-else :type="coronetSkeletonLoaderTypes.CARD" :loading="widgetsLoading">
          <v-card height="390">
            <v-card-text class="d-flex flex-column justify-center align-center flex-grow-1 h-100">
              <v-icon size="100" icon="$coroFlexible" />
              <div class="headline5 text--black mt-4">
                {{ $t("dashboard.widgets.disclaimerWidget.title") }}
              </div>
              <div class="caption mt-3 mb-8">{{ disclaimerWidgetDescription }}</div>
            </v-card-text>
          </v-card>
        </v-skeleton-loader>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  OverviewWidgetAppearance,
  OverviewWidgetSize,
  OverviewWidgetType,
  WidgetType,
} from "@/constants/dashboard";
import { RolePermissionAccessMode } from "@/constants/roles";
import { WorkspaceType } from "@/constants/workplaces";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from "vue";
import OverviewWidget from "@/components/dashboard/OverviewWidget.vue";
import kebabCase from "lodash/kebabCase";
import { useRouter } from "vue-router";
import { useDashboardStore, type WidgetConfig } from "@/_store/dashboard/dashboard.module";
import { storeToRefs } from "pinia";
import { useAccountStore } from "@/_store";
import { useRedirectsStore } from "@/_store/redirects.module";
import { useI18n } from "vue-i18n";
import { useSettingsStore } from "@/_store/settings.module";
import { CoroIcons } from "@/constants/coro-icon";
import CoroWidget from "@/components/dashboard/CoroWidget.vue";
import CloudSecurityWidget from "@/components/dashboard/CloudSecurityWidget.vue";
import WidgetHeader from "@/components/dashboard/WidgetHeader.vue";
import EmailSecurityWidget from "@/components/dashboard/EmailSecurityWidget.vue";
import EndpointSecurityWidget from "@/components/dashboard/EndpointSecurityWidget.vue";
import UserDataGovernanceWidget from "@/components/dashboard/UserDataGovernanceWidget.vue";
import EdrWidget from "@/components/dashboard/EdrWidget.vue";
import TrialBanner from "@/components/TrialBanner.vue";
import type { OverviewWidgetConfiguration } from "@/_store/dashboard/adapters";
import { useWorkspacesStore } from "@/_store/workspaces.module.ts";

export default defineComponent({
  components: {
    OverviewWidget,
    CoroWidget,
    CloudSecurityWidget,
    EmailSecurityWidget,
    EndpointSecurityWidget,
    UserDataGovernanceWidget,
    EdrWidget,
    WidgetHeader,
    TrialBanner,
  },
  setup() {
    const dashboardStore = useDashboardStore();
    const accountStore = useAccountStore();
    const redirectsStore = useRedirectsStore();
    const settingsStore = useSettingsStore();
    const workspacesStore = useWorkspacesStore();
    const i18n = useI18n();
    const { init, resetState } = dashboardStore;
    const { resetRedirects } = redirectsStore;
    const { loginToMdm } = settingsStore;
    const router = useRouter();
    const {
      widgets,
      widgetsData,
      loadedWidgetsCount,
      overviewWidgets,
      hasNotDisabledRestrictedOverviewWidgets,
      enabledOverviewWidgets: enabledModulesOverviewWidgets,
      disabledOverviewWidgets: disabledModulesOverviewWidgets,
      disabledAddonOverviewWidgets,
      enabledAddonOverviewWidgets,
      addonOverviewWidgets,
      overviewWidgetsSkeletonLoader,
    } = storeToRefs(dashboardStore);
    const dashboardRefreshInterval = ref<ReturnType<typeof setInterval> | null>(null);
    const disabledModulesVisible = ref(false);
    const disabledAddonsVisible = ref(false);
    const placeholderOverviewWidget = ref({
      type: "placeholder",
      icon: "coroFlexible",
      size: OverviewWidgetSize.LARGE,
      appearance: OverviewWidgetAppearance.BLANK,
      index: 0,
    });
    const workspaceType = computed(() => accountStore.account.workspaceType);
    const showDisabledModules = computed(() => accountStore.account.showDisabledModules);
    const currentWorkspacePermissions = computed(
      () => accountStore.account.currentWorkspacePermissions
    );
    const hasEnabledModules = computed(() => enabledModulesOverviewWidgets.value.length > 0);
    const { workspaceAndPermissionsUpdatedTimestamp } = storeToRefs(workspacesStore);
    const allTicketsModuleAccessRestricted = computed(() => {
      if (currentWorkspacePermissions.value) {
        return Object.values(currentWorkspacePermissions.value.ticketsScope).every(
          (module) => module.accessMode === RolePermissionAccessMode.NO_ACCESS
        );
      }
      return false;
    });

    const disclaimerWidgetDescription = computed(() => {
      if (allTicketsModuleAccessRestricted.value) {
        return i18n.t("dashboard.widgets.disclaimerWidget.noAccess");
      }
      if (workspaceType.value === WorkspaceType.REGULAR) {
        return i18n.t("dashboard.widgets.disclaimerWidget.regularWorkspaceDescription");
      }
      return i18n.t("dashboard.widgets.disclaimerWidget.mspWorkspaceDescription");
    });

    const addonsTitle = computed(() => {
      if (showDisabledModules.value) {
        return addonOverviewWidgets.value.length > 0 ? i18n.t("dashboard.addons") : "";
      }
      return enabledAddonOverviewWidgets.value.length > 0 ? i18n.t("dashboard.addons") : "";
    });

    const showPlaceholderOverviewWidget = computed(() => {
      if (overviewWidgets.value.length > 0 && overviewWidgets.value.every((w) => w.disabled)) {
        return showDisabledModules.value === false;
      }
      if (showDisabledModules.value === false) {
        return !hasNotDisabledRestrictedOverviewWidgets.value;
      }
      return false;
    });

    const placeholderOverviewWidgetTitle = computed(() => {
      if (
        overviewWidgets.value.length > 0 &&
        overviewWidgets.value.every((w) => w.disabled) &&
        overviewWidgets.value.some((w) => !w.restricted)
      ) {
        return i18n.t("dashboard.widgets.overviewWidget.types.placeholder.title");
      }
      return i18n.t("dashboard.widgets.overviewWidget.types.placeholder.noAccessTitle");
    });

    const widgetsLoading = computed(() =>
      Object.values(widgets.value).some((w) => w.config.showSkeletonLoader)
    );

    watch(hasEnabledModules, (value) => {
      if (!value) {
        disabledModulesVisible.value = true;
      }
    });

    watch(workspaceAndPermissionsUpdatedTimestamp, async () => {
      await dashboardStore.getWidgetsData();
    });

    onMounted(async () => {
      await dashboardStore.getWidgetsData();

      if (redirectsStore.shouldHandleDashboardRedirect) {
        resetRedirects();
      }

      await cleanupQueryParams();

      dashboardRefreshInterval.value = setInterval(() => {
        init();
      }, 300000); // 5 minutes
    });

    onBeforeUnmount(() => {
      if (dashboardRefreshInterval.value) {
        clearInterval(dashboardRefreshInterval.value);
      }
      resetState();
    });

    const onWidgetClick = (widget: string) => {
      if (widget === OverviewWidgetType.MOBILE_DEVICE_MANAGEMENT) {
        loginToMdm(false);
      } else {
        scrollIntoView(widget);
      }
    };

    const cleanupQueryParams = async () => {
      await router.replace({ name: router.currentRoute.value.name });
    };

    const scrollIntoView = (widgetToScroll: string) => {
      const element = document.querySelector(`[data-widget-id="${widgetToScroll}"]`);
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
    };

    const getWidgetHeaderConfig = (config: WidgetConfig) => {
      const widgetData = widgetsData.value[config.widgetId];
      if (!widgetData) {
        return {
          showViewAll: false,
          showWarningIcon: false,
          isInWarningState: false,
        };
      }
      switch (config.widgetType) {
        case WidgetType.CLOUD_SECURITY:
          return {
            showViewAll: false,
            showIcon: widgetData.cloudServiceStatuses.length > 0,
            isInWarningState: widgetData.activeVulnerabilities.length > 0,
          };
        case WidgetType.ENDPOINT_SECURITY:
          return {
            showViewAll: false,
            showIcon: widgetData.protectedDevices > 0,
            isInWarningState: widgetData.activeVulnerabilities.length > 0,
          };
        case WidgetType.EMAIL_SECURITY:
          return {
            showViewAll: false,
            showIcon: widgetData.isEmailServicesConnected,
            isInWarningState: widgetData.activeVulnerabilities.length > 0,
          };
        case WidgetType.USER_DATA_GOVERNANCE:
          return {
            showViewAll: false,
            showIcon: widgetData.isProtectionEnabled,
            isInWarningState: widgetData.activeVulnerabilities.length > 0,
          };
      }
    };

    const displayShowMoreWidgetsButton = (disabledWidgets: OverviewWidgetConfiguration[]) => {
      return disabledWidgets.length > 0 && showDisabledModules.value;
    };

    return {
      coronetIcons: CoroIcons,
      coronetSkeletonLoaderTypes,
      dashboardRefreshInterval,
      disabledModulesVisible,
      disabledAddonsVisible,
      placeholderOverviewWidget,
      widgets,
      widgetsData,
      loadedWidgetsCount,
      overviewWidgets,
      hasNotDisabledRestrictedOverviewWidgets,
      enabledModulesOverviewWidgets,
      disabledModulesOverviewWidgets,
      disabledAddonOverviewWidgets,
      enabledAddonOverviewWidgets,
      addonOverviewWidgets,
      workspaceType,
      showDisabledModules,
      currentWorkspacePermissions,
      overviewWidgetsSkeletonLoader,
      hasEnabledModules,
      allTicketsModuleAccessRestricted,
      disclaimerWidgetDescription,
      addonsTitle,
      showPlaceholderOverviewWidget,
      placeholderOverviewWidgetTitle,
      widgetsLoading,
      onWidgetClick,
      scrollIntoView,
      getWidgetHeaderConfig,
      displayShowMoreWidgetsButton,
      kebabCase,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .no-data-icon {
    font-size: 80px;
  }

  .overview-widgets {
    margin-right: 16px;
    &--background {
      position: absolute;
      height: 310px;
      width: 200vw;
      top: -22px;
      left: 0;
      background-color: var(--v-purple-base);
    }
  }
}

.higher-z-index {
  z-index: 6;
}

.tooltip-inner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
}

.v-tooltip__content {
  font-size: 13px;
  background-color: var(--v-neutral-darken4);
  opacity: 1 !important;
  width: 568px;
  height: 285px;
  max-width: 600px !important;
  display: inline-block;
  white-space: pre-line;
  border-radius: 20px;
  pointer-events: initial;

  &:after {
    top: -19px !important;
    left: calc(50% - 10px) !important;
    border-width: 0 !important;
    border-left: 20px solid transparent !important;
    border-right: 20px solid transparent !important;
    border-bottom: 20px solid white !important;
  }
}

.dashboard-row {
  margin-top: 20px;
  display: flex;
  &__widgets {
    width: 70%;
  }
  &__overview-widgets {
    width: 30%;
  }
}

@media only screen and (max-width: 1212px) {
  .dashboard-row {
    &__widgets {
      width: 70%;
    }
    &__overview-widgets {
      width: 30%;
    }
  }
}

@media only screen and (max-width: 950px) {
  .dashboard-row {
    margin-top: unset;
    flex-direction: column;
    &__widgets {
      margin-top: 20px;
      width: 100%;
      margin-right: unset;
    }
    &__overview-widgets {
      width: 100%;
    }
  }
}
</style>
