<template>
  <div>
    <v-table
      v-if="showActiveVulnerabilities"
      density="compact"
      class="mb-6"
      data-testid="active-vulnerabilities"
    >
      <thead>
        <tr>
          <th>
            <span class="subtitle2">{{ $t("general.open") }}</span>
          </th>
          <th class="text-center">
            <a class="body2 coro-link" @click="onAllActiveClick()">{{ $t("general.all") }}</a>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="vulnerability in widgetData.activeVulnerabilities" :key="vulnerability.type">
          <td class="text-left vulnerability-col">
            <div class="text-no-wrap d-flex align-center">
              <div class="inner-circle mr-2 coro-widget--active-vulnerability-circle"></div>
              <a
                class="coro-link body2 vulnerability-text"
                :data-testid="`widget-${kebabCase(vulnerability.issue)}-link`"
                @click="onViewDetailsClick(vulnerability, true)"
                :title="$t(`tickets.eventTriggers.${vulnerability.issue}`)"
              >
                <slot name="active-vulnerability" v-bind="vulnerability">
                  {{ $t(`tickets.eventTriggers.${vulnerability.issue}`) }}
                </slot>
              </a>
            </div>
          </td>
          <td
            class="text-center number-col"
            :data-testid="`widget-${kebabCase(vulnerability.issue)}-count`"
          >
            {{ vulnerability.count }}
          </td>
          <td class="text-center action-col">
            <v-btn
              :data-testid="`widget-${kebabCase(vulnerability.issue)}-resolve-btn`"
              rounded
              size="x-small"
              color="primary"
              @click="onViewDetailsClick(vulnerability, true)"
            >
              {{ $t("general.review") }}
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-table>
    <v-table density="compact" data-testid="resolved-vulnerabilities">
      <thead>
        <tr>
          <th>
            <span class="subtitle2">{{ $t("general.resolvedLastNinetyDays") }}</span>
          </th>
          <th class="text-center">
            <a class="body2 coro-link" @click="onAllResolvedClick()">{{ $t("general.all") }}</a>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="vulnerability in resolvedVulnerabilities" :key="vulnerability.issue">
          <td class="text-left vulnerability-col">
            <div class="text-no-wrap d-flex align-center">
              <div class="inner-circle mr-2 coro-widget--resolved-vulnerability-circle"></div>
              <a
                class="coro-link body2 vulnerability-text"
                :class="{ 'coro-link--disabled': !vulnerability.count }"
                :data-testid="`widget-${kebabCase(vulnerability.issue)}-link`"
                @click="onViewDetailsClick(vulnerability, false)"
                :title="$t(`tickets.eventTriggers.${vulnerability.issue}`)"
              >
                <slot name="resolved-vulnerability" v-bind="vulnerability">
                  {{ $t(`tickets.eventTriggers.${vulnerability.issue}`) }}
                </slot>
              </a>
            </div>
          </td>
          <td
            class="text-center number-col"
            :data-testid="`widget-${kebabCase(vulnerability.issue)}-count`"
          >
            <span class="body2" :class="{ 'coronet-neutral-darken1--text': !vulnerability.count }">
              {{ vulnerability.count || 0 }}
            </span>
          </td>
          <td class="text-center action-col">
            <v-icon size="24" icon="$check" />
          </td>
        </tr>
      </tbody>
    </v-table>
    <template v-if="displayShowMoreButton && showActiveVulnerabilities">
      <a v-if="!showAllResolved" class="coro-link body2 ml-6 mt-1" @click="showAllResolved = true">
        {{ $t("general.seeNMore", { n: widgetData.resolvedVulnerabilities.length - 3 }) }}...
      </a>
      <a v-if="showAllResolved" class="coro-link body2 ml-6 mt-1" @click="showAllResolved = false">
        {{ $t("general.seeLess") }}...
      </a>
    </template>
  </div>
</template>

<script>
import kebabCase from "lodash/kebabCase";

export default {
  props: {
    widgetType: {
      type: String,
      default: "",
    },
    widgetData: {
      type: Object,
      required: true,
    },
    displayShowMoreButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showAllResolved: false,
    };
  },
  computed: {
    showActiveVulnerabilities() {
      return this.widgetData.activeVulnerabilities.length > 0;
    },
    resolvedVulnerabilities() {
      if (this.showAllResolved || !this.showActiveVulnerabilities) {
        return this.widgetData.resolvedVulnerabilities;
      }
      return this.widgetData.resolvedVulnerabilities.slice(0, 3);
    },
  },
  methods: {
    onAllResolvedClick() {
      this.$emit("all-resolved-link-clicked");
    },
    onAllActiveClick() {
      this.$emit("all-active-link-clicked");
    },
    onViewDetailsClick(vulnerability, isActive) {
      if (!vulnerability.count) return;
      this.$emit("vulnerability-clicked", {
        isActive,
        vulnerability,
      });
    },
    kebabCase,
  },
};
</script>

<style scoped lang="scss">
.action-col {
  max-width: 90px;
}

.number-col {
  max-width: 40px;
}

.vulnerability-col {
  max-width: 300px;
  min-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-table {
  box-shadow: unset !important;
}

:deep(*) {
  .v-table td a {
    color: rgb(var(--v-theme-primary)) !important;
  }
}

.vulnerability-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 290px;
  display: inline-block;
}
</style>
