<template>
  <div>
    <div class="coronet-table--loader">
      <v-progress-linear v-if="loading" id="table-progress-bar" indeterminate height="2px" />
    </div>

    <v-data-table
      v-model="selectedItems"
      class="coronet-table--list"
      :headers="headers"
      :items="items"
      :items-per-page="pagination.pageSize"
      :server-items-length="totalItems"
      show-select
      return-object
    >
      <template #header.data-table-select>
        <v-checkbox
          :model-value="allItemsSelected"
          data-testid="table-select-all-checkbox"
          :indeterminate="isIndeterminate"
          class="ml-3"
          primary
          hide-details
          @update:modelValue="handleAllRowsSelection"
        />
      </template>
      <template #header.type>
        <div class="d-flex align-center justify-space-between m-0">
          <list-table-header
            :pagination="pagination"
            :total-items="totalItems"
            :items="items"
            :show-pagination="showPagination"
            :default-sort-property="sortObject.property"
            :sorting-options="sorterOptions"
            :has-sorter="true"
            @pagination-change="handleListTablePaginationChange"
            @sorting-change="onSortChanged"
          >
            <template #prepend>
              <span class="table-selected-text" v-if="selectedItemsQuantity > 0">
                {{ $t("usersPage.selected", { n: selectedItemsQuantity }) }}
              </span>
            </template>
            <template #append>
              <v-menu v-if="selectedItemsQuantity > 0" offset-y location="bottom right">
                <template #activator="{ props }">
                  <v-btn
                    data-testid="bulk-actions-button"
                    class="ml-8"
                    rounded
                    color="primary"
                    v-bind="props"
                  >
                    {{ $t("general.actions") }}
                    <v-icon class="ml-1 mt-1" size="10" icon="$triangle" />
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="action in availableBulkActions"
                    :key="action"
                    :data-testid="`edr-table-${kebabCase(action)}-action`"
                    @click="onBulkActionClick(action)"
                  >
                    <v-list-item-title>
                      {{ $t(`edr.actions.${action}`) }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </list-table-header>
        </div>
      </template>
      <template #item="{ item, isSelected, index, internalItem }">
        <tr
          :key="index"
          :class="{
            'coro-table-row--selected': isSelected([internalItem]),
            'coro-table-row--active': isRowActive(item[itemKey]),
          }"
          @click="handleRowClick(item)"
        >
          <td class="pl-2">
            <v-checkbox-btn
              :data-testid="`table-checkbox-row-${index}`"
              :model-value="isSelected([internalItem])"
              @update:modelValue="handleOneRowSelection($event, item)"
              @click.stop
            />
          </td>
          <td :id="`events-table-type-col-row-${index}`" class="pl-0">
            <div class="list-item-grid">
              <div class="list-item-grid__icon-block">
                <v-icon :icon="getProcessIcon(item.osType)" size="40" class="mr-4" />
              </div>
              <div class="list-item-grid__details-block d-flex flex-column justify-center pr-2">
                <div
                  :data-testid="`edr-process-table-name-row-${index}`"
                  class="body2 font-weight-bold"
                >
                  {{ item.name }}
                </div>
                <div :data-testid="`edr-process-table-affected-devices-row-${index}`" class="body2">
                  {{
                    $t(
                      "edr.table.devices",
                      {
                        n: item.affectedDevicesCount,
                      },
                      item.affectedDevicesCount
                    )
                  }}
                </div>
                <div
                  :data-testid="`edr-process-table-last-seen-row-${index}`"
                  class="caption text-indigo-medium"
                >
                  {{ $t("edr.table.lastSeen") }}:
                  {{ getFormattedDateTime(item.lastActivity) }}
                </div>
              </div>
              <div
                v-if="item.blocked"
                :data-testid="`edr-process-table-blocked-row-${index}`"
                class="text-red-dark list-item-grid__status-block blocked-block"
              >
                {{ $t("edr.table.blocked") }}
              </div>
            </div>
          </td>
        </tr>
      </template>
      <template #bottom></template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType, toRefs } from "vue";
import { usePageableTable } from "@/composables/usePageableTable";
import type { Pagination } from "@/types";
import { useSelectableTable } from "@/composables/useSelectableTable";
import kebabCase from "lodash/kebabCase";
import { useListTable } from "@/composables/useListTable";
import { RolePermissionsScope } from "@/_store/roles.module";
import { useDialogsStore } from "@/_store/dialogs.module";

import ListTableHeader, { type SortObject } from "@/components/ListTableHeader.vue";
import {
  confirmationDialogsConfigConstructor,
  getFormattedDateTime,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { SubscriptionModule } from "@/constants/workplaces";
import { OsType } from "@/constants/devices";
import { useSelectorStore } from "@/_store/selector.module";
import { storeToRefs } from "pinia";
import type { EdrAction } from "@/constants/edr";
import type { ProcessItem } from "@/_store/endpoint-security/processes.module";
import { useProcessesStore } from "@/_store/endpoint-security/processes.module";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    ListTableHeader,
  },
  props: {
    availableBulkActions: {
      type: Array as PropType<EdrAction[]>,
      default() {
        return [];
      },
    },
    items: {
      type: Array as PropType<ProcessItem[]>,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      default: () => {
        return {
          page: 0,
          pageSize: 15,
        };
      },
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    "selection-changed",
    "page-changed",
    "sorting-changed",
    "update-page-count",
    "update-preview",
  ],
  setup(props, { emit }) {
    const headers = [
      {
        value: "type",
      },
    ];

    const itemKey: keyof ProcessItem = "id";
    const dialogStore = useDialogsStore();
    const i18n = useI18n();
    const processesStore = useProcessesStore();
    const { sortObject } = toRefs(processesStore);
    const { totalItems, items, pagination } = toRefs(props);
    const { selection } = storeToRefs(useSelectorStore());

    const { totalPages, page, showPagination, handleListTablePaginationChange } = usePageableTable(
      {
        totalItems,
        items,
        pagination,
      },
      (value: Pagination) => {
        emit("page-changed", value);
      }
    );
    const sorterOptions = [
      { displayName: i18n.t("edr.details.numberOfDevices"), property: "affectedDevicesCount" },
      { displayName: i18n.t("edr.details.time"), property: "lastActivity" },
    ];

    const onSortChanged = ({ direction, property }: SortObject) => {
      emit("sorting-changed", `${property},${direction}`);
    };

    const {
      selectedItems,
      handleOneRowSelection,
      handleAllRowsSelection,
      isIndeterminate,
      allItemsSelected,
      selectedItemsQuantity,
    } = useSelectableTable({
      totalItems,
      items,
    });

    const getProcessIcon = (osType: OsType) => {
      if (osType) {
        const iconMap: {
          [key: string]: string;
        } = {
          [OsType.WINDOWS]: `$windowsPc`,
          [OsType.OSX]: `$macPc`,
          [OsType.LINUX]: `$linuxPc`,
        };
        return iconMap[osType];
      }
      return undefined;
    };

    const { handleRowClick, isRowActive, activeRowId } = useListTable<ProcessItem>(
      { items, itemKey },
      (item) => {
        emit("update-preview", item);
      }
    );

    const onBulkActionClick = async (action: EdrAction) => {
      const item = {
        action,
        selection: { ...selection.value },
      };

      const disable = isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionModule.EDR
      );

      dialogStore.openDialog({
        ...confirmationDialogsConfigConstructor({
          action,
          disable,
          item,
          callback: () => processesStore.applyProcessAction(item),
        }),
        content: {
          html: i18n.t(
            `modals.${action}.description`,
            { quantity: selectedItemsQuantity.value },
            selectedItemsQuantity.value
          ),
        },
      });
    };

    return {
      headers,
      itemKey,
      totalPages,
      page,
      kebabCase,
      handleRowClick,
      isRowActive,
      activeRowId,
      selectedItems,
      showPagination,
      onBulkActionClick,
      handleListTablePaginationChange,
      getProcessIcon,
      handleOneRowSelection,
      handleAllRowsSelection,
      isIndeterminate,
      allItemsSelected,
      selectedItemsQuantity,
      getFormattedDateTime,
      sortObject,
      sorterOptions,
      onSortChanged,
    };
  },
});
</script>

<style lang="scss">
.blocked-block {
  margin-top: 10px !important;
}
</style>
