<template>
  <div>
    <div class="d-flex justify-end mb-5">
      <filter-wrapper
        :show-clear-button="showClearFiltersButton"
        @clear-filters-clicked="clearFilters(clearFiltersCallback)"
      >
        <v-select
          v-model="localFilters.types"
          :items="Object.values(telemetryTypes)"
          :menu-props="{ maxHeight: '300' }"
          menu-icon="icon-triangle"
          class="filter-menu mr-2"
          :class="{
            'filter-active': localFilters.types?.length,
          }"
          :placeholder="$t('edr.filters.types')"
          density="compact"
          variant="outlined"
          hide-details
          multiple
          rounded
          bg-color="white"
          data-testid="edr-telemetry-page-types-filter"
        >
          <template #selection="{ index }">
            <span v-if="index === 0" class="body2">{{ $t("edr.filters.types") }}</span>
          </template>
          <template #label>
            <span v-if="localFilters.types?.length" class="filter-label">
              {{ localFilters.types.length }}
            </span>
          </template>
          <template #item="{ props, item }">
            <v-list-item v-bind="props" title="">
              <template v-slot:prepend="{ isActive }">
                <v-list-item-action start>
                  <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                </v-list-item-action>
              </template>
              <v-list-item-title
                :data-testid="`edr-telemetry-page-types-filter-${item.value}-item`"
                title=""
              >
                {{ $t(`edr.telemetryTab.types.${item.value}`) }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
        <date-range-picker
          v-model="localFilters.timeRange"
          class="filter-menu mr-2 range-picker-with-time"
          enable-time-picker
          time-picker-inline
          :class="{ 'filter-active': localFilters.timeRange.start && localFilters.timeRange.end }"
          :preset-dates="presets"
          :placeholder="$t('edr.filters.during')"
        />
      </filter-wrapper>
    </div>
    <smart-search
      :search-keys="[...searchKeys, ...expandableSearchKeys]"
      :search-operators="searchOperators"
      :filters="localFilters"
      @filters-updated="onSmartFilterUpdated"
      :class="{
        'filter-active': localFilters.searches?.length,
      }"
      class="smart-search-container"
    >
      <template v-slot:menu="{ currentStep, searchText, handleSelect }">
        <v-list>
          <div class="menu-heading pl-4 mt-1 mb-1">
            {{
              isOperatorMenu(currentStep)
                ? $t("smartSearch.addOperator")
                : $t("smartSearch.searchBy")
            }}
          </div>
          <v-list-item
            v-for="(item, i) in getMenuItems(currentStep, searchText, searchKeys)"
            :key="i"
            :value="item"
            @click="handleSelect(item)"
          >
            <v-list-item-title>
              <div class="d-flex justify-space-between">
                <span v-html="item.displayName" />
              </div>
            </v-list-item-title>
          </v-list-item>
          <template v-if="!isOperatorMenu(currentStep)">
            <v-divider vclass="mt-2 mb-2" />
            <div v-if="isMenuExpanded" class="menu-heading pl-4 mt-3 mb-1">
              {{ $t("smartSearch.searchByForensic") }}
            </div>
            <div
              v-if="!isMenuExpanded"
              class="coro-link mt-3 pl-4"
              @click="isMenuExpanded = !isMenuExpanded"
            >
              {{ $t("smartSearch.moreOptions") }}
            </div>
            <template v-if="isMenuExpanded">
              <v-list-item
                v-for="(item, i) in getMenuItems(currentStep, searchText, expandableSearchKeys)"
                :key="i"
                :value="item"
                @click="handleSelect(item)"
                :id="`menu-activator-${i}`"
              >
                <v-list-item-title>
                  <div class="d-flex justify-space-between">
                    <span v-html="item.displayName" />
                    <span v-if="item.subFilters"> &#9656; </span>
                  </div>
                </v-list-item-title>
                <v-menu
                  v-if="item?.subFilters"
                  open-on-hover
                  :activator="`#menu-activator-${i}`"
                  location="end"
                  :offset="5"
                >
                  <v-list>
                    <v-list-item
                      v-for="(subFilter, index) in item.subFilters"
                      :key="index"
                      :value="index"
                      @click="handleSubFilterSelection(subFilter, item, handleSelect)"
                    >
                      <v-list-item-title>
                        <span v-html="subFilter.displayName" />
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item>
              <div class="coro-link mt-3 pl-4" @click="isMenuExpanded = !isMenuExpanded">
                {{ $t("smartSearch.lessOptions") }}
              </div>
            </template>
          </template>
        </v-list>
      </template>
      <template v-slot:search>
        <v-btn
          color="primary"
          size="small"
          rounded
          class="search-btn"
          @click.stop="onSearchClick()"
        >
          {{ $t("smartSearch.search") }}
        </v-btn>
      </template>
      <template v-slot:history>
        <v-menu>
          <template v-slot:activator="{ props, isActive }">
            <div class="d-flex align-center" v-bind="props" @mousedown.stop role="button">
              <v-btn
                density="compact"
                icon="$recentSearch"
                size="small"
                :ripple="false"
                variant="elevated"
              />
              <v-icon class="ml-2" size="10" icon="$triangle" :class="{ rotated: isActive }" />
            </div>
            <v-divider class="ml-3" vertical />
          </template>
          <v-list>
            <div class="menu-heading pl-4 mt-1 mb-1">
              {{ $t("smartSearch.searchBy") }}
            </div>
            <v-list-item
              v-for="(historyItem, index) in recentSearches"
              :key="index"
              :value="index"
              @click="selectHistoryItem(historyItem)"
            >
              <v-list-item-title class="text-wrap word-break-break-word">
                <span class="mr-2" v-for="(filter, index) in historyItem" :key="index">
                  <b v-html="filter.key ? filter.key?.displayName : $t('smartSearch.search')" />:
                  {{ filter.displayValue }}
                </span>
              </v-list-item-title>
            </v-list-item>
            <template v-if="recentSearches.length">
              <v-divider class="mb-4" />
              <v-list-item @click="clearTelemetryRecentSearches()">
                <v-list-item-title class="coro-link">
                  {{ $t("smartSearch.clearRecentSearches") }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <v-list-item v-else>
              <v-list-item-title>{{ $t("smartSearch.noRecentSearches") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </smart-search>
    <telemetry-table
      v-if="items.length || loading || showSkeletonLoader"
      class="edr-content"
      :items="items"
      :pagination="pagination"
      :loading="loading"
    />
    <empty-state v-else class="margin-auto mt-12 w-65">
      <template #icon>
        <v-icon icon="$fancySearch" :size="80" />
      </template>
      <template #description>
        <div class="headline5 mb-3 mt-3">
          {{ $t("edr.telemetryTab.table.noData.title") }}
        </div>
      </template>
      <template #subtitle>
        {{ $t("edr.telemetryTab.table.noData.description") }}
      </template>
      <template #button>
        <a class="coro-body2 coro-link" @click="clearFilters(clearFiltersCallback)">{{
          $t("edr.telemetryTab.table.noData.actionBtn")
        }}</a>
      </template>
    </empty-state>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, type Ref, watch, computed } from "vue";
import FilterWrapper from "@/components/FilterWrapper.vue";
import { useTelemetryStore } from "@/_store/endpoint-security/telemetry.module";
import { storeToRefs } from "pinia";
import { useFilters } from "@/composables/useFilters";
import { FilterContext, type TimeRange, useFiltersStore } from "@/_store/filters.module";
import { TelemetryType } from "@/constants/edr";
import { SearchSuggestion } from "@/constants/general";
import DateRangePicker from "@/components/DateRangePicker.vue";
import moment from "moment/moment";
import TelemetryTable from "@/components/tables/TelemetryTable.vue";
import SmartSearch, {
  SearchSegmentCreationStep,
  type SmartSearchSegmentMenuItem,
} from "@/components/smart-search/SmartSearch.vue";
import type { SmartSearchSegment } from "@/components/smart-search/SearchSegment.vue";
import capitalize from "lodash/capitalize";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import EmptyState from "@/components/EmptyState.vue";
import { useI18n } from "vue-i18n";

const filterContext = FilterContext.EDR_TELEMETRY;

export default defineComponent({
  components: {
    EmptyState,
    SmartSearch,
    TelemetryTable,
    DateRangePicker,
    FilterWrapper,
  },
  setup() {
    const i18n = useI18n();
    const presets = computed(() => [
      {
        label: i18n.t("dateRangePicker.lastSevenDays"),
        value: [moment().subtract(7, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
      {
        label: i18n.t("dateRangePicker.lastThirtyDays"),
        value: [moment().subtract(30, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
      {
        label: i18n.t("dateRangePicker.lastNinetyDays"),
        value: [moment().subtract(90, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
      {
        label: i18n.t("dateRangePicker.last365Days"),
        value: [moment().subtract(365, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
    ]);

    const telemetryStore = useTelemetryStore();
    const { localFilters, filtersUpdating, showClearFiltersButton, clearFilters } =
      useFilters(filterContext);
    const filtersStore = useFiltersStore();
    const { items, pagination, loading, recentSearches, showSkeletonLoader } =
      storeToRefs(telemetryStore);
    const {
      setHasMore,
      resetPagination,
      getItems,
      getTelemetryRecentSearches,
      clearTelemetryRecentSearches,
    } = telemetryStore;
    const smartFilters: Ref<SmartSearchSegment[]> = ref([]);
    const isMenuExpanded = ref(false);
    localFilters.value.searches = localFilters.value.searches.filter((e) => e.value);
    let previousSearchValues = cloneDeep(localFilters.value.searches);

    const searchKeys: SmartSearchSegmentMenuItem[] = [
      {
        displayName: i18n.t("smartSearch.searchPrefixes.name"),
        value: SearchSuggestion.NAME,
      },
      {
        displayName: i18n.t("smartSearch.searchPrefixes.hostname"),
        value: SearchSuggestion.HOSTNAME,
      },
      {
        displayName: i18n.t("smartSearch.searchPrefixes.enrollmentCode"),
        value: SearchSuggestion.ENROLLMENT_CODE,
      },
      {
        displayName: i18n.t("smartSearch.searchPrefixes.processName"),
        value: SearchSuggestion.PROCESS_NAME,
      },
      {
        displayName: i18n.t("smartSearch.searchPrefixes.processHash"),
        value: SearchSuggestion.PROCESS_HASH,
      },
      {
        displayName: i18n.t("smartSearch.searchPrefixes.user"),
        value: SearchSuggestion.USER,
      },
      {
        displayName: i18n.t("smartSearch.searchPrefixes.eventId"),
        value: SearchSuggestion.EVENT_ID,
      },
    ];

    const expandableSearchKeys: SmartSearchSegmentMenuItem[] = [
      {
        displayName: i18n.t("edr.telemetryTab.types.registryKey"),
        value: "registryKey",
        subFilters: [
          {
            displayName: i18n.t("smartSearch.searchPrefixes.currentValue"),
            value: SearchSuggestion.CURRENT_VALUE,
          },
          {
            displayName: i18n.t("smartSearch.searchPrefixes.oldValue"),
            value: SearchSuggestion.OLD_VALUE,
          },
          {
            displayName: i18n.t("smartSearch.searchPrefixes.operationType"),
            value: SearchSuggestion.REGISTRY_KEY_OPERATION_TYPE,
          },
        ],
      },
      {
        displayName: i18n.t("edr.telemetryTab.types.scheduledTask"),
        value: "scheduledTasks",
        subFilters: [
          {
            displayName: i18n.t("smartSearch.searchPrefixes.action"),
            value: SearchSuggestion.ACTION,
          },
          {
            displayName: i18n.t("smartSearch.searchPrefixes.operationType"),
            value: SearchSuggestion.SCHEDULED_TASK_OPERATION_TYPE,
          },
        ],
      },
      {
        displayName: i18n.t("edr.telemetryTab.types.accountEvent"),
        value: "accountEvents",
        subFilters: [
          {
            displayName: i18n.t("smartSearch.searchPrefixes.targetAccount"),
            value: SearchSuggestion.TARGET_ACCOUNT,
          },
          {
            displayName: i18n.t("smartSearch.searchPrefixes.domain"),
            value: SearchSuggestion.DOMAIN,
          },
        ],
      },
    ];

    const searchOperators: SmartSearchSegmentMenuItem[] = [
      {
        displayName: "=",
        value: "=",
      },
    ];

    const telemetryTypes = [
      TelemetryType.ACCOUNT_EVENT,
      TelemetryType.REGISTRY_KEY,
      TelemetryType.SCHEDULED_TASK,
      TelemetryType.USB_DEVICE_ACTIVITY,
      // TelemetryType.GROUP_POLICY_CHANGES, @TODO uncomment when BE will be fixed
    ];

    const suggestions = [
      SearchSuggestion.ENROLLMENT_CODE,
      SearchSuggestion.HOSTNAME,
      SearchSuggestion.PROCESS_HASH,
      SearchSuggestion.PROCESS_NAME,
    ];

    const getMenuItems = (
      currentStep: SearchSegmentCreationStep,
      searchText: string,
      keys: SmartSearchSegmentMenuItem[]
    ) => {
      if (currentStep === SearchSegmentCreationStep.CREATE_KEY) {
        return keys.filter((item: SmartSearchSegmentMenuItem) =>
          item.displayName.toLowerCase().startsWith(searchText.toLowerCase())
        );
      }
      if (currentStep === SearchSegmentCreationStep.CREATE_OPERATOR) {
        return searchOperators;
      }
      return [];
    };

    const handleSubFilterSelection = (
      filter: SmartSearchSegmentMenuItem,
      parentFilter: SmartSearchSegmentMenuItem,
      callback: (filterValue: SmartSearchSegmentMenuItem) => void
    ) => {
      const filterValue: SmartSearchSegmentMenuItem = {
        displayName: capitalize(`${parentFilter.displayName}:${filter.displayName}`),
        value: filter.value,
      };
      callback(filterValue);
    };

    const isOperatorMenu = (currentStep: string) => {
      return currentStep === SearchSegmentCreationStep.CREATE_OPERATOR;
    };

    const selectHistoryItem = async (item: SmartSearchSegment[]) => {
      localFilters.value = {
        ...localFilters.value,
        timeRange: (item.find((segment) => (segment.key?.value as unknown) === "timeRange")
          ?.value as unknown as TimeRange) || { start: "", end: "" },
        types:
          (item.find((segment) => (segment.key?.value as unknown) === "type")
            ?.value as unknown as TelemetryType[]) || [],
        searches: item.filter((segment) => segment.id),
      };
      filtersStore.setFilters(filterContext, localFilters.value);
      if (!isEqual(localFilters.value.searches, previousSearchValues)) {
        setHasMore(true);
        resetPagination();
        await getItems();
        await getTelemetryRecentSearches();
      }
    };

    const clearFiltersCallback = async () => {
      resetPagination();
      setHasMore(true);
      await getItems();
    };

    const onSmartFilterUpdated = async (event: SmartSearchSegment[]) => {
      filtersStore.setFilters(filterContext, {
        ...localFilters.value,
        searches: event,
      });
      localFilters.value.searches = event;
    };

    const onSearchClick = () => {
      document.getElementById("#smart-search")?.blur();
      setTimeout(async () => {
        await getItems();
        await getTelemetryRecentSearches();
      }, 200);
    };

    watch(
      filtersUpdating,
      async (newValue) => {
        if (newValue) {
          if (!isEqual(localFilters.value.searches, previousSearchValues)) {
            previousSearchValues = cloneDeep(localFilters.value.searches);
            return;
          }
          setHasMore(true);
          resetPagination();
          await getItems();
          await getTelemetryRecentSearches();
        }
      },
      { deep: true }
    );

    onMounted(async () => {
      resetPagination();
      await getTelemetryRecentSearches();
      await getItems({ showSkeletonLoader: true });
    });

    return {
      showClearFiltersButton,
      localFilters,
      telemetryTypes,
      suggestions,
      presets,
      items,
      pagination,
      loading,
      smartFilters,
      searchKeys,
      searchOperators,
      getMenuItems,
      handleSubFilterSelection,
      selectHistoryItem,
      onSmartFilterUpdated,
      clearFilters,
      expandableSearchKeys,
      isMenuExpanded,
      isOperatorMenu,
      getItems,
      onSearchClick,
      clearFiltersCallback,
      clearTelemetryRecentSearches,
      recentSearches,
      showSkeletonLoader,
    };
  },
});
</script>

<style scoped lang="scss">
.filter-menu {
  width: 125px;
}

:deep(.smart-search-container) {
  &.filter-active {
    .v-field {
      border: 2px solid rgb(var(--v-theme-indigo-darkest)) !important;
    }
  }

  .v-field__append-inner {
    border-left: 1px solid rgb(var(--v-theme-gray-light)) !important;
    padding-left: 8px !important;
  }
}

.menu-heading {
  color: rgb(var(--v-theme-indigo-medium));
  font-size: 12px;
}

:deep(*) {
  .v-list-item--density-default.v-list-item--one-line {
    font-size: 16px;
  }

  .icon-triangle {
    opacity: 1 !important;

    &:before {
      color: rgb(var(--v-theme-primary)) !important;
    }
  }
}
</style>
