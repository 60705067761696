<template>
  <div class="v-row h-100 ma-0" data-testid="forgot-password-page-container">
    <div class="v-col-12">
      <div class="w-100 h-100 d-flex justify-center align-center">
        <div class="forgot-password-content">
          <img v-if="showBrandedIcon" alt="Logo" :src="account.appLogo" height="36" />
          <v-img
            v-else
            src="/images/logos/coro-logo-large-primary.svg"
            width="110"
            height="36"
            contain
            class="mb-4"
          ></v-img>
          <v-card class="pa-6" color="white">
            <v-form ref="form" v-model="valid" @submit.prevent>
              <template v-if="!account.requestCompleted && showEmailInput">
                <div class="headline5">{{ $t("forgotPassword.title") }}</div>
                <div class="body1 text-indigo-medium mt-8 mb-8">
                  {{ $t("forgotPassword.explanation") }}
                </div>
                <v-text-field
                  v-model="userName"
                  :rules="[emailRule()]"
                  :disabled="account.requestInProgress"
                  data-testid="forgot-password-email"
                  variant="outlined"
                  validate-on="lazy blur"
                  :placeholder="$t('forms.email.placeholder')"
                  :label="$t('forms.email.label')"
                  @keyup.enter="onForgotPasswordClick(userName, false)"
                ></v-text-field>
                <div v-if="!!account.error" class="text-error caption mb-3">
                  {{ $t(`forgotPassword.${account.error}`) }}
                </div>

                <div class="d-flex flex-column mt-2 justify-center">
                  <v-btn
                    size="large"
                    rounded
                    data-testid="forgot-password-btn"
                    color="primary"
                    block
                    :loading="account.requestInProgress"
                    @click="onForgotPasswordClick(userName, false)"
                  >
                    {{ $t("forgotPassword.resetPasswordBtn") }}
                  </v-btn>
                  <router-link :to="'/login'" class="coro-link text-center mt-4">
                    {{ $t("forgotPassword.backToLoginBtn") }}
                  </router-link>
                </div>
              </template>
              <div v-else class="text-center">
                <v-icon class="no-items-icon mb-2" icon="$emails" size="90" />
                <div class="headline5">{{ $t("forgotPassword.checkYourEmail") }}</div>
                <div class="body1 text-indigo-medium mt-8">
                  {{ $t("forgotPassword.recoverInstruction", { email: userName }) }}
                </div>
                <div class="mt-12 body2 text-indigo-medium">
                  {{ $t("forgotPassword.didNotReceiveEmail") }}
                </div>
                <div role="button" class="coro-link" @click="onForgotPasswordClick(userName, true)">
                  {{ $t("forgotPassword.resendLink") }}
                </div>
                <div role="button" class="coro-link" @click="tryDifferentEmail()">
                  {{ $t("forgotPassword.tryDifferentEmail") }}
                </div>
                <router-link :to="'/login'" class="coro-link">
                  {{ $t("forgotPassword.backToLoginBtn") }}
                </router-link>
              </div>
            </v-form>
          </v-card>
          <div
            class="caption text-indigo-medium text-center mt-3"
            data-size="invisible"
            data-badge="inline"
            v-html="$t('general.recaptcha')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { emailRule } from "@/_helpers/validators";
import { CoroIcons } from "@/constants/coro-icon";
import { appLogoDefaultPath, useAccountStore } from "@/_store";
import { storeToRefs } from "pinia";

export default defineComponent({
  setup() {
    const accountStore = useAccountStore();
    const { setRequestInProgress, setRequestCompleted, forgotPassword } = accountStore;
    const { account } = storeToRefs(accountStore);
    const userName = ref("");
    const showEmailInput = ref(true);
    const valid = ref(true);
    const form = ref();

    const showBrandedIcon = computed(() => {
      return account.value.brandingAlias && account.value.appLogo !== appLogoDefaultPath;
    });

    const onForgotPasswordClick = async (email: string, showSnackbar: boolean = false) => {
      const { valid } = await form.value.validate();
      if (!valid) return;
      showEmailInput.value = false;
      await forgotPassword({
        email,
        showSnackbar,
      });
    };

    onMounted(() => {
      accountStore.$reset();
    });

    const tryDifferentEmail = () => {
      showEmailInput.value = true;
      setRequestCompleted(false);
    };

    return {
      showBrandedIcon,
      valid,
      form,
      account,
      userName,
      showEmailInput,
      emailRule,
      setRequestInProgress,
      onForgotPasswordClick,
      tryDifferentEmail,
      coroIcons: CoroIcons,
    };
  },
});
</script>

<style scoped lang="scss">
.forgot-password-content {
  max-width: 424px;
  min-width: 300px;
}
a {
  text-decoration: none;
}
</style>
