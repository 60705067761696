<template>
  <v-form ref="form" v-model="valid" validate-on="input">
    <span class="subtitle2"> {{ $t("modals.addWebhook.generalInfo.title") }}</span>
    <v-text-field
      v-model="localValue.name"
      class="mt-6"
      :label="localValue.name ? $t('modals.addWebhook.generalInfo.name') : ''"
      :rules="[required()]"
      variant="outlined"
      :placeholder="$t('modals.addWebhook.generalInfo.name')"
    />
    <v-text-field
      v-model="localValue.description"
      class="mt-6"
      :label="localValue.description ? $t('modals.addWebhook.generalInfo.description') : ''"
      variant="outlined"
      :placeholder="$t('modals.addWebhook.generalInfo.description')"
    />
    <span class="subtitle2"> {{ $t("modals.addWebhook.authentication.title") }}</span>
    <v-text-field
      v-model="localValue.webhookUrl"
      class="mt-6"
      :label="localValue.webhookUrl ? $t('modals.addWebhook.authentication.url') : ''"
      variant="outlined"
      :rules="urlRules"
      :placeholder="$t('modals.addWebhook.authentication.url')"
    />
    <v-text-field
      v-model="localValue.secret"
      class="mt-6"
      :label="localValue.secret ? $t('modals.addWebhook.authentication.secret') : ''"
      variant="outlined"
      :rules="[required()]"
      :placeholder="$t('modals.addWebhook.authentication.secret')"
    />
    <span class="subtitle2"> {{ $t("connectors.webhooks.table.sections.authorization") }}</span>
    <key-value-input
      class="mt-10 mb-4"
      v-model:value="localValue.headers"
      :key-label="$t('modals.addSiemConnector.header')"
      :value-label="$t('modals.addSiemConnector.value')"
      :show-add-btn="true"
      :key-config="{
        cols: 4,
        md: 4,
      }"
      :value-config="{
        cols: 8,
        md: 8,
      }"
      :add-btn-label="$t('modals.addSiemConnector.addBtn')"
    >
    </key-value-input>

    <span class="subtitle2"> {{ $t("modals.addWebhook.triggers.title") }}</span>
    <v-alert
      v-for="alert in triggerAlerts"
      :key="alert.type"
      :closable="false"
      type="warning"
      icon="icon-warning"
      :color="alert.type === SeverityType.ERROR ? 'red-faint' : 'yellow-faint'"
      class="mb-5 mt-5"
      :class="{
        'text-yellow-base warning-block': alert.type === SeverityType.WARNING,
        'text-red-dark': alert.type === SeverityType.ERROR,
      }"
    >
      <div
        class="subtitle1"
        :class="{
          'text-yellow-base': alert.type === SeverityType.WARNING,
          'text-red-dark': alert.type === SeverityType.ERROR,
        }"
      >
        {{ alert.title }}
      </div>
      <div v-html="alert.body"></div>
    </v-alert>

    <div
      v-if="showValidationAlert && isTriggersControlDirty && !atLeastOneTriggerSelected"
      class="caption text-error mt-2 d-flex align-center"
    >
      <v-icon icon="$warning" size="14" class="mr-2"></v-icon>
      {{ $t("validations.pleaseSelectAtLeastOneOption") }}
    </div>

    <div
      v-if="showValidationAlert && isTriggersControlDirty && atLeastOneExpiredTrigger"
      class="caption text-error mt-2 d-flex align-center"
    >
      <v-icon icon="$warning" size="14" class="mr-2"></v-icon>
      {{ $t("validations.expiredTriggerCannotBeSelected") }}
    </div>
    <div v-for="(triggerGroup, i) in triggers" :key="`triggerGroup${i}`" class="trigger-group">
      <div
        v-for="(trigger, index) in triggerGroup"
        :key="trigger.triggerUniqueId"
        :class="{ 'trigger-border': !!index && triggerGroup.length > 1 }"
        class="d-flex align-center justify-space-between"
      >
        <v-checkbox
          v-model="localValue.triggers"
          :value="trigger"
          density="default"
          class="ml-3"
          :multiple="true"
          :disabled="isTriggerShouldBeDisabled(trigger)"
          :rules="[atLeastOneIsRequired(), expiredTriggerValidator()]"
          :value-comparator="(a, b) => a.triggerUniqueId === b.triggerUniqueId"
          :label="
            $t(`connectors.webhooks.webhookTriggers.${trigger.triggerId}`) +
            (trigger.label ? ` (${trigger.version})` : '')
          "
        >
        </v-checkbox>
        <v-chip
          label
          v-if="trigger.label"
          size="small"
          :class="`status-label status-label--${trigger.label.type} mr-3`"
        >
          {{ trigger.label!.text }}
        </v-chip>
      </div>
    </div>

    <v-checkbox
      v-if="workspaceType === WorkspaceType.CHANNEL"
      v-model="localValue.applyToAllDescendants"
      class="mt-3"
      :ripple="false"
      :label="$t('modals.addWebhook.additionalConfiguration.applyToChildWorkspace')"
    >
    </v-checkbox>
  </v-form>
</template>

<script lang="ts">
import Patterns from "@/constants/patterns";
import type { PropType, Ref } from "vue";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { atLeastOneIsRequired, required } from "@/_helpers/validators";
import { useAccountStore } from "@/_store";
import KeyValueInput from "@/components/KeyValueInput.vue";
import { WorkspaceType } from "@/constants/workplaces";
import type { Webhook, WebhookTrigger } from "@/_store/connectors/webhooks.module";
import { useWebhooksStore } from "@/_store/connectors/webhooks.module";
import { storeToRefs } from "pinia";
import defaultsDeep from "lodash/defaultsDeep";
import { flatten } from "lodash";
import { SeverityType } from "@/constants/general";

const defaultWebhook = {
  name: "",
  description: "",
  webhookUrl: "",
  secret: "",
  applyToAllDescendants: false,
  headers: [],
  triggers: [],
};

export default defineComponent({
  components: { KeyValueInput },
  props: {
    config: {
      type: Object as PropType<{ item: Webhook; action: string }>,
      required: true,
    },
    showValidationAlert: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:valid", "update:localValue"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const valid = ref(false);
    const {
      account: { workspaceType },
    } = useAccountStore();
    const webhooksStore = useWebhooksStore();
    const { triggers, triggerAlerts } = storeToRefs(webhooksStore);
    const { getTriggers } = webhooksStore;
    const localValue: Ref<Partial<Webhook & { headers: Record<string, string>[] }>> = ref(
      defaultsDeep(props.config.item, defaultWebhook)
    );
    const form = ref();
    const selectedTriggersFromOriginalDataSet: Ref<WebhookTrigger[]> = ref([]);

    const isTriggersControlDirty = ref(false);

    emit("update:localValue", localValue);

    watch(valid, (value: boolean) => {
      if (value === null) {
        return emit("update:valid", true);
      }

      emit("update:valid", value);
    });

    watch(
      localValue,
      (value) => {
        emit("update:localValue", value);
      },
      { deep: true }
    );

    const validate = async () => {
      isTriggersControlDirty.value = true;

      const isValid = await form.value.validate();

      return isValid.valid;
    };

    const urlRules = [
      required(),
      (v: string) => {
        if (!Patterns.URL.test(v)) {
          return i18n.t("validations.url");
        }

        if (!v) {
          return i18n.t("validations.required");
        }

        return true;
      },
    ];

    const onFormatChange = () => {
      form.value.resetValidation();
    };

    const updateSelectedDataSet = () => {
      selectedTriggersFromOriginalDataSet.value = flatten(triggers.value).filter((trigger) =>
        localValue.value.triggers?.some(
          (selectedTrigger) => selectedTrigger.triggerUniqueId === trigger.triggerUniqueId
        )
      );
    };
    onMounted(async () => {
      await getTriggers(props.config.item?.id);

      updateSelectedDataSet();
    });

    const atLeastOneTriggerSelected = computed(() => {
      return !!localValue.value.triggers!.length;
    });

    watch(
      () => localValue.value.triggers,
      () => {
        isTriggersControlDirty.value = true;
        updateSelectedDataSet();
      }
    );

    const atLeastOneExpiredTrigger = computed(() => {
      // Need to check triggers from
      return selectedTriggersFromOriginalDataSet.value.some(
        (trigger: WebhookTrigger) => trigger.label?.type === SeverityType.ERROR
      );
    });

    const isTriggerShouldBeDisabled = (trigger: WebhookTrigger) => {
      return (
        trigger.label?.type === SeverityType.ERROR &&
        localValue.value.triggers!.every(
          (selectedTrigger) => trigger.triggerUniqueId !== selectedTrigger.triggerUniqueId
        )
      );
    };

    const expiredTriggerValidator = (
      errorMessage: string = i18n.t("validations.expiredTriggerCannotBeSelected")
    ) => {
      return () => {
        if (atLeastOneExpiredTrigger.value) {
          return errorMessage;
        }
        return true;
      };
    };

    return {
      isTriggersControlDirty,
      valid,
      isTriggerShouldBeDisabled,
      localValue,
      urlRules,
      onFormatChange,
      workspaceType,
      WorkspaceType,
      form,
      validate,
      triggers,
      required,
      atLeastOneIsRequired,
      atLeastOneTriggerSelected,
      triggerAlerts,
      atLeastOneExpiredTrigger,
      expiredTriggerValidator,
      SeverityType,
    };
  },
});
</script>

<style lang="scss" scoped>
.trigger-group {
  border: 1px solid rgb(var(--v-theme-indigo-pale));
  border-radius: 8px;
  margin-top: 10px;
}

.trigger-border {
  border-top: 1px solid rgb(var(--v-theme-indigo-pale));
}

:deep(.trigger-group) {
  .v-selection-control--error:not(.v-selection-control--disabled) .v-label {
    color: inherit;
  }
}
</style>
