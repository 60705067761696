import axios from "axios";
import { AxiosError, type AxiosResponse, type InternalAxiosRequestConfig } from "axios";
import qs from "qs";
import { extractCoroSubdomain, generateDeviceId, resetPersistedStores } from "@/_helpers/utils";
import router from "@/_helpers/router";
import { AccountErrors } from "@/constants/account";
import type { RequestError } from "@/types";
import { useAccountStore } from "@/_store/account.module";
import type { App } from "vue";
import { useErrorsStore } from "@/_store/errors.module";
import { RouteName } from "@/constants/routes.ts";
import { i18n } from "@/plugins/i18n.ts";
import api from "@/_helpers/api.ts";
import { useDialogsStore } from "@/_store/dialogs.module.ts";
let refreshTokenPromise: Promise<any> | null = null;
let logoutPromise: Promise<any> | null = null;

export const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  paramsSerializer: {
    serialize: (params) => {
      return qs.stringify(params, { arrayFormat: "brackets", skipNulls: true });
    },
  },
});

async function updateRefreshToken() {
  const accountStore = useAccountStore();
  if (!refreshTokenPromise) {
    refreshTokenPromise = accountStore
      .getRefreshToken()
      .then((response) => {
        const newToken = response.data.accessToken;
        accountStore.setToken(newToken);
        return newToken;
      })
      .catch(async (err) => {
        await logoutAfterTokenExpiration();
        throw err; // Handle logout or token expiration here
      })
      .finally(() => {
        refreshTokenPromise = null; // Reset the promise
      });
  }
  return refreshTokenPromise;
}

async function logoutAfterTokenExpiration() {
  const accountStore = useAccountStore();
  if (!logoutPromise) {
    logoutPromise = axiosInstance
      .request(api.logout())
      .catch((err) => {
        console.error(err);
        throw err; // Handle logout or token expiration here
      })
      .finally(async () => {
        const subdomain = extractCoroSubdomain(window.location.hostname);
        subdomain ? accountStore.resetStateWithoutBranding() : accountStore.$reset();
        resetPersistedStores();
        useDialogsStore().closeDialog();
        localStorage.setItem("logout-event", `logout${Math.random()}`);
        await router.push({ name: RouteName.LOGIN });
        logoutPromise = null; // Reset the promise
      });
  }
  return logoutPromise;
}

const handle401Error = async (
  error: AxiosError<RequestError>,
  originalRequest: InternalAxiosRequestConfig
) => {
  const responseUrl = error.response?.config?.url ?? "";
  if (responseUrl !== "/refreshToken" && responseUrl !== "/auth/transfer") {
    try {
      // Refresh the token
      await updateRefreshToken();
      // Update the Authorization header with the new token
      originalRequest.headers["Authorization"] = `Bearer ${useAccountStore().account.token}`;
      // Retry the original request
      return await axiosInstance.request(originalRequest);
    } catch (err: any) {
      if (responseUrl !== "/admin-users/joinWorkspace" && err?.status === 401) {
        await logoutAfterTokenExpiration();
      }
      throw err;
    }
  }
  throw error;
};

const handleMFAError = () => {
  router.push({ name: RouteName.MFA_PAGE }).catch(() => {});
};

const shouldHandleError = (responseUrl: string, nonHandlableEndpoints: string[]) => {
  return !nonHandlableEndpoints.some((endpoint) => responseUrl.startsWith(endpoint));
};

const handleGeneralError = (error: AxiosError<RequestError>, nonHandlableEndpoints: string[]) => {
  const responseUrl = error.response?.config?.url ?? "";
  if (shouldHandleError(responseUrl, nonHandlableEndpoints)) {
    handleError(error);
  }
  return Promise.reject(error);
};

function handleError(error: AxiosError<RequestError>) {
  if (error.isAxiosError && error.code === AxiosError.ERR_CANCELED) {
    return; // Ignore canceled requests
  }

  const errorsStore = useErrorsStore();
  const response = error.response;
  const data = response?.data;
  const errors = data?.errors;

  let errorMessage: string = i18n.global.t("errors.somethingWentWrong", {
    url: response?.config?.url ?? "URL",
    status: response?.status ?? i18n.global.t("general.unknown"),
  });

  let showDialog = false;

  if (errors?.length) {
    errorMessage = errors[0];
    showDialog = true;
  }

  errorsStore.push({ message: errorMessage, showDialog });
}

const onRequest = (config: InternalAxiosRequestConfig) => {
  // perform a task before the request is sent
  const accountStore = useAccountStore();
  const token = accountStore.account.token;
  const workplace = accountStore.account.workplace;

  if (config?.url?.includes("/login") || config?.url?.includes("/signup")) {
    if (!accountStore.account.deviceId) {
      accountStore.setDeviceId(generateDeviceId());
    }

    config.headers["X-Header-DeviceId"] = accountStore.account.deviceId;
  }

  if (token) {
    let bearerValue;
    switch (config.url) {
      case "/logout":
      case "/refreshToken":
        bearerValue = `Bearer ${accountStore.account.refreshToken}`;
        break;
      default:
        bearerValue = `Bearer ${token}`;
        break;
    }
    if (!config?.url?.startsWith("/v2/workspace") && workplace) {
      config.headers["Workspace"] = config.headers["Workspace"] || workplace;
    }
    config.headers["Authorization"] = bearerValue;
  }
  config.headers["Accept-Language"] = accountStore.displayLanguage;
  return config;
};

const onResponse = (response: AxiosResponse) => {
  return response;
};

const onError = async (error: AxiosError<RequestError>) => {
  // endpoints in which we should not show error modal
  const nonHandlableEndpoints = [
    "/login",
    "/resetPassword",
    "/forgotPassword",
    "/logout",
    "/refreshToken",
    "/services/box/configUpload",
    "/branding",
    "/devices/ssh/",
    "/tickets/", // todo: uncomment when report will contain workspaceid
    "/mobile/activate",
    "/mobile/resendInvite",
    "/msp/promo-codes",
  ];
  const response = error.response;
  const errorStatus = error?.response?.status;
  const originalRequest = error.config as InternalAxiosRequestConfig;
  const errorCode = response?.data?.errorCode ?? "";

  if (errorStatus === 401) {
    return await handle401Error(error, originalRequest);
  } else if (errorCode === AccountErrors.MFA_REQUIRED) {
    handleMFAError();
  } else {
    return handleGeneralError(error, nonHandlableEndpoints);
  }

  return Promise.reject(error);
};

export default {
  install: (app: App): void => {
    app.config.globalProperties.$http = axiosInstance;

    axiosInstance.interceptors.response.use(onResponse, onError);
    axiosInstance.interceptors.request.use(onRequest);
  },
};
