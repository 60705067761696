import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import {
  AllowBlockListType,
  AllowListSubType,
  EmailSecurityAllowBlockItemType,
  PhishingAction,
} from "@/constants/phishing";
import { downloadFile } from "@/_helpers/utils";
import type { Pagination } from "@/types";
import { useFiltersStore } from "@/_store/filters.module";
import type { EmailError } from "@/constants/emails";
import type { SecurityMode } from "@/constants/emails";
import type { QuarantineFolder, ScanSensitivityLevels } from "@/constants/emails";
import axios from "axios";

export interface EmailSecuritySettings {
  securityEnabled: boolean;
  scanMalware: {
    enabled: boolean;
    action: SecurityMode;
  };
  scanSpam: {
    enabled: boolean;
    action: SecurityMode;
  };
  scanPhishing: {
    enabled: boolean;
    action: SecurityMode;
  };
  scanNewlyRegisteredDomains: {
    enabled: boolean;
    action: SecurityMode;
  };
  scanEncryptedAttachments: {
    enabled: boolean;
    action: SecurityMode;
  };
  scanUnverifiedSender: {
    enabled: boolean;
    action: SecurityMode;
  };
  scanUnknownExternalSender: {
    enabled: boolean;
    action: SecurityMode;
  };
  quarantineFileTypesEnabled: boolean;
  quarantineFileTypes: string[];
  quarantineCustomFileTypes: string[];
  quarantineFolder: QuarantineFolder;
  senderClassificationInheritanceEnabled: boolean;
  expirationEnabled: boolean;
  expirationCountDays: number;
  restrictForwarding: boolean;
  securityAwarenessConfigDto: SecurityAwarenessConfigDto;
  exclusionSecuritySettingsDto: {
    emailSubjectKeywordsEnabled: boolean;
    emailSubjectKeywords: string[];
  };
  scanSensitivity: ScanSensitivityLevels;
}

export interface ProviderConfiguration {
  name: string;
  terms: string[];
  headers: { name: string; value: string }[];
}

export interface SecurityAwarenessConfigDto {
  enabled: boolean;
  providerConfigurations: ProviderConfiguration[];
}

export interface AllowBlockListItem {
  name: string;
  type: EmailSecurityAllowBlockItemType;
  allowList: boolean;
  inheritedFromParent: boolean;
  senderClassificationType: AllowListSubType;
}

export interface EmailSettingsState {
  settings: EmailSecuritySettings;
  showSkeletonLoader: boolean;
  allowBlockList: AllowBlockListItem[];
  pages: number;
  total: number;
  pagination: Pagination;
  loading: boolean;
}

export interface RemoveFromAllowBlockListPayload {
  name: string;
  listType: AllowBlockListType;
  senderClassificationType: AllowListSubType;
}

export interface AllowBlockListForm {
  items: string[];
  errors: EmailError[];
  action: PhishingAction;
}

const defaultState = {
  settings: {} as EmailSecuritySettings,
  showSkeletonLoader: false,
  allowBlockList: [],
  pages: 0,
  total: 0,
  pagination: {
    page: 0,
    pageSize: 25,
  },
  loading: false,
};

export const useEmailSettingsStore = defineStore("emailSettingsStore", {
  state: (): EmailSettingsState => ({ ...defaultState }),
  actions: {
    setPagination(pagination: Pagination) {
      this.pagination = pagination;
    },
    resetPagination() {
      this.pagination = { ...defaultState.pagination };
    },
    async getEmailsSettings() {
      this.showSkeletonLoader = true;

      const request = {
        ...api.emailsSettings(),
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.settings = data;
      } catch (error) {
        console.error(error);
      }

      this.showSkeletonLoader = false;
    },
    async updateEmailsSettings(newSettings: EmailSecuritySettings): Promise<void> {
      const request = {
        ...api.emailsSettings(),
        method: "put",
        data: newSettings,
      };

      try {
        await axiosInstance.request(request);
        this.settings = newSettings;
      } catch (error) {
        console.error(error);
      }
    },
    async resetEmailFileTypes() {
      const request = {
        ...api.resetEmailFileTypes(),
        method: "post",
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.settings = data;
      } catch (error) {
        console.error(error);
      }
    },
    async getAllowBlockList() {
      this.showSkeletonLoader = true;
      this.loading = true;

      const filtersStore = useFiltersStore();
      const filters = filtersStore.filters.allowBlockListFilters;

      const request = {
        ...api.getPhishingAllowBlackList(),
        params: {
          ...filters,
          ...this.pagination,
        },
      };

      try {
        const { data } = await axiosInstance.request(request);

        this.allowBlockList = data.items;
        this.total = data.total;
      } catch (error) {
        console.error(error);
      }

      this.showSkeletonLoader = false;
      this.loading = false;
    },
    async removeFromAllowlist(payload: AllowBlockListItem) {
      await this.removeFromAllowBlockList({
        name: payload.name,
        listType: AllowBlockListType.ALLOW,
        senderClassificationType: payload.senderClassificationType,
      });
    },
    async removeFromBlocklist(payload: AllowBlockListItem) {
      await this.removeFromAllowBlockList({
        name: payload.name,
        listType: AllowBlockListType.BLOCK,
        senderClassificationType: payload.senderClassificationType,
      });
    },
    async addToAllowBlockList(payload: AllowBlockListForm) {
      const { items, action } = payload;
      const data = {
        items,
        allowList: action === PhishingAction.ADD_TO_ALLOWLIST,
      };

      const request = {
        ...api.getPhishingAllowBlackList(),
        data,
        method: "POST",
      };

      try {
        await axiosInstance.request(request);

        this.getAllowBlockList();
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async removeFromAllowBlockList(payload: RemoveFromAllowBlockListPayload) {
      const request = {
        ...api.getPhishingAllowBlackList(),
        params: payload,
        method: "DELETE",
      };

      try {
        await axiosInstance.request(request);

        this.getAllowBlockList();
      } catch (error) {
        console.error(error);
      }
    },
    async importAllowBlockListFromCsv(payload: File) {
      const data = new FormData();
      data.append("file", payload);
      const request = {
        ...api.importAllowBlockList(),
        data,
      };

      return axiosInstance.request(request);
    },
    async downloadAllowBlockListExampleCsv() {
      const request = {
        ...api.getPhishingAllowBlackListExampleCsv(),
      };

      try {
        const { data, headers } = await axiosInstance.request(request);
        const blob = new Blob([data], { type: headers["content-type"] });
        const fileName = "AllowBlockListExample.csv";

        downloadFile(blob, fileName);
      } catch (error) {
        console.error(error);
      }
    },
    async reportPhishingImpersonation({
      token,
      ...data
    }: {
      allowList?: boolean;
      syncToken: string;
      emailAddress: string;
      token: string;
    }): Promise<boolean> {
      const request = {
        ...api.reportPhishingImpersonation(),
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        // using axios here because we need to avoid interceptor for this particular request as it uses it's own token
        await axios.request(request);
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  },
});
