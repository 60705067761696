<template>
  <div>
    <div v-if="loading" class="align-center d-flex h-100 justify-center">
      <v-progress-circular size="120" indeterminate />
    </div>
    <v-table
      v-else-if="tunnelTestResult.length"
      class="table-container border-indigo-faint rounded-l elevation-0"
    >
      <thead>
        <tr>
          <th class="text-left text-indigo-medium">
            {{ $t(`network.siteToSite.tunnels.table.tunnel`) }}
          </th>
          <th class="text-left text-indigo-medium">
            {{ $t(`network.siteToSite.tunnels.table.testState.title`) }}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="server in tunnelTestResult" :key="server.ip">
          <td class="subtitle2">{{ config.item.siteName }}</td>
          <td class="subtitle2" :class="getPassedClass(server)">
            {{ getPassedText(server) }}
          </td>
          <td class="w-25">
            <div class="d-flex align-center justify-end">
              <v-btn
                color="primary"
                rounded
                elevation="0"
                variant="outlined"
                @click="downloadTunnelLogs(config.item)"
              >
                {{ $t(`network.siteToSite.tunnels.actions.downloadTunnelLog`) }}
              </v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, type PropType, ref } from "vue";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import {
  type SiteToSiteTunnel,
  type SiteToSiteTunnelServer,
  SiteToSiteTunnelTestState,
  useSiteToSiteTunnelStore,
} from "@/_store/network/site-to-site-tunnels.module";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig<SiteToSiteTunnel>>,
      required: true,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const loading = ref(false);
    const tunnelTestResult = ref<SiteToSiteTunnelServer[]>([]);
    const { testTunnel, getTunnelsList, downloadTunnelLogs } = useSiteToSiteTunnelStore();

    onMounted(async () => {
      loading.value = true;
      tunnelTestResult.value = await testTunnel(props.config.item);
      await getTunnelsList(true, false);
      loading.value = false;
    });

    const getPassedClass = (item: SiteToSiteTunnelServer) => {
      return item.passed ? "text-green-dark" : "text-red-dark";
    };

    const getPassedText = (item: SiteToSiteTunnelServer) => {
      return i18n.t(
        `network.siteToSite.tunnels.table.testState.${
          item.passed ? SiteToSiteTunnelTestState.PASSED : SiteToSiteTunnelTestState.FAILED
        }`
      );
    };

    return {
      loading,
      tunnelTestResult,
      downloadTunnelLogs,
      getPassedClass,
      getPassedText,
    };
  },
});
</script>

<style lang="scss" scoped>
.table-container {
  --v-table-row-height: 68px;
  --v-table-header-height: 68px;

  border: 1px solid rgb(var(--v-theme-indigo-faint));

  th {
    border-bottom: 1px solid rgb(var(--v-theme-indigo-faint)) !important;
  }
}
</style>
