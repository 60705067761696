<template>
  <div class="mt-5">
    <h6 class="headline6 mb-4">
      {{ $t("ticketDetails.fullDetails.title") }}
    </h6>
    <table-wrapper>
      <v-data-table-server
        :headers="tableHeaders"
        :items="fileFindings"
        :single-expand="false"
        v-model:expanded="expanded"
        :loading="fileFindingsLoading"
        :items-length="fileFindingsTotal"
        disable-pagination
        hide-default-footer
        item-value="id"
        show-expand
        fixed-header
        expand-icon="icon-chevron"
      >
        <template #headers>
          <tr>
            <th class="w-10">
              <v-fab size="24" icon="expand-all-icon" color="primary" class="expand-all-icon">
                <v-icon
                  :class="{ rotated: expandAll }"
                  @click="expandAll = !expandAll"
                  icon="$chevronDown"
                ></v-icon>
              </v-fab>
            </th>
            <th class="w-80">
              {{ $t("ticketDetails.fullDetails.fileFindings.title") }}
            </th>
            <th class="text-right">
              {{ $t("ticketDetails.fullDetails.fileFindings.count") }}
            </th>
          </tr>
        </template>
        <template #item="{ item, index, isExpanded, toggleExpand, internalItem }">
          <tr :id="`${index}`">
            <td class="w-10">
              <v-icon
                class="ml-1"
                :class="{ rotated: isExpanded(internalItem) }"
                @click="toggleExpand(internalItem)"
                icon="$chevronDown"
              >
              </v-icon>
            </td>
            <td class="w-80 body2">
              <div class="word-break-all white-space-normal">
                {{ item.fileName }}
              </div>
            </td>
            <td class="body2 text-right">
              {{ item.findings.length }}
            </td>
          </tr>
        </template>
        <template #expanded-row="{ columns, item }">
          <td class="w-10"></td>
          <td :colspan="columns.length" class="pl-4 word-break-all white-space-normal">
            <div class="expandable-content">
              <div class="mr-4 mt-6 mb-6 instance-details">
                <findings-full-details
                  :findings-details="item"
                  :show-title="false"
                  :show-subtitle="false"
                />
              </div>
            </div>
          </td>
        </template>
      </v-data-table-server>
    </table-wrapper>
    <div v-if="findingsToLoad > 0" class="load-more-section">
      <div class="coro-link cursor-pointer" @click="loadMoreItems()">
        {{ $t("ticketDetails.plusNFindings", { n: findingsToLoad }) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import FindingsFullDetails from "@/components/tickets/ticket-preview-details/full-details/FindingsFullDetails.vue";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useTicketStore } from "@/_store/tickets/ticket.module";
import { storeToRefs } from "pinia";
import TableWrapper from "@/components/TableWrapper.vue";

export default defineComponent({
  components: {
    TableWrapper,
    FindingsFullDetails,
  },
  setup() {
    const ticketStore = useTicketStore();
    const { fileFindings, fileFindingsLoading, fileFindingsTotal } = storeToRefs(ticketStore);
    const page = ref(0);
    const expanded = ref<string[]>([]);
    const expandAll = ref(false);

    const findingsToLoad = computed(() => {
      return fileFindingsTotal.value - (fileFindings.value ?? []).length;
    });

    watch(expandAll, (newVal) => {
      if (newVal) {
        expanded.value = [...fileFindings.value.map((v) => v.fileName)];
      } else {
        expanded.value = [];
      }
    });

    const getItems = async (virtualScroll = false) => {
      await ticketStore.getFileFindings({
        page: page.value,
        virtualScroll,
      });
    };

    onMounted(async () => {
      await getItems();
    });

    const loadMoreItems = () => {
      page.value += 1;
      getItems(true);
    };

    return {
      tableHeaders: [
        { title: "", key: "data-table-expand", sortable: false },
        {
          key: "fileName",
          sortable: false,
        },
        { key: "totalFindings", sortable: false },
      ],
      page,
      expanded,
      expandAll,
      fileFindings,
      fileFindingsLoading,
      fileFindingsTotal,
      findingsToLoad,
      loadMoreItems,
    };
  },
});
</script>

<style lang="scss" scoped>
.expand-all-icon {
  .v-icon::before {
    color: rgb(var(--v-theme-white)) !important;
  }
}

.load-more-section {
  border-left: 1px solid rgb(var(--v-theme-indigo-pale));
  border-right: 1px solid rgb(var(--v-theme-indigo-pale));
  border-bottom: 1px solid rgb(var(--v-theme-indigo-pale));
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(*) {
  .v-data-table__expanded__content {
    box-shadow: unset !important;
  }

  .v-btn--fab.v-size--small {
    height: 30px;
    width: 30px;
  }
  .v-btn {
    padding: 0 !important;
  }

  .v-table__wrapper {
    box-shadow: unset !important;
    border-radius: unset !important;
    border: 1px solid rgb(var(--v-theme-indigo-faint));
  }

  .full-details-wrapper {
    border: none !important;
  }

  .findings-wrapper {
    padding: 0 !important;
  }
}
</style>
