<template>
  <div class="coro-widget">
    <template v-if="showWidgetData">
      <div class="coro-widget__content">
        <div class="coro-widget__tile coro-widget__tile-content">
          <div class="mb-3">
            <div class="coro-widget-number text-left" data-testid="data-widget-scanned-emails">
              {{ widgetData.totalObjectsProcessed.toLocaleString() }}
            </div>
            <div class="body2">
              {{ $t(`dashboard.widgets.${widgetConfig.widgetType}.objectsProcessed`) }} /
              {{ $t(`dashboard.widgets.${widgetConfig.widgetType}.last90Days`) }}
            </div>
          </div>
          <div v-if="widgetData.topViolators.length" class="mb-3 mt-12">
            <div class="subtitle2 mb-4" data-testid="data-widget-top-violators">
              {{ $t(`dashboard.widgets.${widgetConfig.widgetType}.topViolators`) }}
            </div>
            <div v-for="violator in widgetData.topViolators" :key="violator.email" class="mb-4">
              <div
                class="coro-link body2"
                :data-testid="`data-widget-${kebabCase(violator.email)}-link`"
                @click="viewViolator(violator)"
              >
                {{ violator.email }}
              </div>
              <div class="d-flex justify-start align-center">
                <coro-progress
                  :value="violator.percentage"
                  :height="4"
                  color="rgb(var(--v-theme-indigo-medium))"
                >
                </coro-progress>
                <span class="ml-2 body2 text-primary">{{ violator.count }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="coro-widget__tile">
          <vulnerabilities-tile
            :widget-data="widgetData"
            @all-resolved-link-clicked="viewAllTickets(false, widgetFilter)"
            @all-active-link-clicked="viewAllTickets(true, widgetFilter)"
            @vulnerability-clicked="viewVulnerability($event, widgetFilter)"
          ></vulnerabilities-tile>
        </div>
      </div>
    </template>
    <div
      v-else
      data-testid="data-widget-no-data-container"
      class="coro-widget__content coro-widget--empty"
    >
      <v-icon class="empty-data-icon" icon="$data" />
      <div class="headline5 text-center mt-4">
        {{ $t(`dashboard.widgets.${widgetConfig.widgetType}.noDataLossProtection`) }}
      </div>
      <div class="caption text-indigo-medium mt-3 mb-8">
        {{ $t(`dashboard.widgets.${widgetConfig.widgetType}.noDLPServicesConnected`) }}
      </div>
      <router-link
        v-if="
          !isAccessRestricted(
            rolePermissionsScope.WORKSPACE_MANAGEMENT,
            workspaceManagementScope.CLOUD_APPS
          )
        "
        :to="{ name: RouteName.CLOUD_APPS_PAGE }"
      >
        <v-btn color="primary" rounded size="large" data-testid="data-widget-connect-btn">
          {{ $t("general.connect") }}
        </v-btn>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { TicketsModuleFilter, TicketStatus } from "@/constants/tickets";
import CoroProgress from "@/components/CoroProgress.vue";
import { computed, defineComponent, type PropType } from "vue";
import VulnerabilitiesTile from "@/components/VulnerabilitiesTile.vue";
import kebabCase from "lodash/kebabCase";
import { useRouter } from "vue-router";
import { getCurrentDate, getNinetyDaysAgo } from "@/constants/date-range-picker";
import type { WidgetConfig, WidgetData } from "@/_store/dashboard/dashboard.module";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { useWidgetContent } from "@/composables/useWidgetContent";
import { RouteName } from "@/constants/routes";

export default defineComponent({
  props: {
    widgetData: {
      type: Object as PropType<WidgetData>,
      required: true,
    },
    widgetConfig: {
      type: Object as PropType<WidgetConfig>,
      required: true,
    },
  },
  components: {
    VulnerabilitiesTile,
    CoroProgress,
  },
  setup(props) {
    const router = useRouter();
    const showWidgetData = computed(() => {
      return props.widgetData && props.widgetData.isProtectionEnabled;
    });

    const { viewVulnerability, viewAllTickets, isAccessRestricted } = useWidgetContent();

    const viewViolator = (violator: { email: string }) => {
      router.push({
        name: "tickets",
        query: {
          from: "widget",
          status: TicketStatus.ALL,
          widget: props.widgetConfig.widgetType,
          search: `violator:${violator.email}`,
          startTime: getNinetyDaysAgo(),
          endTime: getCurrentDate(),
          protectedUsersOnly: "true",
        },
      });
    };

    return {
      showWidgetData,
      viewViolator,
      viewVulnerability,
      viewAllTickets,
      isAccessRestricted,
      ticketsWidgetFilters: TicketsModuleFilter,
      rolePermissionsScope: RolePermissionsScope,
      kebabCase,
      widgetFilter: TicketsModuleFilter.USER_DATA_GOVERNANCE,
      workspaceManagementScope: WorkspaceManagementScopeSections,
      RouteName,
    };
  },
});
</script>

<style scoped lang="scss">
.coro-link {
  color: rgb(var(--v-theme-primary)) !important;
}
</style>
