import { ref, computed, watch, type Ref } from "vue";
import isEqual from "lodash/isEqual";
import differenceWith from "lodash/differenceWith";
import unionWith from "lodash/unionWith";
import intersectionWith from "lodash/intersectionWith";

export function useSimpleSelectableTable<T>(items: Ref<T[]>) {
  const selectedItems = ref<T[]>([]);

  const selectedItemsQuantity = computed(() => selectedItems.value.length);

  const allItemsSelected = computed(
    () => differenceWith(items.value, currentPageSelectedItems.value, isEqual).length === 0
  );

  const currentPageSelectedItems = computed(() =>
    intersectionWith(selectedItems.value, items.value, isEqual)
  );

  const isIndeterminate = computed(() => {
    if (allItemsSelected.value) {
      return false;
    }
    // If there are items selected on other pages, or not all items on the current page are selected, return true.
    const hasSelectionsOutsideCurrentPage =
      selectedItems.value.length > currentPageSelectedItems.value.length;
    const hasPartialSelectionOnCurrentPage =
      currentPageSelectedItems.value.length > 0 &&
      currentPageSelectedItems.value.length < items.value.length;

    return hasSelectionsOutsideCurrentPage || hasPartialSelectionOnCurrentPage;
  });

  /**
   * Handle selection of a single row.
   */
  const handleOneRowSelection = (selected: boolean, item: T) => {
    if (selected) {
      selectedItems.value = unionWith(selectedItems.value, [item], isEqual);
    } else {
      selectedItems.value = differenceWith(selectedItems.value, [item], isEqual);
    }
  };

  /**
   * Handle selection or deselection of all rows.
   */
  const handleAllRowsSelection = (selectAll: boolean) => {
    if (selectAll) {
      selectedItems.value = unionWith(selectedItems.value, items.value, isEqual);
    } else {
      selectedItems.value = differenceWith(selectedItems.value, items.value, isEqual);
    }
  };

  const clearSelection = () => {
    selectedItems.value = [];
  };

  watch(
    items,
    (newItems) => {
      selectedItems.value = unionWith(
        selectedItems.value.filter((selected) => newItems.some((item) => isEqual(item, selected))),
        selectedItems.value,
        isEqual
      );
    },
    { deep: true }
  );

  return {
    selectedItems,
    handleOneRowSelection,
    handleAllRowsSelection,
    clearSelection,
    isIndeterminate,
    allItemsSelected,
    selectedItemsQuantity,
  };
}
