import { defineStore } from "pinia";
import { EncryptionStrength } from "@/constants/network.ts";
import type { DeviceLabel } from "@/_store/devices-settings.module.ts";
import api from "@/_helpers/api.ts";
import { axiosInstance } from "@/plugins/https.ts";

export interface NetworkSettingsState {
  settings: NetworkSettings;
  loading: boolean;
}

export interface MobileBannerConfiguration {
  visible: boolean;
}

export interface NetworkSettings {
  manualDisconnectionEnabled: boolean;
  manualDisconnectionLabels: DeviceLabel[];
  blockLocalNetworkEnabled: boolean;
  blockLocalNetworkLabels: DeviceLabel[];
  dataCiphers: EncryptionStrength;
  mobileBannerConfiguration: MobileBannerConfiguration;
}

const defaultNetworkSettingsState: NetworkSettingsState = {
  loading: false,
  settings: {
    manualDisconnectionEnabled: false,
    manualDisconnectionLabels: [],
    blockLocalNetworkEnabled: false,
    blockLocalNetworkLabels: [],
    dataCiphers: EncryptionStrength.AES256,
    mobileBannerConfiguration: {} as MobileBannerConfiguration,
  },
};

export const useNetworkSettingsStore = defineStore("networkSettings", {
  state: () => ({ ...defaultNetworkSettingsState }),
  actions: {
    async initNetworkSettings() {
      this.loading = true;
      await this.fetchSettings();
      await this.getMobileBannerConfiguration();
      this.loading = false;
    },

    async fetchSettings() {
      try {
        const { data } = await axiosInstance.request({
          ...api.networkSettings(),
          method: "GET",
        });
        this.settings = data;
      } catch (e) {
        console.error(e);
      }
    },
    async updateSettings(data: NetworkSettings) {
      try {
        await axiosInstance.request({
          ...api.networkSettings(),
          method: "PUT",
          data,
        });
      } catch (e) {
        console.error(e);
      }
    },

    async getMobileBannerConfiguration() {
      const requestConfig = api.getMobileBannerConfiguration();

      try {
        const { data } = await axiosInstance.request(requestConfig);
        this.settings.mobileBannerConfiguration = { ...data };
      } catch (e) {
        console.error(e);
      }
    },

    async updateMobileBannerConfiguration(payload: { visible: boolean }): Promise<void> {
      const requestConfig = api.updateMobileBannerConfiguration(payload);

      try {
        await axiosInstance.request(requestConfig);
        await this.getMobileBannerConfiguration();
      } catch (e) {
        console.error(e);
      }
    },
  },
});
