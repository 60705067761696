<template>
  <secure-web-gateway-settings
    v-if="showSettings"
    class="mb-10"
    :loading="configurationLoading"
    :settings="secureWebGatewaySettings"
    @settings-updated="updateSwgSettings"
  />
  <div>
    <div class="d-flex justify-space-between align-center mb-5">
      <div class="d-flex flex-column">
        <span class="subtitle1" v-text="$t('network.secureWebGateway.allowBlockList')" />
        <span
          class="body2 text-indigo-medium"
          v-text="$t('network.secureWebGateway.allowBlockListDescription')"
        />
      </div>
      <div class="d-flex align-center">
        <filter-wrapper
          class="mr-2"
          :show-clear-button="showClearFiltersButton"
          @clear-filters-clicked="clearFilters(getSwgUrlList)"
        >
          <v-select
            v-model="localFilters.category"
            density="compact"
            class="search-field filter-menu categories-filter mr-2"
            :items="categories"
            :class="{ 'filter-active': !!localFilters.category }"
            :placeholder="$t('network.secureWebGateway.allCategories')"
            outlined
          >
            <template #item="{ item, props }">
              <v-list-item v-bind="props" title="">
                <span v-text="$t(`network.secureWebGateway.filters.${item.value}`)" />
              </v-list-item>
            </template>
            <template #selection="{ item }">
              <span v-text="$t(`network.secureWebGateway.filters.${item.raw}`)" />
            </template>
          </v-select>
          <v-select
            v-model="localFilters.allowed"
            density="compact"
            class="search-field filter-menu mr-2"
            :items="listFilterChoices"
            :class="{ 'filter-active': !!localFilters.allowed }"
            :placeholder="$t('network.secureWebGateway.allLists')"
            hide-details
            outlined
          >
            <template #item="{ item, props }">
              <v-list-item v-bind="props" title="">
                <span v-text="$t(`network.secureWebGateway.filters.${item.raw}`)" />
              </v-list-item>
            </template>
            <template #selection="{ item }">
              <span v-text="$t(`network.secureWebGateway.filters.${item.raw}`)" />
            </template>
          </v-select>

          <v-text-field
            v-model.trim="localFilters.search"
            data-testid="users-page-users-search"
            :class="{ 'filter-active': localFilters.search?.length }"
            dense
            outlined
            density="compact"
            :placeholder="$t('general.search')"
            class="search-field"
            prepend-inner-icon="icon-search"
            clearable
            clear-icon="icon-x"
            hide-details
          />
        </filter-wrapper>
        <v-menu>
          <template #activator="{ props }">
            <v-btn v-bind="props" class="add-btn" color="primary" rounded large>
              <v-icon icon="$add" color="white" />
              <span v-text="$t('general.add')" />
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="actionItem in actionsList"
              :key="actionItem.action"
              @click="onActionClick(actionItem)"
            >
              <span v-text="$t(`network.secureWebGateway.actions.${actionItem.action}`)" />
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <secure-web-gateway-table
      :items="urlList"
      :total-items="total"
      :pagination="pagination"
      :loading="loading"
      @item-action-applied="onActionClick($event)"
      @page-change="onPageChanged"
      @last-item-reached="onLastItemReached"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, watch } from "vue";
import { useFilters } from "@/composables/useFilters";
import { FilterContext } from "@/_store/filters.module";
import { useSWGStore } from "@/_store/secure-web-gateway.module";
import SecureWebGatewaySettings from "@/components/network/SecureWebGatewaySettings.vue";
import { storeToRefs } from "pinia";
import FilterWrapper from "@/components/FilterWrapper.vue";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionAddon, SubscriptionModule } from "@/constants/workplaces";
import { type DialogConfig, useDialogsStore } from "@/_store/dialogs.module";
import { ModalWidth } from "@/constants/modals";
import {
  SecureWebGatewayAction,
  SecureWebGatewayCategories,
  SecureWebGatewayListFilterChoices,
} from "@/constants/network";
import { useI18n } from "vue-i18n";
import SecureWebGatewayTable from "@/components/tables/SecureWebGateWayTable.vue";
import AllowBlockListSwgModal from "@/components/modals/network/AllowBlockListSwgModal.vue";
import ImportSwgUrlGroupModal from "@/components/modals/network/ImportSwgUrlGroupModal.vue";

const filterContext = FilterContext.SECURE_WEB_GATEWAY;

export default defineComponent({
  components: {
    SecureWebGatewayTable,
    FilterWrapper,
    SecureWebGatewaySettings,
  },
  setup() {
    const { t } = useI18n();

    const secureWebGatewayStore = useSWGStore();
    const dialogStore = useDialogsStore();
    const {
      getSwgSettings,
      addToSwgUrlList,
      getSwgUrlList,
      viewUrlList,
      removeFromSwgUrlList,
      updateSwgSettings,
      toggleDisabledSwgUrl,
    } = secureWebGatewayStore;
    const { openDialog } = dialogStore;
    const {
      urlList,
      urlListLoading: loading,
      pagination,
      total,
      settings: secureWebGatewaySettings,
      configurationLoading,
    } = storeToRefs(secureWebGatewayStore);

    const { clearFilters, showClearFiltersButton, localFilters, filtersUpdating } =
      useFilters(filterContext);

    const categories = Object.values(SecureWebGatewayCategories);
    const listFilterChoices = [
      SecureWebGatewayListFilterChoices.ALLOWED,
      SecureWebGatewayListFilterChoices.BLOCKED,
      SecureWebGatewayListFilterChoices.ALLOWED_DISABLED,
      SecureWebGatewayListFilterChoices.BLOCKED_DISABLED,
    ];
    const actionsList = [
      {
        action: SecureWebGatewayAction.ADD_URL_TO_ALLOWLIST,
        item: { allowed: true },
      },
      {
        action: SecureWebGatewayAction.ADD_URL_TO_BLOCKLIST,
        item: { allowed: false },
      },
      {
        action: SecureWebGatewayAction.IMPORT_URL_GROUP_TO_ALLOWLIST,
        item: {
          allowed: true,
        },
      },
      {
        action: SecureWebGatewayAction.IMPORT_URL_GROUP_TO_BLOCKLIST,
        item: {
          allowed: false,
        },
      },
    ];

    onMounted(async () => {
      await getSwgSettings();
      await getSwgUrlList();
    });

    watch(
      filtersUpdating,
      async (value) => {
        if (value) await getSwgUrlList();
      },
      { deep: true, immediate: true }
    );

    const showSettings = computed(() => Object.keys(secureWebGatewaySettings.value).length > 0);

    const onShowUrlList = async (item: {
      name?: string;
      id?: string;
      allowed: boolean;
      category?: string;
    }) => {
      try {
        if (item.category === SecureWebGatewayCategories.LOCKED_URL_GROUP) {
          const { data } = await viewUrlList(item.id!);
          if (window && data?.[0]) {
            const newWindow = window.open(data[0], "_blank");
            newWindow?.focus();
          }
        } else {
          await navigateToUrlList({ item });
        }
      } catch (e) {
        console.error(e);
      }
    };

    const onActionClick = async (payload: {
      item: {
        name?: string;
        id?: string;
        allowed: boolean;
        category?: string;
      };
      action: string;
    }) => {
      const { item, action } = payload;
      let modalConfig;
      switch (action) {
        case SecureWebGatewayAction.VIEW_URL_LIST:
          await onShowUrlList(item);
          return;
        case SecureWebGatewayAction.ENABLE_URL_GROUP_ALLOW_LIST:
        case SecureWebGatewayAction.ENABLE_URL_GROUP_BLOCK_LIST:
        case SecureWebGatewayAction.DISABLE_URL_GROUP_ALLOW_LIST:
        case SecureWebGatewayAction.DISABLE_URL_GROUP_BLOCK_LIST:
          modalConfig = {
            ...confirmationDialogsConfigConstructor({
              width: ModalWidth.SMALL,
              item,
              action,
              disable: isWorkspaceFrozenOrActionRestricted(
                RolePermissionsScope.PROTECTION,
                SubscriptionAddon.SWG
              ),
              callback: () => {
                toggleDisabledSwgUrl({
                  id: item.id!,
                  disabled: [
                    SecureWebGatewayAction.DISABLE_URL_GROUP_BLOCK_LIST,
                    SecureWebGatewayAction.DISABLE_URL_GROUP_ALLOW_LIST,
                  ].includes(action),
                });
              },
            }),
          };
          break;
        case SecureWebGatewayAction.REMOVE_FROM_ALLOWLIST:
        case SecureWebGatewayAction.REMOVE_FROM_BLOCKLIST:
        case SecureWebGatewayAction.REMOVE_URL_GROUP_FROM_ALLOW_LIST:
        case SecureWebGatewayAction.REMOVE_URL_GROUP_FROM_BLOCK_LIST:
          modalConfig = {
            ...confirmationDialogsConfigConstructor({
              width: ModalWidth.SMALL,
              item,
              action,
              disable: isWorkspaceFrozenOrActionRestricted(
                RolePermissionsScope.PROTECTION,
                SubscriptionModule.NETWORK
              ),
              callback: () => {
                removeFromSwgUrlList(item.id!);
              },
            }),
            content: {
              html: t(`modals.${action}.description`, {
                item: item.name!,
              }),
            },
          };
          break;
        case SecureWebGatewayAction.IMPORT_URL_GROUP_TO_ALLOWLIST:
        case SecureWebGatewayAction.IMPORT_URL_GROUP_TO_BLOCKLIST:
          modalConfig = {
            ...componentDialogsConfigConstructor({
              width: ModalWidth.LARGE,
              component: ImportSwgUrlGroupModal,
              item,
              action,
              callback: () => {},
            }),
            header: {
              title: t(
                `modals.importUrlGroupAllowBlockListFromCsv.${
                  payload.item.allowed ? "allow" : "block"
                }ListTitle`
              ),
            },
            footer: {
              buttons: null,
            },
          };
          break;
        case SecureWebGatewayAction.ADD_URL_TO_ALLOWLIST:
        case SecureWebGatewayAction.ADD_URL_TO_BLOCKLIST:
          modalConfig = {
            ...componentDialogsConfigConstructor({
              width: ModalWidth.LARGE,
              item,
              disable: isWorkspaceFrozenOrActionRestricted(
                RolePermissionsScope.PROTECTION,
                SubscriptionModule.NETWORK
              ),
              component: AllowBlockListSwgModal,
              callback: (payload: { names: []; allowed: boolean }) => {
                addToSwgUrlList(payload);
              },

              action: SecureWebGatewayAction.ADD_URL_TO_ALLOWLIST,
            }),
            header: {
              title: t(
                `modals.allowBlockListSwg.${
                  action === SecureWebGatewayAction.ADD_URL_TO_ALLOWLIST
                    ? "allowListTitle"
                    : "blockListTitle"
                }`
              ),
            },
          };
          break;
        default:
      }
      openDialog(modalConfig as DialogConfig);
    };

    const onPageChanged = async (pageObj: {
      page: number;
      itemsPerPage: number;
    }): Promise<void> => {
      secureWebGatewayStore.$patch({
        pagination: {
          page: pageObj.page - 1,
          pageSize: pageObj.itemsPerPage,
        },
      });
      await getSwgUrlList();
    };

    const navigateToUrlList = async ({
      item,
    }: {
      item: {
        name?: string;
        id?: string;
        allowed: boolean;
        category?: string;
      };
    }) => {
      const { data } = await viewUrlList(item.id!);
      const tab = window.open("about:blank", "_blank");
      const content = `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <title id="browser-tab-title">${item.name} URLs</title>
          </head>
          <body>
          ${data.map((value: string) => `${value}</br>`).join("")}
          </body>
          </html>
        `;
      tab!.document.write(content);
      tab!.document.close();
    };

    const clearFiltersCallback = async (): Promise<void> => {
      secureWebGatewayStore.$patch({
        pagination: {
          page: 0,
          pageSize: 25,
        },
      });
      await getSwgUrlList();
    };

    const onLastItemReached = async (): Promise<void> => {
      secureWebGatewayStore.$patch({
        pagination: { page: pagination.value.page, pageSize: 25 },
      });
      await getSwgUrlList(true);
    };

    return {
      onPageChanged,
      onLastItemReached,
      clearFiltersCallback,
      secureWebGatewaySettings,
      showSettings,
      configurationLoading,
      showClearFiltersButton,
      localFilters,
      categories,
      listFilterChoices,
      SecureWebGatewayAction,
      onActionClick,
      urlList,
      clearFilters,
      getSwgUrlList,
      total,
      updateSwgSettings,
      loading,
      pagination,
      actionsList,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .search-field {
    width: 230px;
  }

  .filter-active {
    .v-select__selections input {
      display: none !important;
    }
  }
}
</style>
