<template>
  <div class="error-modal-container d-flex flex-column align-center text-center">
    <v-icon size="70" class="mb-6" icon="$warning"></v-icon>
    <div class="headline6 text-center px-6 text-pre-wrap word-break-break-word">
      <template v-if="$te(`errors.${config.item}`)">{{ $t(`errors.${config.item}`) }}</template>
      <template v-else>{{ config.item }}</template>
    </div>
    <v-btn color="primary" class="mt-7" rounded size="large" @click="$emit('dismissed')">
      {{ $t("general.gotIt") }}
    </v-btn>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const i18n = useI18n();

    return {
      $te: i18n.te,
    };
  },
};
</script>

<style lang="scss" scoped>
.error-modal-container {
  min-height: 300px;
}
</style>
