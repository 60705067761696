<template>
  <div class="content-md margin-auto">
    <div>
      <div class="overview-widgets--background"></div>
      <v-skeleton-loader
        :key="`${backOfficeData.overviewWidgets.length < 7}`"
        :loading="backOfficeData.overviewWidgets.length < 7"
        class="overview-widgets"
        :type="coronetSkeletonLoaderTypes.OVERVIEW_WIDGETS"
      >
        <div class="overview-widgets bg-primary">
          <back-office-widget
            v-for="overviewWidget in backOfficeData.overviewWidgets"
            :key="overviewWidget.type"
            :item="overviewWidget"
            class="mb-5"
          />
        </div>
      </v-skeleton-loader>
    </div>
    <div class="dashboard-row">
      <div class="dashboard-row__widgets">
        <div class="d-flex align-center mb-5">
          <div v-if="!showSkeletonLoader" class="body2">
            {{
              `${$t("backOffice.beVersion")} ${backOfficeData.branchName}/${
                backOfficeData.commitHash
              }`
            }}
          </div>
          <filter-wrapper
            class="ml-auto"
            :show-clear-button="showClearFiltersButton"
            @clear-filters-clicked="clearFilters(getBackOfficeData)"
          >
            <div class="d-flex align-center"></div>
            <date-range-picker
              v-model="localFilters.timeRange"
              :preset-dates="presets"
              :class="{
                'filter-active': localFilters.timeRange.start && localFilters.timeRange.end,
              }"
              :placeholder="$t('reports.executiveSummaryReport.dateRange')"
              class="ml-auto date-range-picker"
            ></date-range-picker>
          </filter-wrapper>
        </div>
        <v-skeleton-loader :loading="showSkeletonLoader" :type="coronetSkeletonLoaderTypes.CARD">
          <template v-if="hasDevices">
            <div v-for="(item, index) in backOfficeData.widgets" :key="index" class="mb-6">
              <v-progress-linear v-if="loading" indeterminate height="2px" />
              <v-card>
                <v-card-title>
                  <span class="text-uppercase subtitle2 mr-1">
                    {{ $t(`backOffice.widgets.overviewWidget.types.${item.type}.title`) }}
                  </span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                  <v-table>
                    <template #default>
                      <thead>
                        <tr>
                          <th class="text-left caption">
                            {{ $t("backOffice.widgets.table.headers.coroVersion") }}
                          </th>
                          <th class="text-left caption">
                            {{ $t("backOffice.widgets.table.headers.count") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="device in item.devices"
                          :key="device.appVersion"
                          class="service-row"
                        >
                          <td>
                            {{ device.appVersion }}
                          </td>
                          <td>
                            {{ device.count }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-table>
                </v-card-text>
              </v-card>
            </div>
          </template>
          <div v-else class="no-data-container">
            <v-card>
              <v-card-text>
                <div class="d-flex flex-column align-center justify-center pa-7">
                  <v-icon size="68" class="mb-2" icon="$devices" />
                  <span class="subtitle1">{{ $t("general.noData") }}</span>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </v-skeleton-loader>
      </div>
      <div class="dashboard-row__alerts">
        <div
          v-if="showPermissionsLink"
          role="button"
          class="coro-link ml-10"
          @click="$router.push({ name: RouteName.BACKOFFICE_PERMISSIONS_PAGE })"
        >
          {{ $t("backOffice.manageAdminPermissions") }}&nbsp;›
        </div>
        <div
          v-if="
            !isGlobalActionRestricted(
              GlobalRoleScopes.GLOBAL_SCOPE,
              GlobalRoleScopeSection.SPECIAL_PERMISSIONS,
              'manageEndpointAutoUpdateGroups'
            )
          "
          role="button"
          class="coro-link ml-10"
          @click="$router.push({ name: RouteName.BACK_OFFICE_AGENT_GRADUAL_UPDATE })"
        >
          {{ $t("backOffice.agentsGradualUpdate") }}&nbsp;›
        </div>
        <!--         TODO: uncomment in future when needed again-->
        <!--        <div-->
        <!--          v-if="-->
        <!--            !isGlobalActionRestricted(-->
        <!--              GlobalRoleScopes.GLOBAL_SCOPE,-->
        <!--              GlobalRoleScopeSection.SPECIAL_PERMISSIONS,-->
        <!--              'manageWorkspaceCodes'-->
        <!--            )-->
        <!--          "-->
        <!--          role="button"-->
        <!--          class="coro-link ml-10"-->
        <!--          @click="$router.push({ name: RouteName.BACK_OFFICE_WORKSPACE_CODES })"-->
        <!--        >-->
        <!--          {{ $t("backOffice.manageWorkspaceCodes") }} ›-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, watch } from "vue";
import BackOfficeWidget from "@/components/backoffice/BackOfficeWidget.vue";
import FilterWrapper from "@/components/FilterWrapper.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import { useBackOfficeStore } from "@/_store/back-office/back-office.module";
import { storeToRefs } from "pinia";
import { useFilters } from "@/composables/useFilters";
import { FilterContext } from "@/_store/filters.module";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import moment from "moment";
import { isGlobalAccessRestricted, isGlobalActionRestricted } from "@/_helpers/global-permissions";
import { GlobalRoleScopes, GlobalRoleScopeSection } from "@/_store/roles.module";

import { RouteName } from "@/constants/routes";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    DateRangePicker,
    BackOfficeWidget,
    FilterWrapper,
  },
  setup() {
    const backOfficeStore = useBackOfficeStore();
    const i18n = useI18n();
    const { backOfficeData, loading, showSkeletonLoader, globalAdmins } =
      storeToRefs(backOfficeStore);

    const presets = computed(() => [
      {
        label: i18n.t("dateRangePicker.lastSevenDays"),
        value: [moment().subtract(7, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
      {
        label: i18n.t("dateRangePicker.lastThirtyDays"),
        value: [moment().subtract(30, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
      {
        label: i18n.t("dateRangePicker.lastNinetyDays"),
        value: [moment().subtract(90, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
      {
        label: i18n.t("dateRangePicker.last365Days"),
        value: [moment().subtract(365, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
    ]);

    const hasDevices = computed(() => backOfficeData.value.widgets.length > 0);
    const { localFilters, filtersUpdating, showClearFiltersButton, clearFilters } = useFilters(
      FilterContext.BACK_OFFICE
    );
    const showPermissionsLink = computed(() => {
      return (
        [GlobalRoleScopeSection.GLOBAL_ROLES, GlobalRoleScopeSection.GLOBAL_ADMIN_USERS] as const
      ).some((v) => !isGlobalAccessRestricted(GlobalRoleScopes.GLOBAL_SCOPE, v));
    });

    onMounted(async () => {
      await backOfficeStore.getBackOfficeData();
    });

    watch(
      filtersUpdating,
      async (value) => {
        if (value) await backOfficeStore.getBackOfficeData();
      },
      { deep: true, immediate: true }
    );

    return {
      presets,
      coronetSkeletonLoaderTypes,
      backOfficeData,
      loading,
      showSkeletonLoader,
      globalAdmins,
      hasDevices,
      localFilters,
      showClearFiltersButton,
      clearFilters,
      getBackOfficeData: backOfficeStore.getBackOfficeData,
      isGlobalActionRestricted,
      GlobalRoleScopes,
      GlobalRoleScopeSection,
      showPermissionsLink,
      RouteName,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .overview-widgets {
    width: 100%;
    display: grid;
    height: 100%;
    gap: 17px;
    grid-template-columns: repeat(auto-fill, minmax(217px, 1fr));
    line-height: 1.2;
    margin-top: 28px;
    .v-card {
      box-shadow: unset !important;
    }
    &--background {
      position: absolute;
      height: 550px;
      width: 200vw;
      top: 56px;
      left: 0;
      background-color: rgb(var(--v-theme-primary));
    }
  }
}

.dashboard-row {
  margin-top: 40px;
  display: flex;
  &__widgets {
    width: 75%;
  }
}

@media only screen and (max-width: 1212px) {
  .dashboard-row {
    &__widgets {
      width: 75%;
      margin-right: 60px;
    }
  }
}

@media only screen and (max-width: 950px) {
  .dashboard-row {
    margin-top: unset;
    flex-direction: column-reverse;
    &__widgets {
      margin-top: 20px;
      width: 100%;
      margin-right: unset;
    }
    &__alerts {
      width: 100%;
      margin-top: 20px;
    }
  }
  .overview-widgets--background {
    height: 550px;
  }
}

@media only screen and (max-width: 949px) {
  .overview-widgets--background {
    height: 810px;
  }
}

@media only screen and (max-width: 709px) {
  .overview-widgets--background {
    height: 1080px;
  }
}

.date-range-picker {
  width: 280px !important;
}
</style>
