<template>
  <div class="content-md margin-auto" v-resize="onResize" id="list-with-preview">
    <v-card>
      <v-row class="mx-0 my-0 mr-2">
        <v-col class="list-with-preview__list" :cols="listColumns">
          <table-wrapper class="list-with-preview">
            <slot name="list"></slot>
          </table-wrapper>
        </v-col>
        <v-col
          id="scroll-container"
          class="list-with-preview__preview-container"
          :cols="12 - listColumns"
        >
          <v-skeleton-loader
            :loading="showSkeletonLoader"
            type="list-item-three-line@15"
            class="skeleton-loader--table"
          >
            <slot name="preview"></slot>
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import TableWrapper from "@/components/TableWrapper.vue";
import { ref } from "vue";
export default {
  components: { TableWrapper },
  props: {
    showSkeletonLoader: {
      type: Boolean,
      default: false,
    },
    listColumns: {
      type: Number,
      default: 5,
    },
  },
  setup() {
    // approximately reasonable number in case of resize observer fails to work
    const height = ref("calc(100vh - 270px)");

    const onResize = () => {
      const windowHeight = window.innerHeight;
      const offsetTop = document.getElementById("list-with-preview").offsetTop;
      // 20 for at least some small padding at bottom to avoid double scroll
      const value = windowHeight - offsetTop - 20;
      height.value = `${value}px`;
    };

    return {
      onResize,
      height,
    };
  },
};
</script>

<style lang="scss" scoped>
.list-with-preview {
  &__preview-container {
    overflow: auto;
    padding: 0 4px 4px 4px;
    height: v-bind(height);
  }

  &__list {
    padding: 0;
    border-right: 1px solid rgb(var(--v-theme-indigo-faint));
    min-height: v-bind(height);
  }
}

:deep(*) {
  .list-item-grid {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      "icon info status"
      "icon info ."
      "icon info bottom";
    align-items: center;
    width: 100%;
    height: 100%;

    &__icon-block {
      grid-area: icon;
    }

    &__details-block {
      grid-area: info;
      word-break: break-word;
      overflow-wrap: break-word;
      white-space: normal;
    }

    &__status-block {
      grid-area: status;
    }

    &__bottom-block {
      grid-area: bottom;
    }
  }

  .details-preview-header {
    position: sticky;
    top: 0;
    background-color: rgb(var(--v-theme-white)) !important;
    border-bottom: 1px solid rgb(var(--v-theme-indigo-faint)) !important;
    z-index: 9999;
  }

  .list-with-preview__list {
    .v-table__wrapper {
      overflow: auto !important;
      height: v-bind(height);
    }

    table tbody tr {
      height: 88px !important;
    }

    table > thead {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: rgb(var(--v-theme-white));
    }
  }

  .v-table {
    box-shadow: unset !important;
  }

  .v-skeleton-loader {
    background-color: unset !important;
  }
}
</style>
