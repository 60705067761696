import pull from "lodash/pull";
import { WebsocketMessageCode } from "@/constants/websocket";
import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { TerminalState } from "@/_store/terminal.module.ts";
import { useMspDevicesStore } from "@/_store/msp/msp-devices.module.ts";
import { StoreModule } from "@/constants/store.ts";

const defaultTerminalState: TerminalState = {
  handlers: new Map(),
  sessionId: null,
  commandInProgress: false,
  supportedCommands: [],
  commandSequenceNumber: 1,
  sessionOpened: false,
  lastCommandId: null,
  lastCommandResponseType: null,
  lastCommandReceivedTime: null,
  failReason: null,
  websocketConnected: false,
};

export const useMspTerminalStore = defineStore(StoreModule.MSP_TERMINAL, {
  state: () => ({ ...defaultTerminalState }),
  getters: {
    isInInteractiveMode(state) {
      return (
        state.lastCommandResponseType === WebsocketMessageCode.SHELL_COMMAND_CONFIRMATION_REQUEST
      );
    },
  },
  actions: {
    setWebsocketConnected(payload: boolean) {
      this.websocketConnected = payload;
    },
    setSessionId(sessionId: number | string) {
      if (typeof sessionId === "string") {
        this.sessionId = parseInt(sessionId);
      } else {
        this.sessionId = sessionId;
      }
    },
    setCommandInProgress(commandInProgress: boolean) {
      this.commandInProgress = commandInProgress;
    },
    setCommandSequenceNumber(commandSequenceNumber: number) {
      this.commandSequenceNumber = commandSequenceNumber;
    },
    setSessionOpened(sessionOpened: boolean) {
      this.sessionOpened = sessionOpened;
    },
    setLastCommandId(commandId: number) {
      this.lastCommandId = commandId;
    },
    setFailReason(failReason: string) {
      this.failReason = failReason;
    },
    setLastCommandResponseType(lastCommandResponseType: WebsocketMessageCode) {
      this.lastCommandResponseType = lastCommandResponseType;
    },
    setLastCommandReceivedTime(lastCommandReceivedTime: number) {
      this.lastCommandReceivedTime = lastCommandReceivedTime;
    },
    resetState() {
      this.$reset();
    },
    addEventHandler({ type, handler }: { type: string; handler: Function }) {
      let handlers = this.handlers.get(type);

      if (!handlers) handlers = [handler];
      else handlers.push(handler);

      this.handlers.set(type, handlers);
    },
    removeEventHandler({ type, handler }: { type: string; handler: Function }) {
      const handlers = this.handlers.get(type);

      if (handlers) {
        pull(handlers, handler);
      }
    },
    emitEvent({ type, payload }: { type: string; payload: string }) {
      const handlers = this.handlers.get(type);

      if (handlers) {
        handlers.map((handler) => {
          handler(payload);
        });
      }
    },
    async getSupportedCommands(enrollmentCode: string) {
      const deviceStore = useMspDevicesStore();
      try {
        const request = {
          ...api.getMspTerminalSupportedCommands(
            enrollmentCode,
            deviceStore.deviceDetails.workspaceId
          ),
        };

        const { data } = await axiosInstance.request(request);
        this.supportedCommands = data;
      } catch (error) {
        console.error(error);
      }
    },
    async sendTerminalCommand({
      enrollmentCode,
      sshCommand,
      script,
    }: {
      enrollmentCode: string;
      sshCommand: string;
      script?: File;
    }) {
      const deviceStore = useMspDevicesStore();
      try {
        this.commandInProgress = true;

        const requestData = generateTerminalCommandFormData(
          {
            sessionId: this.sessionId!,
            sshCommand,
            commandSequenceNumber: this.commandSequenceNumber,
          },
          script
        );
        const request = {
          ...api.sendMspTerminalCommand(enrollmentCode, deviceStore.deviceDetails.workspaceId),
          data: requestData,
        };

        const { data } = await axiosInstance.request(request);
        this.commandSequenceNumber += 1;
        this.lastCommandId = data.commandId;
      } catch (error) {
        console.error(error);
        this.commandInProgress = false;
      }
    },
    async getLastCommandResults() {
      const deviceStore = useMspDevicesStore();
      const request = {
        ...api.getMspLastCommandResults(
          this.lastCommandId!,
          this.lastCommandReceivedTime,
          deviceStore.deviceDetails.workspaceId
        ),
      };

      return axiosInstance.request(request);
    },
    async getSessionStatus() {
      const deviceStore = useMspDevicesStore();
      if (this.sessionId) {
        const request = {
          ...api.getMspSessionStatus(this.sessionId, deviceStore.deviceDetails.workspaceId),
        };

        return axiosInstance.request(request).then(() => {
          this.sessionOpened = true;
        });
      }
    },
  },
});

function generateTerminalCommandFormData(
  {
    sshCommand,
    sessionId,
    commandSequenceNumber,
  }: { sshCommand: string; sessionId: number; commandSequenceNumber: number },
  script?: File
): FormData {
  const formData = new FormData();
  const data = new Blob([JSON.stringify({ sshCommand, sessionId, commandSequenceNumber })], {
    type: "application/json",
  });

  if (script) {
    const scriptBlob = new Blob([script], { type: "application/octet-stream" });
    formData.append("script", scriptBlob, script.name);
  }
  formData.append("command", data);
  return formData;
}
