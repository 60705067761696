<template>
  <div>
    <div class="subtitle1" data-testid="ticket-preview-title">
      {{ `${$t("ticketDetails.users")} - ${affectedUsersList.length}` }}
    </div>

    <div class="mb-6">
      <div data-testid="ticket-preview-users">
        <div v-for="user of getUsersList" :key="user">
          {{ user }}
        </div>
        <span class="coro-link" v-if="showViewAllButton"> {{ $t("general.viewAll") }}</span>
      </div>
    </div>
    <div class="subtitle1">{{ $t("ticketDetails.when") }}</div>
    <div class="body1 mt-2 mb-2" data-testid="ticket-preview-when">
      <div class="subtitle2">{{ $t("ticketDetails.from") }}</div>
      {{ getFormattedDateTime(ticketDetails.startTime, "lll") }}
    </div>
    <div class="body1 mb-6" data-testid="ticket-preview-endtime">
      <div class="subtitle2">{{ $t("ticketDetails.to") }}</div>
      {{ getFormattedDateTime(ticketDetails.endTime, "lll") }}
    </div>

    <div class="subtitle1">{{ $t("general.service") }}</div>
    <div class="d-flex align-center mb-4">
      <v-icon
        v-if="ticketDetails.sections.serviceName === Service.PROXY"
        size="40"
        class="mr-2"
        icon="$accessRestrictions"
      >
      </v-icon>
      <coro-icon v-else class="service-icon mr-2" :icon-name="ticketDetails.sections.serviceName" />
      <div
        class="subtitle2"
        :data-testid="`ticket-preview-service-${ticketDetails.sections.serviceName}`"
      >
        {{ serviceNameTitle }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import type { TicketDetails } from "@/_store/tickets/tickets.module";
import { useI18n } from "vue-i18n";
import { Service } from "@/constants/cloud-apps";
import { TicketsModal } from "@/constants/tickets";
import { ProxyDirection } from "@/constants/email-proxy";
import CoroIcon from "@/components/CoroIcon.vue";
import { getFormattedDateTime } from "@/_helpers/utils.ts";

export default defineComponent({
  components: { CoroIcon },
  props: {
    ticketDetails: {
      type: Object as PropType<TicketDetails>,
      required: true,
    },
  },
  setup(props) {
    const USERS_COUNT_TO_SHOW = 5;
    const i18n = useI18n();

    const affectedUsersList = computed(() => {
      return props.ticketDetails.sections.securityAwarenessMetaData?.affectedUsersList ?? [];
    });

    const showViewAllButton = computed(() => {
      return affectedUsersList.value.length > USERS_COUNT_TO_SHOW;
    });

    const getUsersList = computed(() => {
      return affectedUsersList.value.slice(0, USERS_COUNT_TO_SHOW);
    });

    const serviceNameTitle = computed(() => {
      const serviceName = i18n.t(`services.${props.ticketDetails.sections.serviceName}`);
      if (props.ticketDetails.sections.serviceName === Service.PROXY) {
        const direction =
          props.ticketDetails.sections.emailMetaData?.emailProxyDirection === ProxyDirection.INBOUND
            ? i18n.t("emailProxy.directions.inbound")
            : i18n.t("emailProxy.directions.outbound");

        return `${direction} ${serviceName}`;
      }
      return serviceName;
    });

    return {
      getFormattedDateTime,
      serviceNameTitle,
      TicketsModal,
      Service,
      showViewAllButton,
      getUsersList,
      affectedUsersList,
    };
  },
});
</script>

<style lang="scss" scoped>
.service-icon {
  width: 40px;
  height: 40px;
}
</style>
