<template>
  <div>
    <v-data-table
      class="tickets-table--list"
      data-testid="tickets-table"
      :class="{ 'tickets-table--list--soc': isSoc }"
      show-select
      return-object
      :loading="loading"
      :headers="headers"
      :items="items"
      v-model="selectedItems"
      :items-per-page="pagination.pageSize"
      :server-items-length="totalItems"
    >
      <!--Slot for table header-->
      <template #header.data-table-select>
        <v-checkbox
          v-if="!isSoc"
          :model-value="allItemsSelected"
          data-testid="table-select-all-checkbox"
          :indeterminate="isIndeterminate"
          class="ml-3"
          primary
          hide-details
          @update:modelValue="handleAllRowsSelection"
        ></v-checkbox>
      </template>
      <template #header.type>
        <div class="d-flex align-center justify-space-between">
          <list-table-header
            :pagination="pagination"
            :total-items="totalItems"
            :items="items"
            :show-pagination="showPagination"
            @pagination-change="handleListTablePaginationChange"
          >
            <template #prepend>
              <span class="table-selected-text" v-if="!!selectedItemsQuantity">
                {{ $t("devices.selected", { n: selectedItemsQuantity }) }}
              </span>
            </template>
            <template #append>
              <v-menu v-if="!!selectedItems.length" offset-y bottom right>
                <template #activator="{ props }">
                  <v-btn
                    data-testid="bulk-actions-button"
                    class="ml-8"
                    rounded
                    color="primary"
                    v-bind="props"
                  >
                    {{ $t("general.actions") }}
                    <v-icon class="ml-1 mt-1" size="10" icon="$triangle" />
                  </v-btn>
                </template>
                <v-list>
                  <template v-if="!availableBulkActions.length">
                    <v-list-item data-testid="users-table-no-actions-available-item">
                      <v-list-item-title>
                        {{ $t("general.noActionsAvailable") }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-for="action in availableBulkActions" :key="action">
                    <v-list-item
                      :data-testid="`tickets-table-bulk-action-${action}`"
                      :disabled="isActionDisabled(action)"
                      @click="onBulkActionClick(action)"
                    >
                      <v-list-item-title>
                        {{
                          $t(`ticketDetails.eventBulkActions.${action}`, {
                            n: selectedItemsQuantity,
                          })
                        }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
            </template>
          </list-table-header>
        </div>
      </template>

      <!--Slot for rows-->
      <template #item="{ item, isSelected, index, internalItem }">
        <tr
          :key="index"
          :class="{
            'coro-table-row--selected': isSelected([internalItem]),
            'coro-table-row--active': isRowActive(item[itemKey]),
          }"
          :data-testid="`table-row-${index}`"
          @click="handleRowClick(item)"
        >
          <td class="pl-2">
            <v-checkbox-btn
              :data-testid="`table-checkbox-row-${index}`"
              :model-value="isSelected([internalItem])"
              @update:modelValue="handleOneRowSelection($event, item)"
              @click.stop
            ></v-checkbox-btn>
          </td>
          <td class="tickets-table--list__item">
            <div class="list-item-grid">
              <div class="list-item-grid__icon-block">
                <v-icon class="tickets-table--list__icon" :icon="`$${getIconName(item)}`"></v-icon>
              </div>

              <div class="list-item-grid__details-block d-flex flex-column justify-center pr-2">
                <div
                  :data-testid="`tickets-table-type-col-row-${index}`"
                  class="tickets-table--list__name subtitle2"
                >
                  {{ $t(`tickets.eventTriggers.${camelCase(item.eventTrigger)}`) }}
                </div>
                <div class="body2" :data-testid="`tickets-table-users-row-${index}`">
                  <span v-if="shouldShowUsersNumber(item.eventType)">
                    {{ $t("general.nUsers", { n: item.usersAffected }) }}
                  </span>
                  <span v-else>
                    {{ getDisplayedUsers(item)[0] }}
                    <span v-if="getDisplayedUsers(item).length > 1">
                      {{ $t("eventsPage.more", { n: getDisplayedUsers(item).length - 1 }) }}
                    </span>
                  </span>
                </div>
                <div v-if="item.workspaceName" class="body2">
                  {{ item.workspaceName }}
                </div>
                <div v-if="item.processName" class="body2">
                  {{ item.processName }}
                </div>
                <div
                  class="caption text-indigo-medium"
                  :data-testid="`tickets-table-date-row-${index}`"
                >
                  {{ getFormattedDateTime(item.eventTime) }}
                </div>
              </div>

              <div class="list-item-grid__status-block">
                <ticket-list-status-icons
                  class="mt-4 justify-end"
                  :index="index"
                  :potentially-protectable-users="getPotentiallyProtectableUsers(item)"
                  :processed="item.processed"
                >
                  <template v-if="item.workspaceId && item.hasUnreadComment" #soc-comments-label>
                    <v-icon size="25" icon="$comments"></v-icon>
                  </template>
                </ticket-list-status-icons>
              </div>

              <div class="list-item-grid__bottom-block">
                <div class="d-flex align-center mb-4">
                  <users-say
                    :suspicious-vote-count="item.suspiciousVoteCount"
                    :safe-vote-count="item.safeVoteCount"
                  >
                    <template #soc-status-label>
                      <div
                        v-if="showProcessStatus(item)"
                        class="caption mt-1"
                        :class="{
                          'text-green-dark': item.processStatus === EdrProcessStatus.ALLOWED,
                          'text-red-dark': item.processStatus === EdrProcessStatus.BLOCKED,
                        }"
                      >
                        {{ $t(`general.${item.processStatus}`) }}
                      </div>

                      <soc-status-label
                        v-if="item.socStatus"
                        class="mt-2 ml-1"
                        :soc-status="item.socStatus"
                      />
                    </template>
                  </users-say>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>
      <template #bottom></template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType, toRefs } from "vue";
import {
  CommentAction,
  TicketTrigger,
  TicketType,
  TicketAction,
  TicketActionSeverity,
  TicketStatus,
  ticketTriggerIcons,
  ticketTypeIcons,
  ViolationDirection,
} from "@/constants/tickets";
import { EdrProcessStatus } from "@/constants/edr";
import { camelCase, union, uniq } from "lodash";
import intersection from "lodash/intersection";
import {
  arrayToLowerCase,
  componentDialogsConfigConstructor,
  getFormattedDateTime,
  isActionRestricted,
  isDeviceTicket,
  isWorkspaceFrozen,
} from "@/_helpers/utils";
import { useListTable } from "@/composables/useListTable";
import { usePageableTable } from "@/composables/usePageableTable";
import type { Pagination } from "@/types";
import { useSelectableTable } from "@/composables/useSelectableTable";
import { SubscriptionModule } from "@/constants/workplaces";
import {
  GlobalRoleScopes,
  GlobalRoleScopeSection,
  RolePermissionsScope,
  type TicketsScope,
} from "@/_store/roles.module";
import { useI18n } from "vue-i18n";
import type { ISelection } from "@/_store/selector.module";
import { useDialogsStore } from "@/_store/dialogs.module";
import BulkTicketCloseModal from "@/components/modals/BulkTicketCloseModal.vue";
import { ModalWidth } from "@/constants/modals";
import { isGlobalActionRestricted } from "@/_helpers/global-permissions";
import { type Ticket, useTicketsStore } from "@/_store/tickets/tickets.module";
import AddCommentModal from "@/components/modals/AddCommentModal.vue";
import type { TicketsFilters } from "@/_store/filters.module";
import UsersSay from "@/components/tickets/UsersSay.vue";
import SocStatusLabel from "@/components/tickets/SocStatusLabel.vue";
import TicketListStatusIcons from "@/components/TicketListStatusIcons.vue";
import ListTableHeader from "@/components/ListTableHeader.vue";
import { useTicketCommentsStore } from "@/_store/ticket-comments.module";

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Array<Ticket>>,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    selection: {
      type: Object as PropType<ISelection<Ticket>>,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      default: () => {
        return {
          page: 0,
          pageSize: 15,
        };
      },
    },
    loading: {
      type: Boolean,
      required: true,
    },
    ticketsFilters: {
      type: Object as PropType<TicketsFilters>,
      required: true,
    },
    isSoc: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ListTableHeader,
    TicketListStatusIcons,
    UsersSay,
    SocStatusLabel,
  },
  emits: ["page-changed", "update-preview", "update-page-count"],
  setup(props, { emit }) {
    const { pagination, items, totalItems } = toRefs(props);

    const i18n = useI18n();

    const { activeRowId, isRowActive, handleRowClick } = useListTable<Ticket>(
      { items, itemKey: "eventId" },
      (item) => {
        emit("update-preview", item);
      }
    );

    const dialogsStore = useDialogsStore();
    const ticketsStore = useTicketsStore();
    const ticketCommentsStore = useTicketCommentsStore();

    const shouldShowUsersNumber = (ticketType: TicketType) => {
      return [TicketType.SIMULATED_EMAIL_PHISHING, TicketType.SECURITY_TRAINING_FAILURE].includes(
        ticketType
      );
    };

    const { showPagination, page, handleListTablePaginationChange, totalPages } = usePageableTable(
      {
        items,
        pagination,
        totalItems,
      },
      (value: Pagination) => {
        emit("page-changed", value);
      }
    );

    const {
      selectedItems,
      handleOneRowSelection,
      handleAllRowsSelection,
      isIndeterminate,
      allItemsSelected,
      selectedItemsQuantity,
    } = useSelectableTable({
      totalItems,
      items,
    });

    const getDisplayedUsers = (ticket: Ticket) => {
      if (
        [TicketType.EMAIL_PHISHING, TicketType.MALWARE_IN_EMAIL_ATTACHMENTS].includes(
          ticket.eventType
        )
      ) {
        return ticket.to ? ticket.to : [];
      }
      if (ticket.lastLoggedInUsers?.length) {
        return ticket.lastLoggedInUsers ?? [];
      }
      const violationDirection = ticket.userDataAccessViolation?.violationDirection;
      if (violationDirection === ViolationDirection.ACCESS) {
        return union(ticket.userDataAccessViolation.users, ticket.potentiallyProtectableUsers);
      } else if (violationDirection === ViolationDirection.SHARE) {
        return ticket.userDataAccessViolation.users;
      }
      return ticket.from ? [ticket.from] : [];
    };
    const getIconName = ({ eventType, eventTrigger }: Ticket) => {
      return ticketTypeIcons[eventType] || ticketTriggerIcons[eventTrigger];
    };

    const showProcessStatus = (item: Ticket) => {
      return (
        item.processStatus === EdrProcessStatus.ALLOWED ||
        item.processStatus === EdrProcessStatus.BLOCKED
      );
    };

    const getPotentiallyProtectableUsers = (ticket: Ticket) => {
      if (
        ticket.eventType === TicketType.EMAIL_PHISHING &&
        ticket.potentiallyProtectableUsers?.length
      ) {
        return ticket.potentiallyProtectableUsers;
      }

      return intersection(
        arrayToLowerCase(ticket.potentiallyProtectableUsers ?? []),
        arrayToLowerCase(getDisplayedUsers(ticket))
      );
    };

    const isActionDisabled = (action: CommentAction | TicketAction) => {
      if (action === CommentAction.BULK_ADD_COMMENT) {
        const isSameWorkspace = props.selection.include.every(
          (v) => v?.workspaceId === props.selection.include[0]?.workspaceId
        );
        const isSameEventType = props.selection.include.every(
          (v) => v?.eventType === props.selection.include[0]?.eventType
        );
        return !isSameWorkspace || !isSameEventType;
      }
      return false;
    };

    const onBulkActionClick = async (action: TicketAction | CommentAction) => {
      const modules = props.selection.isAllPages
        ? [
            SubscriptionModule.EMAIL_SECURITY,
            SubscriptionModule.CLOUD_SECURITY,
            SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
            SubscriptionModule.USER_DATA_GOVERNANCE,
            SubscriptionModule.EDR,
            SubscriptionModule.ENDPOINT_SECURITY,
          ]
        : uniq(props.selection.include.map((ticket) => ticket.moduleName));
      const actionRestricted = (key: TicketActionSeverity) => {
        return modules.every((m) =>
          isActionRestricted(RolePermissionsScope.TICKETS, m as keyof TicketsScope, key)
        );
      };
      const disable = props.isSoc
        ? isGlobalActionRestricted(
            GlobalRoleScopes.GLOBAL_SCOPE,
            GlobalRoleScopeSection.SOC_PORTAL,
            TicketActionSeverity.LOW
          )
        : isWorkspaceFrozen() || actionRestricted(TicketActionSeverity.LOW);
      const disclaimer = props.isSoc && disable ? i18n.t("general.noPermissions") : undefined;
      switch (action) {
        case CommentAction.BULK_ADD_COMMENT:
          dialogsStore.openDialog(
            componentDialogsConfigConstructor({
              item: {
                isDeviceTicket: selectedItems.value.some((ticket) =>
                  isDeviceTicket(ticket.eventType)
                ),
                socModuleEnabled: true,
              },
              action,
              disclaimer,
              component: AddCommentModal,
              width: ModalWidth.LARGE,
              callback: ticketCommentsStore.bulkAddComment,
            })
          );
          break;
        case TicketAction.EXPORT_CSV:
          await ticketsStore.exportTicketsToCsv();
          break;
        case TicketAction.MARK_AS_PROCESSED:
          dialogsStore.openDialog(
            componentDialogsConfigConstructor({
              disclaimer,
              item: {
                callback: ticketsStore.applyTicketBulkAction,
                modules,
              },
              action,
              component: BulkTicketCloseModal,
              width: ModalWidth.MEDIUM,
              callback: () => {},
              hideFooter: true,
              disable,
            })
          );
          break;
        case TicketAction.MARK_AS_UNPROCESSED:
          dialogsStore.openDialog(
            componentDialogsConfigConstructor({
              disclaimer,
              item: {
                callback: ticketsStore.applyTicketBulkAction,
                modules,
              },
              action,
              component: BulkTicketCloseModal,
              width: ModalWidth.MEDIUM,
              callback: () => {},
              hideFooter: true,
              disable,
            })
          );
          break;
      }
    };

    const availableBulkActions = computed(() => {
      const closedTickets = props.selection.isAllPages
        ? []
        : uniq(props.selection.include.map((ticket) => ticket.processed));

      const baseActions = [
        TicketAction.MARK_AS_PROCESSED,
        TicketAction.MARK_AS_UNPROCESSED,
        ...(props.isSoc ? [CommentAction.BULK_ADD_COMMENT] : [TicketAction.EXPORT_CSV]),
      ];

      const filteredActions = baseActions.filter((action) => {
        if (action === TicketAction.MARK_AS_UNPROCESSED) {
          return closedTickets.includes(true); // Include if there are processed tickets
        }
        if (action === TicketAction.MARK_AS_PROCESSED) {
          return closedTickets.includes(false); // Include if there are unprocessed tickets
        }
        return true; // Return other actions (e.g., EXPORT_CSV or BULK_ADD_COMMENT)
      });

      if (props.ticketsFilters.status !== TicketStatus.ALL) {
        return [
          props.ticketsFilters.status === TicketStatus.OPEN
            ? TicketAction.MARK_AS_PROCESSED
            : TicketAction.MARK_AS_UNPROCESSED,
          ...(props.isSoc ? [CommentAction.BULK_ADD_COMMENT] : [TicketAction.EXPORT_CSV]),
        ];
      }

      // If specific tickets are selected (not all pages), apply filtered actions
      return props.selection.isAllPages ? baseActions : filteredActions;
    });

    const showTicketsPagePagination = computed(() => {
      return props.totalItems > props.pagination.pageSize;
    });
    return {
      headers: [
        {
          value: "type",
        },
      ],
      EventType: TicketType,
      EventTrigger: TicketTrigger,
      itemKey: "eventId" as const,
      EdrProcessStatus,
      camelCase,
      showPagination,
      page,
      onBulkActionClick,
      handleListTablePaginationChange,
      totalPages,
      showTicketsPagePagination,
      getFormattedDateTime,
      activeRowId,
      availableBulkActions,
      isRowActive,
      handleRowClick,
      getIconName,
      showProcessStatus,
      getPotentiallyProtectableUsers,
      isActionDisabled,
      getDisplayedUsers,
      selectedItems,
      handleOneRowSelection,
      handleAllRowsSelection,
      isIndeterminate,
      selectedItemsQuantity,
      shouldShowUsersNumber,
      allItemsSelected,
    };
  },
});
</script>

<style lang="scss">
.tickets-table--list {
  table tbody tr {
    cursor: pointer;
    height: 105px;

    &[row-disabled] {
      .icon-warning:before {
        color: #c90000;
      }
      .mdi-check-circle:before {
        color: #16883c;
      }
    }
  }

  &--soc {
    table tbody tr {
      height: 117px;
    }
  }

  .v-data-table__wrapper {
    box-shadow: none !important;
  }

  tbody {
    tr:not(:last-child) {
      td {
        border-bottom-color: rgb(var(--v-theme-indigo-pale)) !important;
      }
    }

    tr:first-child,
    tr:first-child:hover {
      td:first-child {
        border-top-left-radius: 0;
      }

      td:last-child {
        border-top-right-radius: 0;
      }
    }

    tr:last-child,
    tr:last-child:hover {
      td:first-child {
        border-bottom-left-radius: 0;
      }

      td:last-child {
        border-bottom-right-radius: 0;
      }
    }
  }

  &__icon {
    font-size: 40px;
    margin-right: 16px;
  }
}
</style>
