<template>
  <div class="mfa-page-wrapper margin-auto">
    <v-form ref="form" v-model="valid" class="pa-4" @submit.prevent>
      <div v-if="tooManyOtpResetRequests" class="error-block d-flex mb-4 mt-4 align-center w-100">
        <v-icon class="mr-3 ml-4" icon="$warning"></v-icon>
        <span class="body2 text-red-dark">
          {{ $t("validations.tooManyOtpResetRequests") }}
        </span>
      </div>
      <div>
        <div class="headline5 mb-4">{{ $t("mfa.createMFATitle") }}</div>
        <div
          class="body1 coronet-neutral-darken1--text mb-6"
          v-html="$t('mfa.createMFADesc', { code: mfaConfig.otpSecret })"
        />
        <div class="d-flex">
          <qrcode-vue class="margin-auto" :value="mfaConfig.uri" :size="170" />
        </div>
        <v-text-field
          v-model="appVerificationCode"
          class="mt-9"
          outlined
          :rules="verificationCodeRules"
          :placeholder="$t('mfa.enterCode')"
          @keydown.enter="verifyAuthAppCode()"
        />
        <v-btn
          color="primary"
          size="large"
          rounded
          :disabled="!valid"
          :loading="loading"
          @click="verifyAuthAppCode()"
        >
          {{ $t("general.confirm") }}
        </v-btn>
      </div>
    </v-form>
    <div
      class="caption text-center mt-8"
      data-size="invisible"
      data-badge="inline"
      v-html="$t('general.recaptcha')"
    />
  </div>
</template>

<script lang="ts">
import { ref, onMounted, defineComponent } from "vue";
import QrcodeVue from "qrcode.vue";
import { useMfaStore } from "@/_store/mfa.module";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import Patterns from "@/constants/patterns";
import { useMyAccountStore } from "@/_store/my-account.module";
import { useRouter } from "vue-router";
import { RouteName } from "@/constants/routes";
import { AccountErrors } from "@/constants/account";
import { useWorkspacesStore } from "@/_store/workspaces.module.ts";
import { useAccountStore } from "@/_store";

export default defineComponent({
  components: { QrcodeVue },
  setup() {
    const mfaStore = useMfaStore();
    const myAccountStore = useMyAccountStore();
    const workspaceStore = useWorkspacesStore();
    const accountStore = useAccountStore();
    const i18n = useI18n();
    const router = useRouter();
    const { mfaConfig, loading } = storeToRefs(mfaStore);
    const { account, logged } = accountStore;
    const { toggleMFAConfig, completeMFA } = mfaStore;
    const { getProfileData } = myAccountStore;
    const currentStep = ref(1);
    const valid = ref(true);
    const localPhoneNumber = ref(null);
    const appVerificationCode = ref("");
    const smsVerificationCode = ref("");
    const showTimer = ref(false);
    const countdown = ref(59);
    const isVerificationCodeIncorrect = ref(false);
    const isPhoneNumberInvalid = ref(false);
    const tooManyOtpResetRequests = ref(false);

    const verificationCodeRules = [
      (code: string) => {
        if (!code) return i18n.t("validations.required");
        if (!Patterns.ONLY_DIGITS.test(code)) return i18n.t("validations.onlyDigits");
        if (!Patterns.DIGITS_RANGE(6, 6).test(code)) return i18n.t("validations.nDigits", { n: 6 });
        if (isVerificationCodeIncorrect.value) {
          isVerificationCodeIncorrect.value = false;
          return i18n.t("validations.invalidVerificationCode");
        }
        return true;
      },
    ];

    const abortMFASetup = () => {
      appVerificationCode.value = "";
      localPhoneNumber.value = null;
      smsVerificationCode.value = "";
      currentStep.value = 1;
    };

    const verifyAuthAppCode = () => {
      if (!valid.value) return;
      completeMFA(appVerificationCode.value)
        .then(async () => {
          await getProfileData();
          if (account.workplace && logged) {
            await workspaceStore.getCurrentWorkspace();
          }
          await router.push({ name: RouteName.DASHBOARD });
        })
        .catch((err: any) => {
          if (err.response.data.errors?.includes(AccountErrors.INVALID_VERIFICATION_CODE)) {
            isVerificationCodeIncorrect.value = true;
          }
        });
    };

    const startCountdown = () => {
      showTimer.value = true;
      const interval = setInterval(() => {
        countdown.value--;
        if (countdown.value <= 0) {
          showTimer.value = false;
          countdown.value = 59;
          clearInterval(interval);
        }
      }, 1000);
    };

    onMounted(async () => {
      await toggleMFAConfig();
    });

    return {
      currentStep,
      valid,
      localPhoneNumber,
      appVerificationCode,
      smsVerificationCode,
      showTimer,
      countdown,
      isVerificationCodeIncorrect,
      isPhoneNumberInvalid,
      tooManyOtpResetRequests,
      mfaConfig,
      loading,
      verificationCodeRules,
      abortMFASetup,
      verifyAuthAppCode,
      startCountdown,
    };
  },
});
</script>

<style lang="scss" scoped>
.mfa-page-wrapper {
  max-width: 372px;
  margin-top: 88px;
}
</style>
