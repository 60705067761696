<template>
  <div class="d-flex align-center">
    <div
      :class="{ 'visibility-hidden': !showClearButton }"
      role="button"
      class="coro-link mr-2 clear-btn text-no-wrap"
      @click.stop="clearFilters()"
    >
      {{ $t("general.clearAll") }}
    </div>
    <div class="d-flex flex-grow-1 filter-wrapper__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showClearButton: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["clear-filters-clicked"],
  setup(props, { emit }) {
    const clearFilters = () => {
      emit("clear-filters-clicked");
    };

    return {
      clearFilters,
    };
  },
};
</script>

<style scoped lang="scss">
.clear-btn {
  z-index: 50;
}
:deep(*) {
  .v-field {
    height: 40px;
    line-height: 24px;
    border-radius: 20px;
    background-color: rgb(var(--v-theme-white));
    border: none;
  }

  .v-field__outline__start,
  .v-field__outline__end {
    border-color: rgb(var(--v-theme-indigo-pale)) !important;
  }

  .v-field__outline__notch {
    &:before,
    &:after {
      opacity: 1 !important;
      border-color: rgb(var(--v-theme-indigo-pale)) !important;
    }
  }

  .v-input__details {
    display: none;
  }

  .icon-triangle:before {
    color: rgb(var(--v-theme-primary)) !important;
    font-size: 8px;
  }

  input::placeholder {
    color: rgb(var(--v-theme-indigo-medium)) !important;
    opacity: 1 !important;
  }

  .v-select {
    input::placeholder {
      color: rgb(var(--v-theme-primary)) !important;
    }
  }

  .range-picker.filter-active {
    width: 240px !important;
  }

  .range-picker-with-time.filter-active {
    width: 340px !important;
  }

  .filter-active {
    .v-field-label {
      opacity: 1 !important;
    }
    .v-field__outline__start,
    .v-field__outline__end {
      border: 2px rgb(var(--v-theme-primary)) solid !important;
      opacity: 1 !important;
    }
    .v-field__outline__notch {
      &:before,
      &:after {
        border-color: rgb(var(--v-theme-primary)) !important;
        border-width: 0 0 2px;
        opacity: 1 !important;
      }
    }

    .v-field__outline__start {
      border-right: none !important;
    }

    .v-field__outline__end {
      border-left: none !important;
    }
  }

  .filter-label {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: rgb(var(--v-theme-primary));
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search-field {
    width: 300px;
  }
}
</style>
