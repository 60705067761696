import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { i18n } from "@/plugins/i18n";
import { SnackbarTypes, useSnackbarStore } from "@/_store/snackbar.module";
import type { AxiosRequestConfig } from "axios";
import { useFiltersStore } from "@/_store/filters.module";
import type { MfaStatus } from "@/constants/account";
import type { Service } from "@/constants/cloud-apps";
import { useAccountStore } from "@/_store/account.module";

export interface AdminUser extends NewAdminUser {
  id: string;
  mfaConfig: MfaStatus;
  status: string;
  inviteSentOn: number;
  role: {
    roleName: string;
    roleId: string;
  };
  monitoredScopes: {
    [serviceName in Service]: boolean;
  };
  emailProxyMonitoredScopes: {
    [domainName: string]: boolean;
  };
}

export interface NewAdminUser {
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
}

interface AdminAccountsState {
  adminUsers: Array<AdminUser>;
  showSkeletonLoader: boolean;
  loginAccessGranted: boolean;
  isTOTPRequired: boolean;
  loading: boolean;
  adminUserDetails: AdminUser;
}

const defaultAdminAccountsState: AdminAccountsState = {
  adminUsers: [],
  showSkeletonLoader: false,
  loginAccessGranted: false,
  isTOTPRequired: false,
  loading: false,
  adminUserDetails: {} as AdminUser,
};

export const useAdminUsersStore = defineStore("adminUsers", {
  state: (): AdminAccountsState => ({ ...defaultAdminAccountsState }),
  persist: false,
  actions: {
    async getAdminAccounts(showSkeletonLoader = false) {
      this.showSkeletonLoader = showSkeletonLoader;
      this.loading = true;
      const filters = useFiltersStore().filters.adminUsersFilters;
      try {
        const { data } = await axiosInstance.request({
          ...api.adminUsers,
          params: { ...filters },
        });
        this.adminUsers = data;
      } catch (e) {
        console.error(e);
      }
      this.showSkeletonLoader = false;
      this.loading = false;
    },
    async editAdminUser(data: DialogDataConfig<AdminUser>) {
      const request: AxiosRequestConfig<AdminUser> = {
        ...api.adminUser(data.item.id),
        method: "PUT",
        data: {
          ...data.item,
        },
      };
      try {
        await axiosInstance.request(request);
        await this.getAdminAccounts();
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.adminAccounts.adminUsersUpdated")
        );
      } catch (e) {
        console.error(e);
      }
    },
    async addAdminUser(data: DialogDataConfig<AdminUser>) {
      const { role, firstName, email, lastName } = data.item;
      const request: AxiosRequestConfig<NewAdminUser> = {
        ...api.adminUsers,
        method: "post",
        data: {
          firstName,
          lastName,
          email,
          roleId: role?.roleId,
        },
      };
      try {
        await axiosInstance.request(request);
        await this.getAdminAccounts();
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.adminAccounts.adminUsersUpdated")
        );
      } catch (e) {
        console.error(e);
      }
    },
    async deleteAdminUser(data: DialogDataConfig<AdminUser>) {
      const request = {
        ...api.adminUser(data.item.id),
        method: "delete",
      };
      try {
        await axiosInstance.request(request);
        await this.getAdminAccounts();
        const snackbarStore = useSnackbarStore();
        const snackbarMessage = {
          html: i18n.global.t("snackbar.messages.adminAccounts.adminUsersUpdated"),
          type: SnackbarTypes.SUCCESS,
        };
        snackbarStore.add(snackbarMessage);
      } catch (e) {
        console.error(e);
      }
    },
    async resendInvite(data: AdminUser) {
      const request = {
        ...api.resendInvite(data.id),
      };
      try {
        await axiosInstance.request(request);
        const snackbarStore = useSnackbarStore();
        const snackbarMessage = {
          html: i18n.global.t("snackbar.messages.adminAccounts.inviteSent"),
          type: SnackbarTypes.SUCCESS,
        };
        snackbarStore.add(snackbarMessage);
        await this.getAdminAccounts(false);
      } catch (e) {
        console.error(e);
      }
    },
    async getInviteLink(payload: AdminUser) {
      const request = {
        ...api.generateInviteLink(payload.id),
      };

      try {
        const res = await axiosInstance.request(request);
        return res.data.inviteLink;
      } catch (e) {
        console.error(e);
      }
    },
    async acceptInvite() {
      const accountStore = useAccountStore();
      const request = {
        ...api.acceptInvite,
      };
      try {
        const response = await axiosInstance.request(request);
        accountStore.$patch({
          account: {
            inviteAccepted: true,
          },
        });
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    getWorkspaceMFAConfig() {
      const request = {
        ...api.getWorkspaceMFAConfig,
      };
      return axiosInstance
        .request(request)
        .then((res) => {
          this.isTOTPRequired = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    toggleWorkspaceMFA() {
      const request = {
        ...api.toggleWorkspaceMFA,
      };
      return axiosInstance
        .request(request)
        .then(async (res) => {
          this.isTOTPRequired = res.data;
          await this.getAdminAccounts();
          const snackbarStore = useSnackbarStore();
          snackbarStore.add({
            html: i18n.global.t("snackbar.messages.adminAccounts.mfaUpdated"),
            type: SnackbarTypes.SUCCESS,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    deleteMFA(data: DialogDataConfig<AdminUser>) {
      const request = {
        ...api.deleteMFA(data.item.email),
      };
      this.showSkeletonLoader = true;
      return axiosInstance
        .request(request)
        .then(async () => {
          await this.getAdminAccounts();
          const snackbarStore = useSnackbarStore();
          snackbarStore.add({
            html: i18n.global.t("snackbar.messages.adminAccounts.mfaUpdated"),
            type: SnackbarTypes.SUCCESS,
          });
          this.showSkeletonLoader = false;
        })
        .catch((err) => {
          this.showSkeletonLoader = false;
          console.error(err);
        });
    },
    async getAdminUserDetails(adminUserId: string) {
      this.showSkeletonLoader = true;
      const request = {
        ...api.adminUser(adminUserId),
      };
      try {
        const { data } = await axiosInstance.request(request);
        this.adminUserDetails = data;
        this.showSkeletonLoader = false;
      } catch (e) {
        console.error(e);
      }
      this.showSkeletonLoader = false;
    },
    async updateContentInspectionPermissions(
      adminUserId: string,
      updatedPermissions: Pick<AdminUser, "monitoredScopes" & "emailProxyMonitoredScopes">
    ) {
      const request = {
        ...api.adminUserContentInspectionPermissions(adminUserId),
        method: "put",
        data: updatedPermissions,
      };
      try {
        await axiosInstance.request(request);
        await this.getAdminUserDetails(adminUserId);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t(`snackbar.messages.adminAccounts.permissionsUpdated`)
        );
      } catch (e) {
        console.error(e);
      }
    },
  },
});
