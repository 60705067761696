<template>
  <v-skeleton-loader
    class="skeleton-loader--security-settings"
    :loading="showSkeletonLoader"
    :type="coronetSkeletonLoaderTypes.SECURITY_SETTINGS"
  >
    <template v-if="localValue">
      <div v-if="showDisclaimer" class="info-block d-flex align-center mb-4">
        <v-icon class="ml-4 mr-3" icon="$info" size="28"> </v-icon>
        <span class="body2">{{ $t("emailSettings.settingsTab.disclaimer") }}</span>
      </div>
      <v-switch
        v-model="localValue.securityEnabled"
        :disabled="actionNotAllowed()"
        :ripple="false"
        class="mb-4"
        data-testid="real-time-security-switch"
        color="primary lighten-1"
        hide-details
        inset
      >
        <template #label>
          <div class="subtitle1">
            {{ $t("emailSettings.settingsTab.realTimeSecurity") }}
          </div>
        </template>
      </v-switch>
      <table-wrapper>
        <v-table class="white">
          <template #default>
            <thead>
              <tr>
                <th class="text-left caption">
                  {{ $t("emailSettings.settingsTab.threatType") }}
                </th>
                <th class="text-right caption pr-7">
                  {{ $t("emailSettings.settingsTab.securityMode") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="scanOption in scanOptions" :key="scanOption" class="service-row">
                <td class="content-row">
                  <div class="d-flex">
                    <v-checkbox
                      v-model="localValue[scanOption].enabled"
                      :data-testid="`${scanOption}-checkbox`"
                      :ripple="false"
                      :disabled="disabledSettings"
                    >
                      <template #label>
                        <div class="subtitle2">
                          {{ $t(`emailSettings.settingsTab.${scanOption}`) }}
                        </div>
                      </template>
                    </v-checkbox>
                  </div>
                </td>
                <td class="d-flex justify-end align-center">
                  <v-menu
                    v-if="
                      hasMultipleRemediationActionSupports(scanOption) &&
                      localValue[scanOption].enabled
                    "
                    min-width="auto"
                    max-width="250"
                    location="center"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        class="d-flex align-center remediation-btn"
                        :disabled="disabledSettings"
                        :ripple="false"
                        v-bind="props"
                        variant="text"
                      >
                        <span class="body2">
                          {{
                            $t(
                              `emailSettings.settingsTab.securityModes.${localValue[scanOption].action}`
                            )
                          }}
                        </span>
                        <v-icon size="10" icon="$triangle" class="ml-4 item-clickable" />
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="action in remediationActions"
                        :key="action"
                        @click="setRemediation(scanOption, action)"
                      >
                        <v-list-item-title>
                          <span class="body2">
                            {{ $t(`emailSettings.settingsTab.securityModes.${action}`) }}
                          </span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn
                    v-else-if="localValue[scanOption].enabled"
                    class="d-flex align-center remediation-btn not-clickable mr-6"
                    :disabled="disabledSettings"
                    :ripple="false"
                    variant="text"
                  >
                    <span class="body2">
                      {{ $t("emailSettings.settingsTab.securityModes.warnRecipient") }}
                    </span>
                  </v-btn>
                  <span
                    v-else
                    class="body2 text-indigo-medium mr-10"
                    :class="{ 'text-indigo-faint': disabledSettings }"
                  >
                    {{ $t("emailSettings.settingsTab.realTimeSecurityDisabled") }}
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
      </table-wrapper>
      <v-card class="mt-8">
        <v-card-text>
          <div class="sensitivity-level">
            <div class="subtitle1">{{ $t(`emailSettings.settingsTab.sensitivityLevel`) }}</div>
            <v-btn-toggle
              density="compact"
              class="btn-toggle ml-4 mb-1"
              v-model="localValue.scanSensitivity"
            >
              <v-btn
                v-for="sensitivityLevel in sensitivityLevels"
                class="toggle-btn"
                :disabled="disabledSettings"
                :value="sensitivityLevel.value"
                :key="sensitivityLevel.value"
              >
                {{ sensitivityLevel.display }}
              </v-btn>
            </v-btn-toggle>
          </div>
          <div class="body2 mb-3 mt-2">
            {{ $t(`emailSettings.settingsTab.sensitivityLevelDescription`) }}
          </div>
        </v-card-text>
      </v-card>
      <v-card class="mt-8">
        <v-card-text>
          <div class="subtitle1">
            {{ $t("emailSettings.settingsTab.quarantineByAttachmentType") }}
          </div>
          <v-checkbox
            v-model="localValue.quarantineFileTypesEnabled"
            data-testid="quarantine-emails-checkbox"
            :ripple="false"
            :disabled="disabledSettings"
          >
            <template #label>
              <div class="body2">
                {{ $t("emailSettings.settingsTab.quarantineEmails") }}
              </div>
            </template>
          </v-checkbox>
          <div class="subsettings ml-7 mt-2">
            <div class="mt-2">
              <template v-for="(_, index) of Math.ceil(fileTypesList.length / 6)">
                <v-row v-if="index <= 1 || showAll" :key="index" class="pl-4">
                  <v-col
                    v-for="fileType of fileTypesList.slice(index * 6, 6 + index * 6)"
                    :key="fileType"
                    cols="2"
                    class="pa-0"
                  >
                    <v-checkbox
                      v-model="localValue.quarantineFileTypes"
                      :value="fileType"
                      :ripple="false"
                      :disabled="disabledSettings"
                    >
                      <template #label>
                        <div class="body2">{{ fileType }}</div>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </template>
              <div class="d-flex align-center mb-4 mt-4" role="button" @click="showAll = !showAll">
                <span class="body2 coro-link">
                  {{
                    showAll
                      ? $t("emailSettings.settingsTab.seeLess")
                      : $t("emailSettings.settingsTab.seeAll")
                  }}
                </span>
                <v-icon :class="{ rotated: showAll }" icon="$chevronDown" />
              </div>
              <v-combobox
                v-model="localValue.quarantineCustomFileTypes"
                clearable
                clear-icon="$x"
                :label="$t('emailSettings.settingsTab.customFileTypes')"
                data-testid="file-types-combobox"
                variant="outlined"
                multiple
                :disabled="disabledSettings || !localValue.quarantineFileTypesEnabled"
                :placeholder="$t('emailSettings.settingsTab.customFileTypes')"
              >
                <template v-slot:chip="{ props, item }">
                  <v-chip
                    v-bind="props"
                    :closable="true"
                    :disabled="disabledSettings || !localValue.quarantineFileTypesEnabled"
                    variant="flat"
                    size="default"
                    color="indigo-faint"
                    close-icon="$closeCircle"
                    :text="item.title"
                  >
                    <div class="d-flex align-center">
                      <span class="ml-1 mr-2">{{ item.title }}</span>
                    </div>
                  </v-chip>
                </template>
                <template #item="data">
                  <v-list-item>
                    <span class="ml-1">{{ data.item }}</span>
                  </v-list-item>
                </template>
              </v-combobox>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <div v-if="localValue.exclusionSecuritySettingsDto" class="mt-8 subtitle1">
        {{ $t("emailSettings.settingsTab.scanBypass") }}
      </div>
      <v-card v-if="localValue.securityAwarenessConfigDto" class="mt-4">
        <v-card-text>
          <div class="subtitle1 mb-4">
            {{ $t("emailSettings.settingsTab.securityAwarenessTraining") }}
          </div>
          <v-checkbox
            v-model="localValue.securityAwarenessConfigDto.enabled"
            :ripple="false"
            :disabled="disabledSettings"
          >
            <template #label>
              <div class="body2">
                {{ $t("emailSettings.settingsTab.securityAwarenessTrainingSubtitle") }}
              </div>
            </template>
          </v-checkbox>

          <div class="d-flex justify-space-between align-center mt-6">
            <div>
              <span class="subtitle1">{{ $t("emailSettings.settingsTab.allowList") }}</span>
              <span
                class="subtitle1"
                v-if="localValue.securityAwarenessConfigDto.providerConfigurations.length > 0"
                >({{ localValue.securityAwarenessConfigDto.providerConfigurations.length }})</span
              >
            </div>

            <v-menu>
              <template #activator="{ props }">
                <v-btn
                  class="add-btn"
                  color="primary"
                  rounded
                  density="default"
                  size="large"
                  :disabled="disabledSettings || !localValue.securityAwarenessConfigDto.enabled"
                  v-bind="props"
                  @click="
                    onActionClick({
                      action: EmailSettingsAction.ADD_SECURITY_AWARENESS_CONFIGURATION,
                    })
                  "
                >
                  <v-icon class="mr-2" icon="$add" />
                  {{ $t("general.add") }}
                </v-btn>
              </template>
            </v-menu>
          </div>
          <email-security-security-awareness-table
            v-if="localValue.securityAwarenessConfigDto.providerConfigurations.length"
            :disabled="disabledSettings || !localValue.securityAwarenessConfigDto.enabled"
            :items="localValue.securityAwarenessConfigDto.providerConfigurations"
            @on-action-click="onActionClick($event)"
          />
        </v-card-text>
      </v-card>
      <v-card v-if="localValue.exclusionSecuritySettingsDto" class="mt-4">
        <v-card-text>
          <div class="subtitle1">{{ $t("emailSettings.settingsTab.subjectLine") }}</div>
          <v-checkbox
            class="mt-4"
            v-model="localValue.exclusionSecuritySettingsDto.emailSubjectKeywordsEnabled"
            :disabled="disabledSettings"
          >
            <template #label>
              <div class="body2">
                {{ $t("emailSettings.settingsTab.scanBypassLabel") }}
              </div>
            </template>
          </v-checkbox>
          <v-combobox
            v-model="localValue.exclusionSecuritySettingsDto.emailSubjectKeywords"
            clearable
            clear-icon="$x"
            class="mt-2 ml-7"
            variant="outlined"
            :placeholder="$t('emailSettings.settingsTab.emailSubjectKeywordsPlaceHolder')"
            multiple
            :disabled="
              disabledSettings ||
              !localValue.exclusionSecuritySettingsDto.emailSubjectKeywordsEnabled
            "
          >
            <template v-slot:chip="{ props, item }">
              <v-chip
                v-bind="props"
                :closable="true"
                :disabled="
                  disabledSettings ||
                  !localValue.exclusionSecuritySettingsDto.emailSubjectKeywordsEnabled
                "
                variant="flat"
                size="default"
                color="indigo-faint"
                close-icon="$closeCircle"
                :text="item.title"
              >
                <div class="d-flex align-center">
                  <span class="ml-1 mr-2">{{ item.title }}</span>
                </div>
              </v-chip>
            </template>
          </v-combobox>
        </v-card-text>
      </v-card>
      <div class="mt-8 subtitle1">
        {{ $t("emailSettings.settingsTab.apiBasedEmailSecurity") }}
      </div>
      <v-card class="mt-4">
        <v-card-text>
          <div class="d-flex align-center">
            <div
              :class="{
                'text-indigo-medium': disabledQuarantineFolder,
              }"
              class="subtitle1 mb-1"
            >
              {{ $t("emailSettings.settingsTab.quarantineFolder") }}
            </div>
            <v-tooltip v-if="disabledQuarantineFolder" open-delay="300" location="top">
              <template #activator="{ props }">
                <v-icon icon="$info" v-bind="props" />
              </template>
              <span>{{ $t("emailSettings.settingsTab.quarantineFolderInfoTooltip") }}</span>
            </v-tooltip>
          </div>
          <div class="body2 text-indigo-medium mb-3">
            {{ $t("emailSettings.settingsTab.quarantineFolderDescription") }}
          </div>
          <v-radio-group v-model="localValue.quarantineFolder">
            <v-radio
              v-for="folder in folders"
              :key="folder"
              :disabled="disabledQuarantineFolder"
              :value="folder"
            >
              <template #label>
                <div class="body2">
                  {{ $t(`emailSettings.settingsTab.quarantineFolderType.${folder}`) }}
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-card-text>
      </v-card>
    </template>
  </v-skeleton-loader>
</template>

<script lang="ts">
import type { Ref } from "vue";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import type {
  EmailSecuritySettings,
  ProviderConfiguration,
} from "@/_store/email-security/email-settings.module";
import { useEmailSettingsStore } from "@/_store/email-security/email-settings.module";
import { storeToRefs } from "pinia";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { useCloudAppsStore } from "@/_store/cloud-apps/cloud-apps.module";
import {
  checkEmailServiceConnection,
  clearFileTypes,
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionModule } from "@/constants/workplaces";
import { useEmailProxyStore } from "@/_store/email-security/email-proxy.module";
import {
  AttachmentFileExtension,
  EmailSettingsAction,
  QuarantineFolder,
  ScanOptions,
  SecurityMode,
  ScanSensitivityLevels,
} from "@/constants/emails";
import { debounce } from "lodash";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import TableWrapper from "@/components/TableWrapper.vue";
import { ProxyDirection } from "@/constants/email-proxy";
import { useDialogsStore } from "@/_store/dialogs.module";
import { ModalWidth } from "@/constants/modals";
import AddSecurityAwarnessConfiguration from "@/components/modals/email-settings/AddSecurityAwarnessConfiguration.vue";
import EmailSecuritySecurityAwarenessTable from "@/components/tables/EmailSecuritySecurityAwarenessTable.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { EmailSecuritySecurityAwarenessTable, TableWrapper },
  setup() {
    const emailSettingsStore = useEmailSettingsStore();
    const emailProxyStore = useEmailProxyStore();
    const cloudAppsStore = useCloudAppsStore();
    const { services } = storeToRefs(cloudAppsStore);
    const localValue: Ref<EmailSecuritySettings | null> = ref(null);
    const { t } = useI18n();
    const showAll = ref(false);
    const fileTypesList = ref([
      AttachmentFileExtension.SEVEN_ZIP,
      AttachmentFileExtension.BAT,
      AttachmentFileExtension.BIN,
      AttachmentFileExtension.CHM,
      AttachmentFileExtension.CMD,
      AttachmentFileExtension.COM,
      AttachmentFileExtension.CPL,
      AttachmentFileExtension.DLL,
      AttachmentFileExtension.DMG,
      AttachmentFileExtension.EXE,
      AttachmentFileExtension.GADGET,
      AttachmentFileExtension.G7,
      AttachmentFileExtension.GZ,
      AttachmentFileExtension.HTA,
      AttachmentFileExtension.HTM,
      AttachmentFileExtension.HTML,
      AttachmentFileExtension.INF,
      AttachmentFileExtension.ISO,
      AttachmentFileExtension.JAR,
      AttachmentFileExtension.JS,
      AttachmentFileExtension.JSE,
      AttachmentFileExtension.INK,
      AttachmentFileExtension.LNK,
      AttachmentFileExtension.MHT,
      AttachmentFileExtension.MSC,
      AttachmentFileExtension.MSH,
      AttachmentFileExtension.MSH1,
      AttachmentFileExtension.MSH1XML,
      AttachmentFileExtension.MSH2,
      AttachmentFileExtension.MSH2XML,
      AttachmentFileExtension.MSHL,
      AttachmentFileExtension.MSHXML,
      AttachmentFileExtension.MSI,
      AttachmentFileExtension.MSP,
      AttachmentFileExtension.PIF,
      AttachmentFileExtension.PS,
      AttachmentFileExtension.PS1,
      AttachmentFileExtension.PS1XML,
      AttachmentFileExtension.PS2,
      AttachmentFileExtension.PS2XML,
      AttachmentFileExtension.PSC1,
      AttachmentFileExtension.PSC2,
      AttachmentFileExtension.PSCL,
      AttachmentFileExtension.PSL,
      AttachmentFileExtension.PY,
      AttachmentFileExtension.RAR,
      AttachmentFileExtension.REG,
      AttachmentFileExtension.SCF,
      AttachmentFileExtension.SCR,
      AttachmentFileExtension.SH,
      AttachmentFileExtension.TAR,
      AttachmentFileExtension.VB,
      AttachmentFileExtension.VBE,
      AttachmentFileExtension.VBS,
      AttachmentFileExtension.ZIP,
      AttachmentFileExtension.WAS,
      AttachmentFileExtension.WS,
      AttachmentFileExtension.WSC,
      AttachmentFileExtension.WSF,
      AttachmentFileExtension.WSH,
    ]);
    const scanOptions = ref([
      ScanOptions.SCAN_MALWARE,
      ScanOptions.SCAN_SPAM,
      ScanOptions.SCAN_PHISHING,
      ScanOptions.SCAN_NEWLY_REGISTERED_DOMAINS,
      ScanOptions.SCAN_ENCRYPTED_ATTACHMENTS,
      ScanOptions.SCAN_UNVERIFIED_SENDER,
      ScanOptions.SCAN_UNKNOWN_EXTERNAL_SENDER,
    ]);

    const sensitivityLevels = [
      {
        value: ScanSensitivityLevels.LOW,
        display: t(`emailSettings.settingsTab.sensitivityLevels.${ScanSensitivityLevels.LOW}`),
      },
      {
        value: ScanSensitivityLevels.MEDIUM,
        display: t(`emailSettings.settingsTab.sensitivityLevels.${ScanSensitivityLevels.MEDIUM}`),
      },
      {
        value: ScanSensitivityLevels.HIGH,
        display: t(`emailSettings.settingsTab.sensitivityLevels.${ScanSensitivityLevels.HIGH}`),
      },
    ];
    const { openDialog } = useDialogsStore();
    const { isProxyActive } = storeToRefs(emailProxyStore);
    const { showSkeletonLoader, settings } = storeToRefs(emailSettingsStore);
    const remediationActions = [SecurityMode.QUARANTINE_EMAIL, SecurityMode.WARN_RECIPIENT];
    const actionNotAllowed = () => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionModule.EMAIL_SECURITY
      );
    };

    const isEmailServiceConnected = computed(() => {
      return checkEmailServiceConnection(services.value);
    });
    const disabledSettings = computed(() => {
      return !localValue.value!.securityEnabled || actionNotAllowed();
    });
    const disabledQuarantineFolder = computed(() => {
      return actionNotAllowed() || (!isEmailServiceConnected.value && isProxyActive.value);
    });
    const showDisclaimer = computed(() => {
      return !isEmailServiceConnected.value && !isProxyActive.value;
    });
    const hasMultipleRemediationActionSupports = (key: ScanOptions) => {
      return [ScanOptions.SCAN_MALWARE, ScanOptions.SCAN_SPAM, ScanOptions.SCAN_PHISHING].includes(
        key
      );
    };

    const onActionClick = ({
      action,
      item,
      index,
    }: {
      item?: ProviderConfiguration;
      index?: number;
      action: EmailSettingsAction;
    }) => {
      switch (action) {
        case EmailSettingsAction.ADD_SECURITY_AWARENESS_CONFIGURATION:
          openDialog({
            ...componentDialogsConfigConstructor({
              callback: (val) =>
                localValue.value!.securityAwarenessConfigDto.providerConfigurations.push(val),
              width: ModalWidth.MEDIUM,
              component: AddSecurityAwarnessConfiguration,
              action,
            }),
          });
          break;
        case EmailSettingsAction.EDIT_SECURITY_AWARENESS_CONFIGURATION:
          openDialog({
            ...componentDialogsConfigConstructor({
              callback: (val) =>
                (localValue.value!.securityAwarenessConfigDto.providerConfigurations[index!] = {
                  ...val,
                }),
              width: ModalWidth.MEDIUM,
              item,
              component: AddSecurityAwarnessConfiguration,
              action,
            }),
          });
          break;
        case EmailSettingsAction.REMOVE_SECURITY_AWARENESS_CONFIGURATION:
          openDialog({
            ...confirmationDialogsConfigConstructor({
              item: {},
              action: EmailSettingsAction.REMOVE_SECURITY_AWARENESS_CONFIGURATION,
              callback: () => {
                if (localValue.value) {
                  localValue.value.securityAwarenessConfigDto.providerConfigurations.splice(
                    index!,
                    1
                  );
                }
              },
            }),
            header: {
              title: t(
                `modals.${EmailSettingsAction.REMOVE_SECURITY_AWARENESS_CONFIGURATION}.title`
              ),
              titleClass: "text-red-dark",
              titleIcon: "warning",
            },
          });
          break;
      }
    };

    const resetQuarantineFileTypes = async () => {
      if (!disabledSettings.value) {
        await emailSettingsStore.resetEmailFileTypes();
      }
    };

    const setRemediation = (key: string, action: SecurityMode) => {
      // This is temporary switch, ability to set remediation is expected for all settings in this block
      switch (key) {
        case ScanOptions.SCAN_MALWARE:
          localValue.value!.scanMalware.action = action;
          break;
        case ScanOptions.SCAN_SPAM:
          localValue.value!.scanSpam.action = action;
          break;
        case ScanOptions.SCAN_PHISHING:
          localValue.value!.scanPhishing.action = action;
          break;
      }
    };

    watch(
      localValue,
      debounce(async (newVal, oldVal) => {
        if (!isEmpty(newVal) && !isEmpty(oldVal)) {
          await emailSettingsStore.updateEmailsSettings({
            ...newVal,
            quarantineCustomFileTypes: clearFileTypes(newVal.quarantineCustomFileTypes),
          });
        }
      }, 500),
      { deep: true }
    );

    onMounted(async () => {
      await emailSettingsStore.getEmailsSettings();
      await cloudAppsStore.getServices();
      await emailProxyStore.getEmailProxyStatus({
        direction: ProxyDirection.INBOUND,
      });

      localValue.value = cloneDeep(settings.value);
    });

    return {
      sensitivityLevels,
      onActionClick,
      showSkeletonLoader,
      coronetSkeletonLoaderTypes,
      disabledSettings,
      isEmailServiceConnected,
      disabledQuarantineFolder,
      showDisclaimer,
      remediationActions,
      resetQuarantineFileTypes,
      actionNotAllowed,
      folders: [QuarantineFolder.SUSPECTED, QuarantineFolder.TRASH],
      localValue,
      showAll,
      setRemediation,
      fileTypesList,
      scanOptions,
      ScanOptions,
      hasMultipleRemediationActionSupports,
      EmailSettingsAction,
    };
  },
});
</script>

<style scoped lang="scss">
.chip-item {
  height: 36px;
  border-radius: 18px;
}

.feedback-container {
  border: 1px solid rgb(var(--v-theme-neutral-lighten-4));
}

.service-icon {
  height: 18px;
  width: 18px;
}

:deep(*) {
  .icon-chevron:before {
    color: rgb(var(--v-theme-link)) !important;
  }

  .v-field {
    .v-chip {
      --v-chip-height: 32px;
    }

    .v-field__input {
      row-gap: 12px;
    }
  }

  label {
    opacity: 1;
  }
  .sensitivity-level {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
    .btn-toggle {
      background-color: rgb(var(--v-theme-indigo-pale));
      padding: 5px;

      .v-btn--active {
        background-color: white !important;

        .v-btn__overlay {
          background-color: white !important;
          color: rgb(var(--v-theme-primary)) !important;
        }
      }

      .toggle-btn {
        background-color: transparent;

        .v-btn__content {
          text-transform: capitalize;
          letter-spacing: normal;
          font-size: 16px !important;
          font-weight: normal !important;
        }
      }
    }
  }
  .wifi-phishing-checkbox label {
    font-weight: bold;
  }

  .v-input--radio-group legend {
    margin-left: 0 !important;
  }

  .v-icon.icon-info:before {
    cursor: default;
    color: rgb(var(--v-theme-primary)) !important;
  }

  .remediation-btn {
    text-transform: initial !important;
  }
}

:deep(.v-switch) {
  .v-selection-control {
    min-height: 24px !important;
  }
}
</style>
