<template>
  <div class="mt-4 position-relative">
    <v-card class="back-to-ticket-wrapper position-fixed" @click="goToTicket()">
      <span class="coro-link">{{ `‹ ${$t("processGraph.backToTicket")} (${eventId})` }}</span>
    </v-card>
    <v-card
      v-if="lastSelectedNodes.length && !firstLoading"
      class="process-details-wrapper position-fixed mr-4 mt-0"
      :loading="isDetailsLoading"
    >
      <v-card-title class="d-flex justify-space-between align-center pa-6">
        <span class="headline6">{{ details.processName }}</span>
      </v-card-title>
      <v-card-text class="pa-6">
        <template v-if="details.lastStatusModifiedTime">
          <div :style="getStatusColorStyles(details.status)" class="body2 mb-5">
            {{
              $t("processGraph.processStatus", {
                status: $t(`general.${details.status}`),
                date: getFormattedDateTime(details.lastStatusModifiedTime, "MMM, DD YYYY, h:mm A"),
              })
            }}
          </div>
        </template>
        <template v-if="isAnchorProcess(details.processId)">
          <div class="subtitle1">{{ $t("processGraph.relatedTicket") }}</div>
          <div class="coro-link body1 mb-5" role="button" @click="goToTicket()">{{ eventId }}</div>
        </template>
        <template v-if="details.pid">
          <div class="subtitle1">{{ $t("processGraph.pid") }}</div>
          <div class="body1 mb-5">{{ details.pid }}</div>
        </template>
        <template v-if="details.userName">
          <div class="subtitle1">{{ $t("processGraph.relatedUser") }}</div>
          <div class="body1 mb-5">{{ details.userName }}</div>
        </template>
        <template v-if="details.executionTime">
          <div class="subtitle1">{{ $t("processGraph.executionTime") }}</div>
          <div class="body1 mb-5">
            {{ getFormattedDateTime(details.executionTime, "MMM, DD YYYY, h:mm A") }}
          </div>
        </template>
        <template v-if="details.commandLineArguments">
          <div class="subtitle1">{{ $t("processGraph.commandLineArguments") }}</div>
          <ellipsified-copy-text
            class="body1 mb-5 word-break-break-word"
            :text="details.commandLineArguments"
            :max-characters-length="150"
            show-tooltip
          />
        </template>
        <div class="mb-5" v-if="details.persistence?.length">
          <div class="subtitle1">{{ $t("processGraph.persistence") }}</div>
          <v-tooltip open-delay="300" location="top">
            <template #activator="{ props }">
              <div v-bind="props">
                <ellipsified-copy-text
                  v-for="(persistenceItem, index) in details.persistence.slice(0, 2)"
                  :key="index"
                  class="body1 mb-5 word-break-break-word"
                  :text="persistenceItem"
                  :max-characters-length="150"
                />
              </div>
            </template>
            <span v-html="details.persistenceStringified" />
          </v-tooltip>
        </div>
        <v-divider class="mb-5"></v-divider>
        <div class="subtitle1">{{ $t("processGraph.fileInformation") }}</div>
        <template v-if="details.imageFileLocation">
          <div class="subtitle1 mt-5">{{ $t("processGraph.imageFileInformation") }}</div>
          <ellipsified-copy-text class="body1" :text="details.imageFileLocation" />
        </template>
        <div class="subtitle1 mt-5">{{ $t("processGraph.shaHash") }}</div>
        <ellipsified-copy-text class="body1" :text="details.hash" />
        <!--        @TODO Uncomment when BE will be ready -->
        <!--        <div class="subtitle1 mt-5">{{ $t("processGraph.fileSize") }}</div>-->
        <!--        <div class="body1">{{ humanizeFileSize(details.fileSize) }}</div>-->
        <!--        <div class="subtitle1 mt-5">{{ $t("processGraph.fileCreationTime") }}</div>-->
        <!--        <div class="body1">{{ getFormattedDateTime(details.fileCreationTime, "MMM, DD YYYY, h:mm A") }}</div>-->
        <!--        <div class="subtitle1 mt-5">{{ $t("processGraph.lastModifiedTime") }}</div>-->
        <!--        <div class="body1">{{ getFormattedDateTime(details.lastModifiedTime, "MMM, DD YYYY, h:mm A") }}</div>-->
        <!--        <div class="subtitle1 mt-5">{{ $t("processGraph.signatureStatus") }}</div>-->
        <!--        <div class="body1">{{ details.signatureStatus }}</div>-->
        <!--        <div class="subtitle1 mt-5">{{ $t("processGraph.verificationStatus") }}</div>-->
        <!--        <div class="body1">{{ details.verificationStatus }}</div>-->
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer />
        <v-menu>
          <template #activator="{ props }">
            <v-btn
              rounded
              color="primary"
              size="large"
              variant="flat"
              density="default"
              v-bind="props"
            >
              {{ $t("general.actions") }}
              <v-icon class="ml-1 mt-1" size="10" icon="$triangle" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="action in details.actions" :key="action" @click="openModal(action)">
              <v-list-item-title>
                {{ $t(`edr.actions.${action}`) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>
    </v-card>
    <v-network-graph
      v-if="!isProcessGraphLoading"
      v-model:zoom-level="zoomLevel"
      ref="graph"
      class="process-graph"
      v-model:selected-nodes="selectedNodes"
      :nodes="nodes"
      :edges="edges"
      :layouts="layouts"
      :configs="configs"
      :event-handlers="eventHandlers"
    >
      <template #override-node-label="{ nodeId, scale, text }">
        <svg
          :width="40 * scale"
          :height="40 * scale"
          :x="-120 * scale"
          :y="-20 * scale"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="svg-wrapper"
            d="m6.81 7.413.062.1 3.694 6.687 1.763-4.348a1 1 0 0 1 1.703-.255l.073.103 4.285 6.891a3.987 3.987 0 0 1 4.038 6.714l3.307 5.317 1.812-4.47a1 1 0 0 1 1.736-.213l.066.105 4.708 8.525a1 1 0 0 1-1.688 1.065l-.062-.099-3.695-6.689-1.762 4.35a1 1 0 0 1-1.703.254l-.073-.103-4.43-7.124a3.987 3.987 0 0 1-3.897-6.488l-3.304-5.31-1.811 4.471a1 1 0 0 1-1.736.212l-.066-.105-4.708-8.525A1 1 0 0 1 6.81 7.413zm.674 11.92a1 1 0 0 1 1 1c0 6.233 5.053 11.286 11.287 11.286a1 1 0 0 1 0 2c-7.338 0-13.287-5.949-13.287-13.287a1 1 0 0 1 1-1v.001zm12.287-.988a1.988 1.988 0 1 0 .002 3.976 1.988 1.988 0 0 0-.002-3.976zm0-11.3c7.339 0 13.288 5.95 13.288 13.287a1 1 0 0 1-2 0c0-6.234-5.054-11.287-11.287-11.287a1 1 0 1 1 0-2h-.001z"
            style="fill: var(--v-theme-primary)"
          />
        </svg>
        <text
          class="svg-wrapper"
          x="0"
          :y="nodes[nodeId].anchorProcess ? -10 * scale : 0"
          :font-size="18 * scale"
          font-weight="600"
          text-anchor="middle"
          dominant-baseline="central"
          style="fill: var(--v-theme-primary)"
        >
          {{ text.length <= labelMaxLength ? text : text.slice(0, labelMaxLength - 3) + "..." }}
        </text>
        <text
          v-if="nodes[nodeId].anchorProcess"
          class="svg-wrapper"
          x="0"
          :y="10 * scale"
          :font-size="16 * scale"
          font-weight="400"
          text-anchor="middle"
          dominant-baseline="central"
          style="fill: var(--v-theme-indigo-medium)"
        >
          {{ $t("processGraph.anchorProcess") }}
        </text>
        <template v-if="nodes[nodeId].childProcessesCount">
          <rect
            class="svg-wrapper"
            :x="90 * scale"
            :y="-14 * scale"
            :width="33 * scale"
            :height="29 * scale"
            style="fill: var(--v-theme-indigo-pale)"
            rx="50"
          />
          <text
            class="svg-wrapper"
            :x="105 * scale"
            :y="0"
            :font-size="16 * scale"
            font-weight="600"
            text-anchor="middle"
            dominant-baseline="central"
            style="fill: var(--v-theme-indigo-medium)"
          >
            {{
              nodes[nodeId].childProcessesCount > 99
                ? "+99"
                : `+${nodes[nodeId].childProcessesCount}`
            }}
          </text>
        </template>
      </template>
    </v-network-graph>
    <v-progress-circular
      v-if="isProcessGraphLoading || isDetailsLoading"
      class="ma mt-4 process-graph-spinner"
      color="primary"
      indeterminate
      size="72"
    />
    <div ref="tooltip" class="tooltip" :style="{ ...tooltipPos, opacity: tooltipOpacity }">
      <div>{{ nodes[targetNodeId]?.name }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { VNetworkGraph, defineConfigs } from "v-network-graph";
import type { Nodes, Edges, Layouts, VNetworkGraphInstance, EventHandlers } from "v-network-graph";
import { computed, onUnmounted, reactive, watch } from "vue";
//@ts-ignore
import dagre from "dagre/dist/dagre.min.js";
import EllipsifiedCopyText from "@/components/EllipsifiedCopyText.vue";
import { onMounted, ref } from "vue";
import {
  confirmationDialogsConfigConstructor,
  getFormattedDateTime,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { type ProcessGraphNode, useProcessGraphStore } from "@/_store/process-graph.module";
import { storeToRefs } from "pinia";
import { RouteName } from "@/constants/routes";
import { useRouter } from "vue-router";
import { TicketStatus } from "@/constants/tickets";
import { EdrProcessStatus, ProcessGraphAction } from "@/constants/edr";
import { useDialogsStore } from "@/_store/dialogs.module";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionModule } from "@/constants/workplaces";
import { i18n } from "@/plugins/i18n";

const props = defineProps<{
  enrollmentCode: string;
  processHash: string;
  processCreationTime: number;
  pid: number;
  eventId: string;
}>();

const nodes = ref<Nodes>({});
const edges = ref<Edges>({});
const selectedNodes = ref<string[]>([]);
const lastSelectedNodes = ref<string[]>([]);
const graph = ref<VNetworkGraphInstance>();
const labelMaxLength = ref<number>(16);
const tooltip = ref<HTMLDivElement>();
const targetNodeId = ref<string>("");
const tooltipOpacity = ref(0);
const tooltipPos = ref({ left: "0px", top: "0px" });
const processGraphStore = useProcessGraphStore();
const router = useRouter();
const dialogsStore = useDialogsStore();
const zoomLevel = ref(1);
const firstLoading = ref(true);
const { processGraph, isProcessGraphLoading, details, isDetailsLoading } =
  storeToRefs(processGraphStore);
const { getProcessGraphData, getProcessGraphDetails, performProcessGraphAction, resetState } =
  processGraphStore;

const configs = defineConfigs({
  view: {
    autoPanAndZoomOnLoad: "fit-content",
    onBeforeInitialDisplay: () => layout(),
    scalingObjects: true,
  },
  node: {
    normal: {
      type: "rect",
      width: 280,
      height: 62,
      borderRadius: 40,
      color: "#FFFFFF",
      strokeColor: "#E2E2EB",
      strokeWidth: 2,
    },
    hover: {
      color: "#eeeef3",
      strokeColor: "#262260",
    },
    selected: {
      color: "#ededff",
      strokeColor: "#262260",
      type: "rect",
      width: 280,
      height: 62,
      borderRadius: 40,
      strokeWidth: 2,
    },
    focusring: {
      visible: false,
    },
    selectable: true,
    label: { direction: "center" },
  },
  edge: {
    normal: {
      color: "#B1B1E1",
      width: 2,
      linecap: "round",
    },
    type: "curve",
  },
});

const targetNodePos = computed(() => {
  const nodePos = layouts.nodes[targetNodeId.value];
  return nodePos || { x: 0, y: 0 };
});

const layouts: Layouts = reactive({
  nodes: {},
});

onMounted(async () => {
  await getProcessGraphData({
    enrollmentCode: props.enrollmentCode,
    processHash: props.processHash,
    processCreationTime: props.processCreationTime,
    pid: props.pid,
  });
  firstLoading.value = false;
});

onUnmounted(() => {
  resetState();
});

// Update `tooltipPos`
watch(
  () => [targetNodePos.value, tooltipOpacity.value],
  () => {
    if (!graph.value || !tooltip.value) return;

    // translate coordinates: SVG -> DOM
    const domPoint = graph.value.translateFromSvgToDomCoordinates(targetNodePos.value);
    // calculates top-left position of the tooltip.
    tooltipPos.value = {
      left: domPoint.x - tooltip.value.offsetWidth / 2 + "px",
      top: domPoint.y - 8 - tooltip.value.offsetHeight - 10 + "px",
    };
  },
  { deep: true }
);

const getStatusColorStyles = (status?: EdrProcessStatus) => {
  switch (status) {
    case EdrProcessStatus.ALLOWED:
      return {
        color: "rgb(var(--v-theme-green-base))",
      };
    case EdrProcessStatus.BLOCKED:
      return {
        color: "rgb(var(--v-theme-red-dark))",
      };
  }
};

function prepareGraphData() {
  processGraph.value.forEach((process: ProcessGraphNode, index: number) => {
    nodes.value[process.id] = {
      name: process.name,
      anchorProcess: process.anchorProcess,
      childProcessesCount: process.childProcessesCount,
    };
    if (process.parentId) {
      edges.value[`edge${index}`] = { source: process.parentId, target: process.id };
    }
  });
}

const selectAnchorNode = async () => {
  const anchorProcessId = Object.entries(nodes.value).find((i) => i[1].anchorProcess)?.[0];
  if (anchorProcessId) {
    selectedNodes.value.push(anchorProcessId);
    lastSelectedNodes.value = selectedNodes.value;
    await getProcessGraphDetails(anchorProcessId);
  }
};

const layout = () => {
  prepareGraphData();
  selectAnchorNode();
  if (Object.keys(nodes.value).length <= 1 || Object.keys(edges.value).length == 0) {
    return;
  }

  // convert graph
  // ref: https://github.com/dagrejs/dagre/wiki
  const g = new dagre.graphlib.Graph();
  // Set an object for the graph label
  g.setGraph({
    rankdir: "LR",
    align: "DL",
    nodesep: 20,
    edgesep: 10,
    ranksep: 40,
  });
  // Default to assigning a new object as a label for each new edge.
  g.setDefaultEdgeLabel(() => ({}));

  // Add nodes to the graph. The first argument is the node id. The second is
  // metadata about the node. In this case we're going to add labels to each of
  // our nodes.
  Object.entries(nodes.value).forEach(([nodeId, node]) => {
    g.setNode(nodeId, { width: 280 * zoomLevel.value, height: 62 * zoomLevel.value, ...node });
  });

  // Add edges to the graph.
  Object.values(edges.value).forEach((edge) => {
    g.setEdge(edge.source, edge.target);
  });

  dagre.layout(g);

  g.nodes().forEach((nodeId: string) => {
    // update node position
    const x = g.node(nodeId).x;
    const y = g.node(nodeId).y;
    layouts.nodes[nodeId] = { x, y };
  });
};

const eventHandlers: EventHandlers = {
  "node:pointerover": ({ node }) => {
    targetNodeId.value = node;
    tooltipOpacity.value = 1; // show
  },
  "node:pointerout": () => {
    tooltipOpacity.value = 0; // hide
  },
  "node:click": async ({ node }) => {
    await getProcessGraphDetails(node);
  },
};

const goToTicket = () => {
  router.push({
    name: RouteName.TICKETS,
    query: {
      search: props.eventId,
      status: TicketStatus.ALL,
    },
  });
};

const openModal = (action: ProcessGraphAction) => {
  const item = {
    action,
    item: details.value,
  };

  const disable = isWorkspaceFrozenOrActionRestricted(
    RolePermissionsScope.PROTECTION,
    SubscriptionModule.EDR
  );

  dialogsStore.openDialog({
    ...confirmationDialogsConfigConstructor({
      action,
      disable,
      item,
      text: i18n.global.t(`modals.${action}.description`, {
        name: details.value.processName,
        processHash: details.value.hash,
      }),
      callback: () =>
        performProcessGraphAction({
          processId: details.value.processId,
          ticketId: props.eventId,
          action,
        }),
    }),
  });
};

const isAnchorProcess = (processId: string) => {
  return nodes.value[processId].anchorProcess;
};
</script>

<style scoped lang="scss">
.process-graph {
  height: calc(100vh - 90px) !important;
  width: calc(100vw - 540px) !important;
}

.process-details-wrapper {
  width: 456px;
  right: 0;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12) !important;
}

.back-to-ticket-wrapper {
  padding: 16px 24px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12) !important;
}

.tooltip {
  top: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  display: grid;
  place-content: center;
  text-align: center;
  font-size: 12px;
  background-color: rgb(var(--v-theme-primary));
  color: rgb(var(--v-theme-white));
  border-radius: 8px;
  padding: 8px 16px;
  pointer-events: none;
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(50% - 7px);
  margin-bottom: -14px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent transparent rgb(var(--v-theme-primary));
  transform: rotate(90deg);
}

.process-graph-spinner {
  left: calc(50% - 30px) !important;
  top: calc(50% - 80px) !important;
  position: fixed;
}

:deep(*) {
  .icon-x:before {
    color: rgb(var(--v-theme-primary)) !important;
  }

  .v-card-title {
    height: 72px;
  }

  .v-card-actions {
    height: 92px;
  }

  .v-btn--size-large {
    padding: 0 20px !important;
  }

  .v-card-text {
    overflow-y: auto;
    max-height: 69vh;
  }

  .svg-wrapper {
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
</style>
