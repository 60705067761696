<template>
  <div class="mb-4">
    <span class="subtitle1">{{
      $t(`modals.${ServiceAction.CONNECTION_STATUS}.lastHeartbeat`)
    }}</span>
    <div
      class="mt-2"
      v-if="config.item.lastHeartbeat"
      :class="{ 'text-yellow-base': isHeartbeatIssue }"
    >
      {{ moment(config.item.lastHeartbeat).format("llll") }}
    </div>
    <div v-else class="mt-2">
      {{ $t("general.notAvailable") }}
    </div>
  </div>
  <v-divider v-if="enablePermissionsSection"></v-divider>
  <div class="mt-4" v-if="enablePermissionsSection">
    <div class="d-flex justify-space-between align-center">
      <span class="subtitle2">{{ $t("cloudApplications.permissions") }}</span>
      <v-btn
        v-if="showGrantPermissionsButton"
        variant="outlined"
        rounded
        color="primary"
        @click="grantPermissions()"
      >
        {{ $t("cloudApplications.grantPermissions") }}
      </v-btn>
    </div>
    <div class="mt-4">
      <div
        v-for="permission of config.item.permissions"
        :key="permission.scope"
        class="d-flex align-center mb-2"
      >
        <v-icon
          :class="{ 'text-red-dark': !permission.granted }"
          :icon="permission.granted ? '$check' : '$x'"
        ></v-icon
        >{{ permission.scope }}
      </div>
    </div>
  </div>
  <div class="d-flex justify-end mt-10">
    <v-btn
      rounded
      color="primary"
      data-testid="google-signup-cancel-btn"
      @click="$emit('dismissed')"
    >
      {{ $t("general.cancel") }}
    </v-btn>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType, watch } from "vue";
import { Service, ServiceAction } from "@/constants/cloud-apps";
import { useI18n } from "vue-i18n";
import { useCloudAppsStore } from "@/_store/cloud-apps/cloud-apps.module";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import moment from "moment";
import type { ConnectAppModalConfigItem } from "@/views/settings/cloud-apps/CloudAppsPage.vue";
import { componentDialogsConfigConstructor } from "@/_helpers/utils";
import { ModalWidth } from "@/constants/modals";
import { useDialogsStore } from "@/_store/dialogs.module";
import GsuiteGrantPrivileges from "@/views/settings/cloud-apps/modals/GsuiteGrantPrivileges.vue";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig<ConnectAppModalConfigItem>>,
      required: true,
    },
  },
  emits: ["change-header-title", "dismissed", "hide-default-footer"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const dialogsModule = useDialogsStore();
    const cloudAppsModule = useCloudAppsStore();
    watch(
      () => props.config.item.name,
      (val) => {
        emit(
          "change-header-title",
          i18n.t(`modals.${ServiceAction.CONNECTION_STATUS}.title`, {
            service: i18n.t(`services.${val}`),
          })
        );
      },
      {
        immediate: true,
      }
    );

    const isHeartbeatIssue = computed(() => {
      return moment().diff(moment(props.config.item.lastHeartbeat), "hours") > 1;
    });

    const enablePermissionsSection = computed(() => {
      return (
        props.config.item.permissions &&
        [Service.OFFICE_365, Service.G_SUITE].includes(props.config.item.name)
      );
    });

    const showGrantPermissionsButton = computed(() => {
      return props.config.item.permissions?.some((permission) => !permission.granted);
    });

    const openConnectServiceModal = (service: Service) => {
      const item = {
        name: service,
        serviceStatus: "active",
        signup: false,
      };

      const config = componentDialogsConfigConstructor({
        width: ModalWidth.LARGE,
        action: ServiceAction.GRANT_PRIVILEGES,
        item,
        component: GsuiteGrantPrivileges,
        disable: false,
        callback: () => {},
        hideFooter: true,
      });

      dialogsModule.openDialog(config);
    };

    const grantPermissions = async () => {
      const service = props.config.item.name;

      if (service === Service.G_SUITE) {
        emit("dismissed");
        openConnectServiceModal(Service.G_SUITE);
      } else {
        await cloudAppsModule.connect({ service, grantPermissions: true });
      }
    };

    return {
      grantPermissions,
      enablePermissionsSection,
      isHeartbeatIssue,
      ServiceAction,
      moment,
      showGrantPermissionsButton,
    };
  },
});
</script>
<style scoped lang="scss">
:deep(*) {
  .text-red-dark::before {
    color: rgb(var(--v-theme-red-dark));
  }
}
</style>
