<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <div v-if="isInbound" class="body1 mb-7">{{ $t("modals.addDomain.inboundDescription") }}</div>
    <v-text-field
      v-model.trim="localValue.name"
      :label="localValue.name ? $t('modals.addDomain.domain.label') : ''"
      outlined
      :rules="domainRules"
      :placeholder="$t('modals.addDomain.domain.placeholder')"
    />
    <template v-if="isInbound">
      <div class="subtitle1 mb-6">{{ $t("modals.addDomain.smtpProxy") }}</div>
      <key-value-input
        v-model:value="localValue.relaySmtpUrls"
        :key-label="$t('modals.addDomain.host.title')"
        :value-label="$t('modals.addDomain.port')"
        :key-config="{
          field: 'host',
          placeholder: $t('modals.addDomain.host.placeholder'),
          rules: hostRules,
          cols: 8,
          md: 8,
        }"
        :value-config="{
          field: 'port',
          placeholder: $t('general.select'),
          rules: [required()],
          cols: 4,
          md: 4,
          inputType: 'select',
          items: ports,
        }"
        :add-btn-label="$t('modals.addDomain.addBtn')"
      />
      <div class="subtitle1 mb-6 mt-6">{{ $t("modals.addDomain.customHeader") }}</div>
      <key-value-input
        v-model:value="localValue.headers"
        :key-label="$t('general.name')"
        :value-label="$t('general.value')"
        :key-config="{
          field: 'name',
          placeholder: $t('modals.addDomain.header.placeholder'),
          rules: [headersKeyValidator()],
          cols: 6,
          md: 6,
        }"
        :value-config="{
          field: 'value',
          placeholder: $t('modals.addDomain.headerValue.placeholder'),
          rules: [headersValueValidator()],
          cols: 6,
          md: 6,
        }"
        :add-btn-label="$t('modals.addDomain.addHeaderBtn')"
      />
    </template>
    <v-combobox
      v-else
      v-model="localValue.subnets"
      :label="localValue.subnets?.length ? $t('modals.addAccessPermissions.restrict.ip.label') : ''"
      :placeholder="$t('modals.addDomain.ip.placeholder')"
      :rules="ipRules"
      maxlength="150"
      outlined
      multiple
      data-testid="access-restriction-modal-add-ip-addresses-input"
    >
      <template v-slot:chip="{ props, item }">
        <v-chip
          v-bind="props"
          :closable="true"
          variant="flat"
          size="default"
          close-icon="$closeCircle"
          :text="item.title"
          :color="getChipColor(item.title)"
        >
          <div class="d-flex align-center">
            <span class="ml-1 mr-2">{{ item.title }}</span>
          </div>
        </v-chip>
      </template>
      <template #item="{ item, props }">
        <v-list-item v-bind="props" :data-testid="`access-restriction-modal-ip-${item}`">
          <div class="d-flex align-center">
            <span class="ml-1">{{ item.title }}</span>
          </div>
        </v-list-item>
      </template>
    </v-combobox>
  </v-form>
</template>

<script lang="ts">
import KeyValueInput from "@/components/KeyValueInput.vue";
import { defineComponent, ref, watch, computed } from "vue";
import Patterns from "@/constants/patterns";
import type { VuetifyFormRef } from "@/types";
import { required } from "@/_helpers/validators";
import { ProxyDirection, ProxyError } from "@/constants/email-proxy";
import { useI18n } from "vue-i18n";
import { defaults } from "lodash";
import type { EmailProxyHeader } from "@/_store/email-security/email-proxy.module.ts";

export default defineComponent({
  components: {
    KeyValueInput,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:valid", "update:localValue"],
  setup(props, { emit }) {
    const valid = ref(true);
    const form = ref<VuetifyFormRef>();

    const localValue = ref(
      defaults(props.config.item, {
        subnets: [],
        name: "",
        relaySmtpUrls: [],
        headers: [],
      })
    );
    const i18n = useI18n();
    const validate = async () => {
      const validationResult = await form.value?.validate();

      return validationResult?.valid;
    };

    const previousExistingDomain = ref();
    const isInbound = props.config.item.direction === ProxyDirection.INBOUND;

    const getChipColor = (ip: string) => {
      const isValid = Patterns.IP.test(ip);

      return isValid ? "indigo-faint" : "error";
    };

    const areFieldsEmpty = computed(() => {
      return localValue.value.headers.every(
        (header: EmailProxyHeader) => !header.name.length && !header.value.length
      );
    });

    const headersValueValidationRule = computed(() => {
      return localValue.value.headers
        ? localValue.value.headers.every((header: EmailProxyHeader) => header.value)
        : true;
    });

    const headerNameValidationRule = computed(() => {
      return localValue.value.headers
        ? localValue.value.headers.every((header: EmailProxyHeader) => header.name)
        : true;
    });

    const headersKeyValidator = () => {
      return (value: string) => {
        if (value || areFieldsEmpty.value) return true;
        if (!headerNameValidationRule.value) {
          return i18n.t("validations.required");
        }
        return true;
      };
    };

    const headersValueValidator = () => {
      return (value: string) => {
        if (value || areFieldsEmpty.value) return true;
        if (!headersValueValidationRule.value) {
          return i18n.t("validations.required");
        }
        return true;
      };
    };

    const domainRules = [
      (domain: string) => {
        if (!domain || domain.length <= 0) {
          return i18n.t("validations.required");
        }
        if (!Patterns.PUNYCODE_DOMAIN.test(domain.trim())) {
          return i18n.t("validations.punycodeDomain");
        }
        if (Patterns.NOT_ALLOWED_DOMAIN_ZONE.test(domain.trim())) {
          return i18n.t("validations.domainZoneIsNotAllowed");
        }
        if (
          localValue.value.errors?.includes(ProxyError.DOMAIN_ALREADY_EXISTS) ||
          previousExistingDomain.value === domain
        ) {
          previousExistingDomain.value = domain;
          localValue.value.errors = [];
          return i18n.t("errors.domainAlreadyExists");
        }
        return true;
      },
    ];
    const hostRules = [
      (domain: string) => {
        if (!domain || domain.length <= 0) {
          return i18n.t("validations.required");
        }
        if (!Patterns.PUNYCODE_DOMAIN.test(domain.trim())) {
          return i18n.t("validations.punycodeDomain");
        }
        if (Patterns.NOT_ALLOWED_DOMAIN_ZONE.test(domain.trim())) {
          return i18n.t("validations.domainZoneIsNotAllowed");
        }
        return true;
      },
    ];
    const ipRules = [
      (values: string[]) => {
        if (!values.length) {
          return i18n.t("validations.required");
        }

        const isInvalid = values.some((value) => !Patterns.IP.test(value));

        return isInvalid ? i18n.t("validations.ip4") : true;
      },
    ];

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      {
        deep: true,
        immediate: true,
      }
    );

    watch(
      valid,
      (newVal: boolean | null) => {
        if (newVal === null) {
          return emit("update:valid", true);
        }

        emit("update:valid", newVal);
      },
      { immediate: false }
    );

    const ports = ["25", "465", "587", "2525"];

    return {
      validate,
      getChipColor,
      form,
      localValue,
      required,
      headersKeyValidator,
      headersValueValidator,
      valid,
      domainRules,
      isInbound,
      hostRules,
      ports,
      ipRules,
    };
  },
});
</script>
