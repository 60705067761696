import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { Service } from "@/constants/cloud-apps";
import type { Pagination } from "@/types";
import { FilterContext, useFiltersStore } from "@/_store/filters.module";

export enum ThirdPartyAppStatus {
  ENABLED = "enabled",
  DISABLED = "disabled",
}

export interface ThirdPartyApp {
  id: number;
  name: string;
  cloudApplication: Service;
  protectedUsers: number;
  status: ThirdPartyAppStatus;
}

export interface ThirdPartyAppServicePermission {
  cloudApplication: Service;
  permitted: boolean;
}

export interface ThirdPartyAppsState {
  loading: boolean;
  showSkeletonLoader: boolean;
  thirdPartyApps: ThirdPartyApp[];
  pagination: Pagination;
  cloudServicesPermissions: ThirdPartyAppServicePermission[];
  total: number;
}

const defaultCloudAppsState = {
  services: [],
  loading: false,
  showSkeletonLoader: false,
  boxConfigFileError: false,
  total: 0,
  cloudServicesPermissions: [],
  pagination: {
    page: 0,
    pageSize: 25,
  },
  thirdPartyApps: [],
};

export const useThirdPartyAppsStore = defineStore("third-party-apps", {
  state: (): ThirdPartyAppsState => ({ ...defaultCloudAppsState }),
  actions: {
    resetPagination() {
      this.pagination = {
        page: 0,
        pageSize: 25,
      };
    },
    async getThirdPartyApps(showSkeletonLoader = true) {
      if (showSkeletonLoader) {
        this.showSkeletonLoader = true;
      } else {
        this.loading = true;
      }

      const filters = useFiltersStore().filters[FilterContext.THIRD_PARTY_APPS];

      const request = {
        ...api.thirdPartyApps(),
        params: {
          ...this.pagination,
          ...filters,
          cloudApplications: filters.cloudApplications.join(","),
        },
      };

      try {
        const { data } = await axiosInstance.request(request);

        this.thirdPartyApps = data.items;
        this.total = data.total;
      } catch (error) {
        console.error(error);
      }
      this.showSkeletonLoader = false;
      this.loading = false;
    },
    async getCloudServicesPermissions() {
      try {
        const request = {
          ...api.thirdPartyAppsPermissions(),
        };
        const { data } = await axiosInstance.request(request);
        this.cloudServicesPermissions = data;
      } catch (err) {
        console.error(err);
      }
    },
    async toggleAppStatus(app: ThirdPartyApp, newStatus: ThirdPartyAppStatus, notify = false) {
      try {
        const request = {
          ...api.toggleThirdPartyAppsStatus(app.id),
          method: "POST",
          params: {
            status: newStatus,
            notify,
          },
        };

        await axiosInstance.request(request);
        await this.getThirdPartyApps(false);
      } catch (err) {
        console.error(err);
      }
    },
  },
});
