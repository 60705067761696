<template>
  <v-stepper-vertical hide-actions v-model="currentStep">
    <template v-slot:default>
      <v-stepper-vertical-item value="1" :elevation="0">
        <template #title>
          <div class="subtitle1">{{ $t("service.dropboxEnforcement.stepOne.title") }}</div>
        </template>
        <div
          class="body2"
          data-testid="dropbox-step1-description"
          v-html="$t('service.dropboxEnforcement.stepOne.description')"
        ></div>
        <div class="mt-5">
          <v-btn
            v-if="isNextStep"
            rounded
            color="primary"
            width="120"
            data-testid="dropbox-step1-continue-btn"
            @click="currentStep++"
          >
            {{ $t("cloudApplications.buttons.continue") }}
          </v-btn>
          <v-btn
            v-else
            color="primary"
            rounded
            width="120"
            :loading="isLoading"
            data-testid="dropbox-step1-connect-btn"
            @click="
              connect({
                service: config.item.name as Service,
                appType: AppType.TEAM_MANAGEMENT_KEY,
              })
            "
          >
            {{ $t("cloudApplications.buttons.connectService") }}
          </v-btn>
        </div>
      </v-stepper-vertical-item>

      <v-stepper-vertical-item
        value="2"
        :elevation="0"
        :title="$t('service.dropboxEnforcement.stepTwo.title')"
      >
        <div class="body2" data-testid="dropbox-step2-description">
          {{ $t("service.dropboxEnforcement.stepTwo.description") }}
        </div>
        <div class="mt-5">
          <v-btn
            rounded
            color="primary"
            width="120"
            :loading="isLoading"
            data-testid="dropbox-step2-confirm-btn"
            @click="
              connect({
                service: config.item.name as Service,
                appType: AppType.TEAM_FILE_ACCESS_KEY,
              })
            "
          >
            {{ $t("cloudApplications.buttons.connectService") }}
          </v-btn>
          <v-btn
            variant="text"
            color="primary"
            data-testid="dropbox-step2-back-btn"
            @click="currentStep = 1"
          >
            {{ $t("general.back") }}
          </v-btn>
        </div>
      </v-stepper-vertical-item>
    </template>
  </v-stepper-vertical>
</template>

<script lang="ts">
import { defineComponent, onMounted, type PropType, ref } from "vue";
import { AppType, Service, ServiceStatus } from "@/constants/cloud-apps";
import { useRoute } from "vue-router";
import { useCloudAppsStore } from "@/_store/cloud-apps/cloud-apps.module";
import { storeToRefs } from "pinia";
import type { ConnectAppModalConfig } from "@/views/settings/cloud-apps/CloudAppsPage.vue";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<ConnectAppModalConfig>,
      required: true,
    },
  },
  setup(props) {
    const cloudAppsModule = useCloudAppsStore();
    const { isLoading } = storeToRefs(cloudAppsModule);
    const currentStep = ref(1);
    const route = useRoute();

    const updateCurrentStep = () => {
      if (route.query.step) {
        currentStep.value = Number.parseInt(route.query.step as string, 10);
      }

      if (props.config.item.serviceStatus === ServiceStatus.INCOMPLETE) {
        currentStep.value = 2;
      }

      if (props.config.item.serviceStatus === ServiceStatus.NOT_CONNECTED) {
        currentStep.value = 1;
      }
    };

    onMounted(() => {
      updateCurrentStep();
    });

    return {
      connect: cloudAppsModule.connect,
      currentStep,
      AppType,
      isNextStep: route.query.isNextStep,
      isLoading,
      Service,
    };
  },
});
</script>
