<template>
  <table-wrapper>
    <v-data-table-server
      class="coronet-table--highlitable mb-10"
      :items="items"
      :headers="headers"
      :loading="loading"
      :fixed-header="true"
      :show-select="false"
      :items-per-page="pagination.pageSize"
      :items-length="totalItems"
      item-value="id"
      @update:options="handlePageChange"
    >
      <template #item="{ item, index }">
        <tr :id="`${index}`" :key="index" v-intersect="handleVirtualScroll">
          <td class="pl-4">
            <span class="body2 text--semibold" v-text="item.name" />
          </td>
          <td class="pl-2 pr-2">
            <span v-text="getCommaSeperatedList(item.ips)" />
          </td>
          <td class="pl-2 pr-2">
            <span v-text="getCommaSeperatedList(item.ports)" />
          </td>
          <td class="pl-2 pr-2">
            <span v-text="item.protocols" />
          </td>
          <td class="pl-2 pr-2">
            <div v-if="!!item.labels.length" class="chip-container pa-4">
              <v-chip
                v-for="(label, index) in item.labels"
                variant="flat"
                size="default"
                :color="label.color"
                rounded="sm"
                :text="label.name"
                :key="index"
              />
            </div>
            <div class="pa-4" v-else>
              <v-icon icon="$warning" />
              <span class="ml-2 text-red" v-text="$t(`network.virtualOffice.ztna.table.noLabel`)" />
            </div>
          </td>
          <td>
            <v-menu :disabled="loading">
              <template #activator="{ props }">
                <v-icon v-bind="props" icon="$dots" />
              </template>
              <v-list>
                <template v-for="(action, index) in getActionsList()" :key="index">
                  <v-list-item @click="handleItemAction(action, item)">
                    <span v-text="$t(`network.virtualOffice.ztna.actions.${action}`)" />
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table-server>
  </table-wrapper>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import TableWrapper from "@/components/TableWrapper.vue";
import { ZtnaAction } from "@/constants/network";
import type { ZtnaRule } from "@/_store/network/ztna.module";
import type { Pagination } from "@/types";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    TableWrapper,
  },
  props: {
    loading: { type: Boolean as PropType<boolean>, default: false },
    items: { type: Array as PropType<ZtnaRule[]>, required: true },
    totalItems: { type: Number, required: true },
    pagination: { type: Object as PropType<Pagination>, required: true },
  },

  emits: ["last-item-reached", "page-change", "action-click"],

  setup(props, { emit }) {
    const { t } = useI18n();

    const handlePageChange = (payload: Pagination): void => {
      emit("page-change", payload);
    };

    const handleVirtualScroll = (isIntersecting: boolean, entries: IntersectionObserverEntry[]) => {
      const intersectedItemId = Number(entries[0].target.id);
      if (isIntersecting && intersectedItemId === props.items?.length - 1) {
        if (intersectedItemId === props?.totalItems - 1) {
          return;
        }
        emit("last-item-reached");
      }
    };

    const getActionsList = () => {
      return [ZtnaAction.DELETE_ZTNA_RULE, ZtnaAction.EDIT_ZTNA_RULE];
    };

    const getCommaSeperatedList = (list: string[]) => {
      return list.join(",");
    };

    const handleItemAction = (action: ZtnaAction, item: ZtnaRule): void => {
      emit("action-click", { action, item });
    };

    const headers = [
      {
        title: t("network.virtualOffice.ztna.table.headers.resourceName"),
        key: "resourceName",
        sortable: false,
      },
      {
        title: t("network.virtualOffice.ztna.table.headers.ipAddress"),
        key: "ipAddress",
        sortable: false,
      },
      {
        title: t("network.virtualOffice.ztna.table.headers.ports"),
        key: "ports",
        sortable: false,
      },
      {
        title: t("network.virtualOffice.ztna.table.headers.protocol"),
        key: "protocol",
        sortable: false,
      },
      {
        title: t("network.virtualOffice.ztna.table.headers.devices"),
        key: "devices",
        sortable: false,
      },
      {},
    ];

    return {
      getCommaSeperatedList,
      handlePageChange,
      handleItemAction,
      handleVirtualScroll,
      headers,
      getActionsList,
    };
  },
});
</script>

<style scoped lang="scss">
.chip-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  gap: 4px;
}
</style>
