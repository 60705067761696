<template>
  <div class="content-md margin-auto mt-4">
    <div class="d-flex align-center justify-space-between mb-4">
      <div class="d-flex align-center">
        <span class="headline5" data-testid="protected-users-page-title">
          {{ $t("mspUsersPage.title") }}
        </span>
      </div>
    </div>

    <div class="d-flex justify-space-between align-center w-100 mb-4">
      <v-tabs
        v-model="localFilters.hasVulnerabilities"
        align-with-title
        class="overflow-visible flex-b"
        color="orange-base"
        background-color="white"
        slider-color="orange-base"
        :height="48"
        :show-arrows="false"
      >
        <v-tab
          v-for="item in tabs"
          :key="item.id"
          class="mr-8 opacity-100"
          :height="42"
          :value="item.status"
          variant="plain"
        >
          {{ item.name }}
        </v-tab>
      </v-tabs>
      <filter-wrapper
        :show-clear-button="showClearFiltersButton"
        @clear-filters-clicked="clearFilters()"
      >
        <v-text-field
          v-model="localFilters.search"
          density="compact"
          variant="outlined"
          background-color="white"
          :placeholder="$t('general.search')"
          :class="{ 'filter-active': localFilters.search?.length }"
          class="search-field"
          prepend-inner-icon="$search"
          hide-details
          clearable
          persistent-clear
          clear-icon="$x"
        />
      </filter-wrapper>
    </div>
    <list-with-preview :show-skeleton-loader="usersLoading">
      <template #list>
        <list-table
          :items="items"
          item-key="id"
          :pagination="pagination"
          :loading="loading"
          :selected-items="selectedItems"
          :is-indeterminate="isIndeterminate"
          :all-items-selected="allItemsSelected"
          :selected-items-quantity="selectedItemsQuantity"
          :total-items="total"
          :available-bulk-actions="availableBulkActions"
          :actions-loading="actionsLoading"
          @page-changed="onPageChange"
          @update-preview="onPreviewUpdate($event)"
          @bulk-menu-clicked="getBulkActions"
          @bulk-action-clicked="onBulkActionClick($event)"
          @handle-one-row-selection="handleOneRowSelection($event.selected, $event.item)"
          @handle-all-rows-selection="handleAllRowsSelection"
        >
          <template #list-item="{ item, index }">
            <div class="list-item-grid py-1">
              <div class="list-item-grid__icon-block">
                <v-icon icon="$user" size="40" />
              </div>

              <div class="list-item-grid__details-block d-flex flex-column justify-center px-2">
                <div :data-testid="`users-table-name-row-${index}`" class="subtitle2">
                  {{ item?.email }}
                  <template v-if="item.aliases.length"> +{{ item.aliases.length }}</template>
                </div>
                <div :data-testid="`users-table-email-row-${index}`" class="body2">
                  {{ item?.name }}
                </div>
                <div class="body2">
                  {{ item?.workspaceName }}
                </div>
              </div>

              <div class="list-item-grid__status-block">
                <span v-if="item.isSuspended" class="caption text-red-dark mr-1">
                  {{ $t("mspUsersPage.suspended") }}
                </span>
                <v-tooltip v-if="item.hasOpenTickets" open-delay="300" location="top">
                  <template #activator="{ props }">
                    <v-icon
                      :data-testid="`users-table-icon-row-${index}`"
                      icon="$warning"
                      v-bind="props"
                    />
                  </template>
                  {{ $t("general.unresolvedTickets") }}
                </v-tooltip>
                <v-tooltip v-else open-delay="300" location="top">
                  <template #activator="{ props }">
                    <v-icon
                      :data-testid="`users-table-icon-row-${index}`"
                      v-bind="props"
                      icon="$circleCheck"
                    />
                  </template>
                  {{ $t("general.noTickets") }}
                </v-tooltip>
              </div>
            </div>
          </template>
        </list-table>
      </template>
      <template #preview>
        <msp-user-preview :user="preview"></msp-user-preview>
      </template>
    </list-with-preview>
  </div>
</template>

<script lang="ts">
import FilterWrapper from "@/components/FilterWrapper.vue";
import ListWithPreview from "@/components/ListWithPreview.vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { storeToRefs } from "pinia";
import { defaultFiltersState, FilterContext } from "@/_store/filters.module";
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useFilters } from "@/composables/useFilters";
import ListTable from "@/components/tables/ListTable.vue";
import { useDialogsStore } from "@/_store/dialogs.module.ts";
import { TicketAction } from "@/constants/tickets.ts";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils.ts";
import { SubscriptionModule } from "@/constants/workplaces.ts";
import BulkTicketCloseModal from "@/components/modals/BulkTicketCloseModal.vue";
import { ModalWidth } from "@/constants/modals.ts";
import { RolePermissionsScope } from "@/_store/roles.module.ts";
import { type MspUserListItem, useMspUsersStore } from "@/_store/msp/msp-users.module.ts";
import MspUserPreview from "@/components/msp/MspUserPreview.vue";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { MspUserVulnerabilityStatus } from "@/constants/msp-users.ts";
import { useSimpleSelectableTable } from "@/composables/useSimpleSelectableTable.ts";

export default defineComponent({
  components: {
    ListTable,
    FilterWrapper,
    ListWithPreview,
    MspUserPreview,
  },
  setup() {
    const usersStore = useMspUsersStore();
    const dialogStore = useDialogsStore();
    const i18n = useI18n();
    const preview = ref<MspUserListItem | null>(null);
    const { getItems, onPageChange } = usersStore;
    const availableBulkActions = ref<{ name: string; displayName: string }[]>([]);
    const actionsLoading = ref(false);

    const {
      localFilters,
      filtersUpdating,
      clearFilters: clearFiltersFromComposable,
    } = useFilters(FilterContext.MSP_USERS);

    const { loading, items, total, pagination, usersLoading, userDetailsLoading } =
      storeToRefs(usersStore);

    const showClearFiltersButton = computed(() => {
      return !isEqual(
        { ...localFilters.value, hasVulnerabilities: null },
        { ...defaultFiltersState[FilterContext.MSP_USERS], hasVulnerabilities: null }
      );
    });

    const {
      selectedItems,
      handleOneRowSelection,
      handleAllRowsSelection,
      isIndeterminate,
      allItemsSelected,
      selectedItemsQuantity,
      clearSelection,
    } = useSimpleSelectableTable(items);

    const tabs = computed(() => [
      {
        id: 0,
        name: i18n.t("mspUsersPage.filters.withIssues"),
        status: MspUserVulnerabilityStatus.WITH_ISSUES,
      },
      {
        id: 1,
        name: i18n.t("mspUsersPage.filters.noIssues"),
        status: MspUserVulnerabilityStatus.NO_ISSUES,
      },
      { id: 2, name: i18n.t("mspUsersPage.filters.all"), status: MspUserVulnerabilityStatus.ALL },
    ]);

    const onPreviewUpdate = (item: MspUserListItem | null) => {
      preview.value = item ? { ...item } : null;
    };

    watch(
      filtersUpdating,
      async (value) => {
        if (value) await getItems();
      },
      { deep: true, immediate: true }
    );

    onMounted(async () => {
      await getItems(true);
    });

    onUnmounted(() => {
      usersStore.$reset();
      clearFiltersFromComposable();
      clearSelection();
    });

    const clearFilters = () => {
      localFilters.value = cloneDeep({
        ...defaultFiltersState[FilterContext.MSP_USERS],
        hasVulnerabilities: localFilters.value.hasVulnerabilities,
      });
    };

    const onBulkActionClick = (action: string) => {
      switch (action) {
        case TicketAction.MARK_AS_PROCESSED:
          dialogStore.openDialog(
            componentDialogsConfigConstructor({
              item: {
                selection: [...selectedItems.value],
                callback: usersStore.onProtectedUsersAction,
                modules: [
                  SubscriptionModule.EMAIL_SECURITY,
                  SubscriptionModule.CLOUD_SECURITY,
                  SubscriptionModule.USER_DATA_GOVERNANCE,
                ],
              },
              action,
              component: BulkTicketCloseModal,
              width: ModalWidth.MEDIUM,
              callback: () => {},
              hideFooter: true,
              disable: isWorkspaceFrozenOrActionRestricted(RolePermissionsScope.VIEWS, "usersView"),
            })
          );
          break;
        default:
          dialogStore.openDialog(
            confirmationDialogsConfigConstructor({
              action,
              disable: isWorkspaceFrozenOrActionRestricted(RolePermissionsScope.VIEWS, "usersView"),
              callback: () => {
                usersStore.onProtectedUsersAction({
                  selection: [...selectedItems.value],
                  action,
                });
              },
            })
          );
          break;
      }
    };

    const getBulkActions = async () => {
      availableBulkActions.value = [];
      actionsLoading.value = true;
      try {
        const { data } = await usersStore.getActions(selectedItems.value.map((v) => v.id));
        availableBulkActions.value = data.items.map((name) => ({
          name,
          displayName: i18n.t(`usersPage.actions.${name}`),
        }));
        actionsLoading.value = false;
      } catch {
        actionsLoading.value = false;
      }
    };

    return {
      breadcrumbsItems: [
        {
          title: `‹ ${i18n.t("general.home")}`,
          disabled: false,
          to: { path: "/portal/dashboard" },
        },
      ],
      coronetSkeletonLoaderTypes,
      preview,
      loading,
      items,
      total,
      pagination,
      usersLoading,
      userDetailsLoading,
      localFilters,
      filtersUpdating,
      showClearFiltersButton,
      clearFilters,
      onPageChange,
      onPreviewUpdate,
      getItems,
      selectedItems,
      handleOneRowSelection,
      handleAllRowsSelection,
      isIndeterminate,
      allItemsSelected,
      selectedItemsQuantity,
      onBulkActionClick,
      getBulkActions,
      availableBulkActions,
      actionsLoading,
      tabs,
    };
  },
});
</script>

<style lang="scss" scoped>
.search-field {
  max-width: 280px;
}
</style>
