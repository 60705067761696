<template>
  <div class="content-md margin-auto">
    <div class="mb-3 mt-4">
      <v-breadcrumbs data-testid="go-home-link" :items="breadCrumbsItems" />
    </div>

    <div class="d-flex align-center justify-space-between mb-1">
      <div class="d-flex align-center">
        <span class="headline5" data-testid="ticket-log-page-title">
          {{ $t("general.socTicketLog") }}
        </span>
      </div>
    </div>
    <div class="d-flex justify-space-between align-center mb-6 flex-wrap">
      <v-tabs
        v-model="localFilters.status"
        align-with-title
        class="edit-subscription-tabs"
        color="orange-base"
        background-color="white"
        slider-color="orange-base"
        :show-arrows="false"
        :height="48"
      >
        <v-tab
          v-for="item in tabs"
          :key="item.id"
          class="mr-8 opacity-100"
          :height="42"
          :value="item.status"
          variant="plain"
        >
          {{ item.name }}
        </v-tab>
      </v-tabs>
      <div class="mt-4 w-100">
        <filter-wrapper
          :show-clear-button="showClearFiltersButton"
          @clear-filters-clicked="clearFilters()"
        >
          <div class="grid-container">
            <v-autocomplete
              v-model="localFilters.workspaceId"
              :loading="workspacesLoading"
              :items="workspaces"
              :placeholder="$t('tickets.filters.searchCustomers')"
              prepend-inner-icon="icon-search"
              density="compact"
              variant="outlined"
              :class="{ 'filter-active': localFilters.workspaceId }"
              class="filter-menu customer-filter"
              hide-no-data
              hide-details
              clear-on-select
              rounded
              item-value="workspaceId"
              item-title="workspaceName"
              background-color="white"
              menu-icon="icon-triangle"
            >
              <template #append-item>
                <div v-intersect="onIntersect" />
              </template>
            </v-autocomplete>

            <v-select
              v-model="localFilters.widget"
              :items="widgets"
              :menu-props="{ maxHeight: '300' }"
              class="widget-filter filter-menu"
              :class="{ 'filter-active': localFilters.widget }"
              :placeholder="$t('ticketDetails.filters.widgets.placeholder')"
              data-testid="tickets-page-widget-type-filter"
              density="compact"
              variant="outlined"
              rounded
              hide-details
              background-color="white"
              @update:model-value="localFilters.eventTriggers = []"
            >
              <template #selection="{ item }">
                <span class="body2">
                  {{ $t(`ticketDetails.filters.widgets.${item.title}`) }}
                </span>
              </template>
              <template #item="{ item, props }">
                <v-list-item
                  v-bind="props"
                  :title="$t(`ticketDetails.filters.widgets.${item.title}`)"
                  :data-testid="`tickets-page-widget-type-filter-${item}-item`"
                >
                </v-list-item>
              </template>
            </v-select>
            <v-select
              v-model="localFilters.eventTriggers"
              :items="triggers"
              :menu-props="{ maxHeight: '300' }"
              class="filter-menu"
              :class="{ 'filter-active': !!localFilters.eventTriggers?.length }"
              :placeholder="$t('ticketDetails.filters.type')"
              data-testid="tickets-page-trigger-filter"
              density="compact"
              variant="outlined"
              multiple
              rounded
              hide-details
              background-color="white"
              item-value="value"
              item-title="value"
            >
              <template #selection="{ index }">
                <span v-if="index === 0" class="body2">{{
                  $t("manageWorkspaces.filters.type")
                }}</span>
              </template>
              <template #label>
                <span v-if="localFilters.eventTriggers?.length" class="filter-label">
                  {{ localFilters.eventTriggers.length }}
                </span>
              </template>
              <template #item="{ item, props }">
                <v-list-subheader v-if="item.raw.type === 'subheader'" :title="item.raw.value" />
                <v-list-item v-else v-bind="props">
                  <template v-slot:prepend="{ isActive }">
                    <v-list-item-action start>
                      <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                    </v-list-item-action>
                  </template>

                  <template v-slot:title>
                    {{ $t(`tickets.eventTriggers.${item.title}`) }}
                  </template>
                </v-list-item>
              </template>
            </v-select>
            <v-select
              v-model="localFilters.socStatuses"
              :items="socStatuses"
              :menu-props="{ maxHeight: '300' }"
              class="filter-menu"
              :class="{ 'filter-active': localFilters.socStatuses?.length > 0 }"
              :placeholder="$t('ticketDetails.filters.status')"
              data-testid="tickets-page-trigger-filter"
              density="compact"
              variant="outlined"
              multiple
              rounded
              hide-details
              background-color="white"
            >
              <template #selection="{ index }">
                <span v-if="index === 0" class="coro-body2">{{
                  $t("ticketDetails.filters.status")
                }}</span>
              </template>
              <template #label>
                <span v-if="localFilters.socStatuses?.length" class="filter-label">{{
                  localFilters.socStatuses.length
                }}</span>
              </template>
              <template #item="{ item, props }">
                <v-list-item v-bind="props">
                  <template v-slot:prepend="{ isActive }">
                    <v-list-item-action start>
                      <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                    </v-list-item-action>
                  </template>

                  <template v-slot:title>
                    {{ $t(`tickets.socStatuses.${item.title}`) }}
                  </template>
                </v-list-item>
              </template>
            </v-select>
            <date-range-picker
              v-model="localFilters.eventTimeRange"
              class="daterange-picker"
              :class="{
                'filter-active':
                  localFilters.eventTimeRange.start && localFilters.eventTimeRange.end,
              }"
              :presets="presets"
              :placeholder="$t('ticketDetails.filters.during')"
              :input-props="{
                'append-icon': 'icon-triangle',
                'hide-details': true,
              }"
              next-icon="icon-expand"
              data-testid="tickets-page-time-range-filter"
              prev-icon="icon-minimize"
              density="compact"
              variant="outlined"
            />
            <v-text-field
              v-model="localFilters.search"
              density="compact"
              variant="outlined"
              filled
              rounded
              clearable
              clear-icon="icon-x"
              background-color="white"
              :placeholder="$t('general.search')"
              prepend-inner-icon="icon-search"
              hide-details
              data-testid="tickets-page-search-field"
              @keyup.enter.prevent
              @keydown.enter.prevent
            >
            </v-text-field>
          </div>
        </filter-wrapper>
      </div>
    </div>

    <list-with-preview :show-skeleton-loader="showLoader">
      <template #list>
        <tickets-list-table
          :items="tickets"
          :total-items="allTickets"
          :selection="selection"
          :pagination="pagination"
          :loading="loading"
          :margin-top="150"
          :tickets-filters="localFilters"
          :is-soc="true"
          @page-changed="onPageChanged($event)"
          @update-preview="ticketPreview = $event"
        ></tickets-list-table>
      </template>
      <template #preview>
        <ticket-preview :ticket="ticketPreview" :is-soc="true"></ticket-preview>
      </template>
    </list-with-preview>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SocStatus, TicketsModuleFilter } from "@/constants/tickets";
import { FilterContext } from "@/_store/filters.module";
import FilterWrapper from "@/components/FilterWrapper.vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import DateRangePicker from "@/components/DateRangePicker.vue";
import ListWithPreview from "@/components/ListWithPreview.vue";
import TicketsListTable from "@/components/tables/TicketsListTable.vue";
import TicketPreview from "@/views/tickets/TicketPreview.vue";
import { useTicketsPage } from "@/composables/useTicketsPage";
import { getDateRangePresets } from "@/constants/date-range-picker";

export default defineComponent({
  components: {
    FilterWrapper,
    DateRangePicker,
    ListWithPreview,
    TicketsListTable,
    TicketPreview,
  },
  setup() {
    const {
      localFilters,
      filtersUpdating,
      showClearFiltersButton,
      clearFilters,
      getTickets,
      triggers,
      showLoader,
      tickets,
      allTickets,
      selection,
      pagination,
      loading,
      onPageChanged,
      tabs,
      currentTab,
      ticketPreview,
      workspaces,
      workspacesLoading,
      onWorkspaceSearchUpdate,
      onIntersect,
      breadCrumbsItems,
    } = useTicketsPage(FilterContext.SOC_TICKETS, true);

    return {
      localFilters,
      filtersUpdating,
      showClearFiltersButton,
      clearFilters,
      getTickets,
      triggers,
      showLoader,
      tickets,
      allTickets,
      selection,
      pagination,
      loading,
      onPageChanged,
      tabs,
      currentTab,
      ticketPreview,
      workspacesLoading,
      workspaces,
      breadCrumbsItems,
      coronetSkeletonLoaderTypes,
      widgets: Object.values(TicketsModuleFilter),
      presets: getDateRangePresets(),
      filterContext: FilterContext.SOC_TICKETS,
      socStatuses: [
        SocStatus.NEW,
        SocStatus.NEW_COMMENTS,
        SocStatus.WIP_TIER1,
        SocStatus.REQUIRES_TIER2,
        SocStatus.WIP_TIER2,
      ],
      onWorkspaceSearchUpdate,
      onIntersect,
    };
  },
});
</script>

<style lang="scss" scoped>
.autocomplete {
  position: absolute;
  color: rgb(var(--v-theme-indigo-medium-base)) !important;
  user-select: none;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  top: 8px;
  left: 52px;

  &-container {
    position: relative;
  }
}
.grid-container {
  display: grid;
  grid-template-columns: minmax(220px, 1fr) minmax(230px, 1fr) 1fr 1fr minmax(240px, 1fr) minmax(
      190px,
      1fr
    );
  align-items: center;
  gap: 8px;
  width: 100%;
}

:deep(*) {
  .nav-tabs {
    max-width: 300px;
  }

  .v-chip-group .v-chip {
    min-width: 32px;
  }

  .v-list-item__title {
    font-size: 16px !important;
    color: rgb(var(--v-theme-primary)) !important;
  }

  .clear-btn {
    z-index: 0 !important;
  }

  .search-field {
    .v-text-field__slot {
      z-index: 10 !important;
    }
  }

  .v-date-range__input-field .v-input__slot {
    padding: 0 8px 0 16px !important;
  }
}
</style>
