export enum RouteName {
  PORTAL = "portal",
  SOC_TICKETS = "soc-tickets",
  ENDPOINT_SECURITY_SETTINGS_PAGE = "endpoint-security-settings-page",
  ENDPOINT_SECURITY_DEVICE_POSTURE_TAB = "endpoint-security-device-posture-tab",
  ENDPOINT_SECURITY_NGAV_TAB = "endpoint-security-ngav-tab",
  ENDPOINT_SECURITY_ALLOW_BLOCK_LIST_TAB = "endpoint-security-allow-block-list-tab",
  ENDPOINT_SECURITY_DEVICE_ADDONS_TAB = "endpoint-security-device-addons-tab",
  EDR_PAGE = "edr-page",
  EDR_PROCESSES_TAB = "edr-processes-tab",
  EDR_TELEMETRY_TAB = "edr-telemetry-tab",
  EDR_ALLOW_BLOCK_LIST_TAB = "edr-allow-block-list-tab",
  PROCESS_GRAPH = "process-graph",
  ENDPOINT_DATA_GOVERNANCE_PAGE = "endpoint-data-governance-page",
  ENDPOINT_DATA_GOVERNANCE_PRIVACY_SENSITIVE_DATA_TAB = "endpoint-data-governance-privacy-sensitive-data-tab",
  ENDPOINT_DATA_GOVERNANCE_SENSITIVE_DATA_SCANS_TAB = "endpoint-data-governance-sensitive-data-scans-tab",
  ACCESS_CONTROL_PAGE = "access-control-page",
  ACCESS_CONTROL_ADMIN_USERS_TAB = "access-control-admin-users-tab",
  ACCESS_CONTROL_ROLES_TAB = "access-control-roles-tab",
  ACCESS_CONTROL_ACTIVE_SESSIONS_TAB = "access-control-active-sessions-tab",
  DEVICES_SETTINGS_PAGE = "devices-settings-page",
  DEVICES_SETTINGS_AGENT_DEPLOYMENT_TAB = "devices-settings-agent-deployment-tab",
  DEVICES_SETTINGS_DEVICE_SETTINGS_TAB = "devices-settings-device-settings-tab",
  DEVICES_SETTINGS_LABELS_TAB = "devices-settings-labels-tab",
  ACCESS_CONTROL_ADMIN_USER_DETAILS = "access-control-admin-user-details",
  CONNECTORS_PAGE = "connectors-page",
  CONNECTORS_SIEM_TAB = "connectors-siem-tab",
  CONNECTORS_API_KEYS_TAB = "connectors-api-keys-tab",
  CONNECTORS_WEBHOOKS_TAB = "connectors-webhooks-tab",
  CONNECTORS_PSA_TAB = "connectors-psa-tab",
  CONNECTORS_CONNECTWISE = "connectors-connectwise",
  CONNECTORS_CONNECTWISE_WORKSPACE_MAPPING = "connectors-connectwise-workspace-mapping",
  CONNECTORS_CONNECTWISE_SERVICE_MAPPING = "connectors-connectwise-service-mapping",
  CONNECTORS_CONNECTWISE_TICKETING = "connectwise-ticketing",
  CONNECTORS_AUTOTASK = "connectors-autotask",
  CONNECTORS_AUTOTASK_WORKSPACE_MAPPING = "connectors-autotask-workspace-mapping",
  CONNECTORS_AUTOTASK_SERVICE_MAPPING = "connectors-autotask-service-mapping",
  CONNECTORS_AUTOTASK_TICKETING = "connectors-autotask-ticketing",
  USER_DATA_GOVERNANCE_PAGE = "user-data-governance-page",
  USER_DATA_GOVERNANCE_MONITORING_TAB = "user-data-governance-monitoring-tab",
  USER_DATA_GOVERNANCE_PERMISSIONS_TAB = "user-data-governance-permissions-tab",
  USER_DATA_GOVERNANCE_EXCLUSIONS_TAB = "user-data-governance-exclusions-tab",
  EMAIL_SECURITY_PAGE = "email-security-page",
  EMAIL_SECURITY_SETTINGS_TAB = "email-security-settings-tab",
  EMAIL_SECURITY_ALLOW_BLOCK_LIST_TAB = "email-security-allow-block-list-tab",
  EMAIL_SECURITY_ADD_INS_TAB = "email-security-add-ins-tab",
  EMAIL_SECURITY_INBOUND_GATEWAY_TAB = "email-security-inbound-gateway-tab",
  EMAIL_SECURITY_CORO_MAIL_TAB = "email-security-coro-mail-tab",
  CLOUD_SECURITY_PAGE = "cloud-security-page",
  CLOUD_SECURITY_PERMISSIONS_LIST_TAB = "cloud-security-permissions-list-tab",
  CLOUD_SECURITY_THIRD_PARTY_APPS_TAB = "cloud-security-third-party-apps-tab",
  CLOUD_SECURITY_ACCESS_PERMISSIONS_PAGE = "cloud-security-access-permissions-page",
  CLOUD_SECURITY_SETTINGS = "cloud-security-settings",
  DASHBOARD = "dashboard",
  SETTINGS = "settings",
  ACTIVITY_LOGS = "activity-logs",
  DEVICES = "devices",
  PROTECTED_USERS = "protected-users",
  BACK_OFFICE_PAGE = "back-office-page",
  BACKOFFICE_DASHBOARD = "backoffice-dashboard",
  BACKOFFICE_PERMISSIONS_PAGE = "backoffice-permissions-page",
  GLOBAL_ADMIN_USERS = "global-admin-users",
  GLOBAL_ROLES = "global-roles",
  BACK_OFFICE_AGENT_GRADUAL_UPDATE = "back-office-agent-gradual-update",
  // TODO: uncomment in future when needed again
  // BACK_OFFICE_WORKSPACE_CODES = "back-office-workspace-codes",
  MSP = "msp",
  MSP_OVERVIEW_PAGE = "msp-overview-page",
  MSP_WORKSPACES_TAB = "msp-workspaces-tab",
  MSP_ADMIN_USERS_TAB = "msp-admin-users-tab",
  MSP_ROLES_TAB = "msp-roles-tab",
  MSP_TICKETS_PAGE = "msp-tickets-page",
  MSP_DEVICES_PAGE = "msp-devices-page",
  MSP_PROTECTED_USERS_PAGE = "msp-protected-users-page",
  MSP_CONTROL_PANEL_PAGE = "msp-control-panel-page",

  ALERTS = "alerts",
  SOC_PAGE = "soc-page",
  SOC_CONTACTS_TAB = "soc-contacts-tab",
  SOC_REMEDIATION_TAB = "soc-remediation-tab",
  USERS_SETTINGS_PAGE = "users-settings-page",
  USERS_SETTINGS_USERS_TAB = "users-settings-users-tab",
  USERS_SETTINGS_GROUPS_TAB = "users-settings-groups-tab",
  USERS_SETTINGS_EXCEPTIONS_TAB = "users-settings-exceptions-tab",
  NETWORK_PAGE = "network-page",
  NETWORK_VIRTUAL_OFFICE_TAB = "virtual-office-tab",
  NETWORK_SECURE_WEB_GATEWAY_TAB = "secure-web-gateway",
  NETWORK_SITE_TO_SITE_TAB = "network-site-to-site",
  NETWORK_SETTINGS_TAB = "network-settings",
  TICKETS = "tickets",
  MY_ACCOUNT_PAGE = "my-account-page",
  MY_ACCOUNT_PROFILE_TAB = "my-account-profile-tab",
  MY_ACCOUNT_CHANGE_PASSWORD_TAB = "my-account-change-password-tab",
  MY_ACCOUNT_MFA_TAB = "my-account-mfa-tab",
  MY_ACCOUNT_NOTIFICATIONS_TAB = "notifications",
  REPORTS_PAGE = "reports-page",
  REPORTS_SELECT_REPORTS = "reports-select-reports",
  REPORTS_EXPORTS_TAB = "reports-exports-tab",
  REPORTS_SCHEDULED_TAB = "reports-scheduled-tab",
  REPORTS_EXECUTIVE_SUMMARY_TAB = "reports-executive-summary-tab",
  REPORTS_DNS_SUMMARY_TAB = "reports-dns-summary-tab",
  REPORTS_MANAGED_SERVICES_SUMMARY_TAB = "reports-managed-services-summary-tab",
  REPORTS_SECURED_MESSAGES_SUMMARY_TAB = "reports-secured-messages-summary-tab",
  BILLING_PAGE = "billing-page",
  BILLING_SUBSCRIPTION_TAB = "billing-subscription-tab",
  CLOUD_APPS_PAGE = "cloud-apps-page",
  LOGIN = "login",
  OAUTH = "oauth",
  MFA_PAGE = "mfa",
  MFA_VERIFICATION = "verification",
  ACTIVATE_MOBILE_PAGE = "activate-mobile",
  MDM_CALLBACK_PAGE = "mdm-callback-page",
  PROXY_VERIFICATION_PAGE = "proxy-verification-page",
  PNI_VERIFICATION_PAGE = "pni-verification-page",
  SIGN_UP = "sign-up",
  WORKSPACES = "workspaces",
  FORGOT_PASSWORD = "forgot-password",
  RESET_PASSWORD = "reset-password",
  /**
   * @deprecated
   */
  DOWNLOADS_PAGE = "downloads-page",
  INVITED_PAGE = "invited",
  SSO_PAGE = "sso",
  ABNORMAL_DATA_MANIPULATION_DETAILS_PAGE = "abnormal-data-manipulation-details-page",
}
