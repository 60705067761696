import api from "@/_helpers/api";
import { defineStore } from "pinia";
import type { Pagination, Selection } from "@/types";
import { axiosInstance } from "@/plugins/https";
import { useFiltersStore } from "@/_store/filters.module";
import { getSelectionAsStringsArray, isAccessRestricted } from "@/_helpers/utils";
import { useSnackbarStore } from "@/_store/snackbar.module";
import { i18n } from "@/plugins/i18n";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { useSelectorStore } from "@/_store/selector.module";
import { SubscriptionModule } from "@/constants/workplaces";
import type { AxiosResponse } from "axios";

export interface UserDetails {
  id: null | string;
  name: null | string;
  protectionGrantedTime: number;
  cloudServices: Array<string>;
  tickets: Array<any>;
  activityLogs: Array<any>;
  aliases: Array<string>;
}

export interface UserListItem {
  id: string;
  name: string;
  email: string;
  hasOpenTickets: boolean;
  vulnerabilities: UserVulnerability[];
  aliases: string[];
}

export interface UserVulnerability {
  issue: string;
}

interface UsersState {
  loading: boolean;
  items: Array<UserListItem>;
  total: number;
  pagination: Pagination;
  usersLoading: boolean;
  search: null | string;
  userDetailsLoading: boolean;
  activityLogsLoading: boolean;
  userDetails: UserDetails;
}

const defaultUsersState: UsersState = {
  loading: false,
  items: [],
  total: 0,
  pagination: {
    page: 0,
    pageSize: 25,
  },
  usersLoading: true,
  search: null,
  userDetailsLoading: false,
  activityLogsLoading: false,
  userDetails: {
    id: null,
    name: null,
    protectionGrantedTime: 0,
    cloudServices: [],
    tickets: [],
    activityLogs: [],
    aliases: [],
  },
};

export const useUsersStore = defineStore("users", {
  state: (): UsersState => ({ ...defaultUsersState }),
  actions: {
    async getUserDetails(userId: string): Promise<void> {
      this.userDetailsLoading = true;

      const activityLogsAccessRestricted = isAccessRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.ACTIVITY_LOGS
      );

      const ticketsAccessRestricted = (
        [
          SubscriptionModule.USER_DATA_GOVERNANCE,
          SubscriptionModule.CLOUD_SECURITY,
          SubscriptionModule.EMAIL_SECURITY,
        ] as const
      ).every((module) => isAccessRestricted(RolePermissionsScope.TICKETS, module));

      const userInfoRequest = {
        ...api.getUserInfo(userId),
      };
      const userTicketsRequest = {
        ...api.getUserTickets(userId),
      };
      const userActivityLogsRequest = {
        ...api.getUserActivityLogs(userId, { page: 0, pageSize: 5 }),
      };

      Promise.all([
        axiosInstance.request(userInfoRequest),
        ticketsAccessRestricted ? null : axiosInstance.request(userTicketsRequest),
        activityLogsAccessRestricted ? null : axiosInstance.request(userActivityLogsRequest),
      ])
        .then(([userInfoResponse, userTicketsResponse, userActivityLogsResponse]) => {
          const userDetails = {
            ...userInfoResponse.data,
            tickets: userTicketsResponse ? [...userTicketsResponse.data] : [],
            activityLogs: userActivityLogsResponse ? [...userActivityLogsResponse.data.items] : [],
          };
          this.userDetails = userDetails;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.usersLoading = false;
          this.userDetailsLoading = false;
        });
    },
    async getItems(payload: { showSkeletonLoader: boolean } = { showSkeletonLoader: false }) {
      this.loading = true;
      this.usersLoading = payload?.showSkeletonLoader;

      const filtersStore = useFiltersStore();
      const request = {
        ...api.getUsers({
          ...this.pagination,
          ...filtersStore.filters.usersFilters,
        }),
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.items = data.items;
        this.total = data.total;
      } catch (error) {
        console.error(error);
      }

      this.loading = false;
      this.usersLoading = false;
    },
    async onPageChange(pagination: Pagination) {
      this.pagination = pagination;
      await this.getItems();
    },

    async undoActivityLog(id: string) {
      this.activityLogsLoading = true;

      try {
        const request = { ...api.activityLogsUndo(id) };

        await axiosInstance.request(request);

        const userActivityLogsRequest = {
          ...api.getUserActivityLogs(this.userDetails.id as string, { page: 0, pageSize: 5 }),
        };

        const { data } = await axiosInstance.request(userActivityLogsRequest);

        this.userDetails.activityLogs = data.items;
      } catch (error) {
        console.error(error);
      }

      this.activityLogsLoading = false;
    },
    onProtectedUsersAction(payload: { action: string; selection: Selection<UserListItem> }) {
      const filters = useFiltersStore();
      const { deselectAllPages } = useSelectorStore();
      const { search } = filters.filters.usersFilters;
      const request = {
        ...api.protectedUsersAction,
        data: {
          action: payload.action,
          selection: getSelectionAsStringsArray(payload.selection, "id"),
          filter: { search },
        },
      };

      axiosInstance
        .request(request)
        .then(() => {
          useSnackbarStore().addGenericSuccess(
            i18n.global.t(`snackbar.messages.users.${payload.action}`)
          );
          this.getItems();
          deselectAllPages();
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getActions(selection: Selection<UserListItem>): Promise<AxiosResponse<{ items: string[] }>> {
      const filters = useFiltersStore();
      const request = {
        ...api.usersListActions,
        data: {
          filters: {
            ...filters.filters.usersFilters,
          },
          selection: {
            ...getSelectionAsStringsArray(selection),
          },
        },
      };

      return axiosInstance.request(request);
    },
  },
});
