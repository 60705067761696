import { i18n } from "@/plugins/i18n";
import moment from "moment/moment";

export const getReportsDefaultDateRangePresets = (): Array<{
  label: string;
  value: [string, string];
}> => [
  {
    label: i18n.global.t("dateRangePicker.lastSevenDays"),
    value: [moment().subtract(7, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
  },
  {
    label: i18n.global.t("dateRangePicker.lastThirtyDays"),
    value: [moment().subtract(30, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
  },
  {
    label: i18n.global.t("dateRangePicker.lastNinetyDays"),
    value: [moment().subtract(90, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
  },
  {
    label: i18n.global.t("dateRangePicker.last365Days"),
    value: [moment().subtract(365, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
  },
];
