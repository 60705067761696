<template>
  <v-skeleton-loader :type="coronetSkeletonLoaderTypes.CARD" :loading="loading">
    <v-card class="pa-4">
      <v-card-text>
        <div class="ml-4 mt-3 mr-4">
          <div v-if="localValue.mobileBannerConfiguration?.visible" class="info-block mb-6 pa-4">
            <div class="d-flex justify-space-between align-start">
              <div class="d-flex align-start flex-grow-1">
                <v-icon icon="$warning" size="24" />
                <div class="d-flex flex-column flex-grow-1 ml-2">
                  <div class="subtitle1" v-text="$t('network.settings.mobileBanner.title')" />
                  <div
                    class="w-60 body2"
                    v-html="$t('network.settings.mobileBanner.description')"
                  ></div>
                  <router-link
                    class="body2 font-weight-bold text-decoration-underline coronet-primary--text"
                    :to="{
                      name: RouteName.USERS_SETTINGS_USERS_TAB,
                      query: { openImportModal: 'true' },
                    }"
                  >
                    {{ $t("network.settings.mobileBanner.importList") }}
                  </router-link>
                </div>
              </div>
              <v-icon size="24" role="button" @click="dismissBanner()" icon="$x" />
            </div>
          </div>
          <v-checkbox
            v-model="localValue.manualDisconnectionEnabled"
            :ripple="false"
            class="mt-1"
            :disabled="actionNotAllowed"
          >
            <template #label>
              <div
                class="subtitle1"
                :class="{ 'text-indigo-medium': actionNotAllowed }"
                v-text="$t('network.settings.manualDisconnectionEnabled')"
              />
            </template>
          </v-checkbox>
          <div
            class="body2 text--semitransparent ml-7"
            v-text="$t('network.settings.manualDisconnectionDescription')"
          />

          <div
            class="body1 mt-4 ml-7"
            :class="{
              'text-indigo-medium':
                actionNotAllowed || isParentSettingDisabled(localValue.manualDisconnectionEnabled),
            }"
            v-text="$t('devicesSettings.settingsCard.applyToDevices')"
          />
          <v-autocomplete
            v-model="localValue.manualDisconnectionLabels"
            class="mt-4 ml-7"
            :items="labels"
            :label="autocompleteLabel(localValue.manualDisconnectionLabels?.length)"
            :placeholder="$t('network.settings.labelsAutocompletePlaceholder')"
            :disabled="
              actionNotAllowed || isParentSettingDisabled(localValue.manualDisconnectionEnabled)
            "
            outlined
            variant="outlined"
            multiple
            return-object
            chips
            closable-chips
            clear-on-select
            item-value="id"
            item-title="name"
          >
            <template v-slot:chip="{ props, item }">
              <v-chip
                v-bind="props"
                :closable="true"
                variant="flat"
                density="comfortable"
                size="default"
                rounded="sm"
                :color="item.raw.color"
                close-icon="$close"
                :text="item.raw.name"
                class="label-chip"
                :disabled="
                  actionNotAllowed || isParentSettingDisabled(localValue.manualDisconnectionEnabled)
                "
                :style="{ 'background-color': item.raw.color }"
              >
                <div class="d-flex align-center">
                  <span class="ml-1 mr-2 body2" v-text="item.raw.name" />
                </div>
              </v-chip>
            </template>
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" title="">
                <template #default>
                  <div class="d-flex">
                    <div
                      class="color-preview mr-2"
                      :style="{ 'background-color': item.raw.color }"
                    />
                    <span v-text="item.raw.name" />
                  </div>
                </template>
              </v-list-item>
            </template>
          </v-autocomplete>

          <v-divider class="mt-8 mb-7" />
          <div
            class="subtitle1 mb-4"
            v-text="$t('network.virtualOffice.encryptionStrength.title')"
          />

          <v-radio-group v-model="localValue.dataCiphers" :disabled="actionNotAllowed">
            <template v-for="encryption in EncryptionStrength" :key="encryption">
              <v-radio
                :value="encryption"
                class="mb-1"
                :label="$t(`network.virtualOffice.encryptionStrength.${encryption}`)"
                @click.native.prevent.stop.capture="onEncryptionStrengthChange(encryption)"
              >
                <template #label>
                  <div
                    class="subtitle1"
                    v-text="$t(`network.virtualOffice.encryptionStrength.${encryption}`)"
                  ></div>
                </template>
              </v-radio>
              <div
                class="body2 text--semitransparent ml-8 mb-3"
                v-text="$t(`network.virtualOffice.encryptionStrength.${encryption}Hint`)"
              />
            </template>
          </v-radio-group>
        </div>
      </v-card-text>
    </v-card>
  </v-skeleton-loader>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, type Ref, ref, watch } from "vue";

import {
  type MobileBannerConfiguration,
  type NetworkSettings,
  useNetworkSettingsStore,
} from "@/_store/network/network-settings.module.ts";

import { EncryptionStrength, NetworkSettingsAction } from "@/constants/network.ts";
import { RouteName } from "@/constants/routes.ts";
import { storeToRefs } from "pinia";
import { useDevicesSettingsStore } from "@/_store/devices-settings.module.ts";
import { type DialogConfig, useDialogsStore } from "@/_store/dialogs.module.ts";
import { useI18n } from "vue-i18n";
import {
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils.ts";
import { RolePermissionsScope } from "@/_store/roles.module.ts";
import { SubscriptionModule } from "@/constants/workplaces.ts";
import { ModalWidth } from "@/constants/modals.ts";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader.ts";

export default defineComponent({
  emits: ["update:localValue"],

  setup() {
    const { t } = useI18n();

    const networkSettingsStore = useNetworkSettingsStore();
    const { initNetworkSettings, updateSettings, updateMobileBannerConfiguration } =
      networkSettingsStore;
    const { settings: networkSettings, loading } = storeToRefs(networkSettingsStore);

    const devicesStore = useDevicesSettingsStore();
    const { getLabels } = devicesStore;
    const { labels } = storeToRefs(devicesStore);

    const { openDialog } = useDialogsStore();

    const localValue: Ref<NetworkSettings> = ref({
      manualDisconnectionEnabled: false,
      manualDisconnectionLabels: [],
      blockLocalNetworkEnabled: false,
      blockLocalNetworkLabels: [],
      dataCiphers: EncryptionStrength.AES256,
      mobileBannerConfiguration: {} as MobileBannerConfiguration,
    });

    onMounted(async () => {
      await Promise.all([await initNetworkSettings(), await getLabels()]);
      localValue.value = { ...networkSettings.value };
    });

    const dismissBanner = () => {
      updateMobileBannerConfiguration({ visible: false });
      localValue.value.mobileBannerConfiguration.visible = false;
    };

    const actionNotAllowed = computed((): boolean => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionModule.NETWORK
      );
    });

    const isParentSettingDisabled = (parentSettingEnabled: boolean): boolean => {
      return !parentSettingEnabled;
    };

    const autocompleteLabel = (labelsLength: number) => {
      return labelsLength ? t("network.settings.labelsAutocompleteLabel") : "";
    };

    const onEncryptionStrengthChange = (dataCiphers: EncryptionStrength) => {
      const config = {
        data: {
          item: {},
          action: NetworkSettingsAction.TOGGLE_ENCRYPTION_STRENGTH,
          callback: () => {
            localValue.value = { ...localValue.value, dataCiphers };
          },
        },
      };
      openModal(config);
    };

    const openModal = (config: Partial<DialogConfig>) => {
      let modalConfig: DialogConfig | undefined;
      const { action, callback, item } = config.data ?? {};

      if (action && callback && item) {
        switch (action) {
          case NetworkSettingsAction.TOGGLE_ENCRYPTION_STRENGTH:
            modalConfig = {
              ...confirmationDialogsConfigConstructor({
                item: {},
                callback,
                disable: actionNotAllowed.value,
                width: ModalWidth.SMALL,
                action,
              }),
              header: {
                title: t(`modals.${action}.title`),
                titleClass: "text-red-dark",
                titleIcon: "warning",
              },
            };
            break;
          default:
            modalConfig = undefined;
        }
      }
      if (modalConfig) {
        openDialog(modalConfig);
      }
    };

    watch(
      localValue,
      async () => {
        await updateSettings(localValue.value);
      },
      { deep: true }
    );

    return {
      EncryptionStrength,
      RouteName,
      onEncryptionStrengthChange,
      loading,
      labels,
      autocompleteLabel,
      localValue,
      dismissBanner,
      actionNotAllowed,
      isParentSettingDisabled,
      coronetSkeletonLoaderTypes,
    };
  },
});
</script>

<style scoped lang="scss"></style>
