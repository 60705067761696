<template>
  <div class="content-md margin-auto content-wrapper">
    <v-skeleton-loader
      class="skeleton-loader--cloud-apps"
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.CLOUD_APPS_SETTINGS"
    >
      <div class="d-flex justify-end mb-4">
        <filter-wrapper
          :show-clear-button="showClearFiltersButton"
          @clear-filters-clicked="clearFilters()"
        >
          <div class="d-flex align-center">
            <v-select
              v-model="localFilters.cloudApplications"
              :items="servicesOptions"
              bg-color="white"
              menu-icon="icon-triangle"
              item-value="id"
              item-title="name"
              :menu-props="{ maxHeight: '300' }"
              class="mr-2"
              min-width="220px"
              :class="{
                'filter-active': localFilters.cloudApplications?.length,
              }"
              :placeholder="$t('thirdPartyApps.filters.cloudApps')"
              :no-data-text="$t('thirdPartyApps.filters.cloudAppsNoData')"
              data-testid="third-party-apps-page-cloud-apps-filter"
              density="compact"
              variant="outlined"
              multiple
              rounded
              hide-details
              background-color="white"
              return-object
            >
              <template #selection="{ index }">
                <span v-if="index === 0" class="body2">{{
                  $t("thirdPartyApps.filters.cloudApps")
                }}</span>
              </template>
              <template #label>
                <span v-if="localFilters.cloudApplications?.length" class="filter-label">
                  {{ localFilters.cloudApplications.length }}
                </span>
              </template>
              <template #item="{ item, props }">
                <v-list-item v-bind="props" :title="$t(`services.${item.title}`)">
                  <template v-slot:prepend="{ isActive }">
                    <v-list-item-action start>
                      <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-select>
            <v-select
              v-model="localFilters.status"
              :items="Object.values(ThirdPartyAppStatus)"
              :menu-props="{ maxHeight: '300' }"
              class="os-version-filter filter-menu mr-2 fit"
              :class="{ 'filter-active': localFilters.status }"
              :placeholder="$t('thirdPartyApps.filters.status')"
              data-testid="third-party-apps-page-status-filter"
              density="compact"
              item-value="name"
              item-title="name"
              rounded
              hide-details
              background-color="white"
            >
              <template #selection="{ item }">
                <span class="body2">
                  {{ $t(`thirdPartyApps.status.${item.title}`) }}
                </span>
              </template>
              <template #item="{ item, props }">
                <v-list-item
                  :data-testid="`third-party-apps-page-status-filter-${item}-item`"
                  v-bind="props"
                  :title="$t(`thirdPartyApps.status.${item.title}`)"
                >
                </v-list-item>
              </template>
            </v-select>
            <v-text-field
              v-model="localFilters.search"
              density="compact"
              variant="outlined"
              background-color="white"
              :placeholder="$t('general.search')"
              :class="{ 'filter-active': localFilters.search?.length }"
              class="search-field"
              prepend-inner-icon="$search"
              hide-details
              clearable
              persistent-clear
              @click:clear="localFilters.search = ''"
              clear-icon="$x"
            />
          </div>
        </filter-wrapper>
      </div>
      <template v-for="alert in cloudServicesPermissions">
        <v-alert
          :key="alert.cloudApplication"
          v-if="alert.cloudApplication && !alert.permitted"
          :closable="true"
          type="warning"
          icon="icon-warning"
          color="yellow-faint"
          class="mb-5 text-yellow-base warning-block"
        >
          <div class="subtitle1 text-yellow-base">
            {{
              $t(`thirdPartyApps.servicePermissionsMissing.title`, {
                service: $t(`services.${alert.cloudApplication}`),
              })
            }}
          </div>
          <div>
            {{
              $t(`thirdPartyApps.servicePermissionsMissing.description`, {
                service: $t(`services.${alert.cloudApplication}`),
              })
            }}
          </div>
        </v-alert>
      </template>
      <v-card>
        <v-progress-linear
          v-if="loading"
          data-testid="table-progress-bar"
          indeterminate
          height="2px"
        ></v-progress-linear>
        <table-wrapper>
          <v-data-table-server
            class="coronet-table--highlightable"
            :items="thirdPartyApps"
            :fixed-header="true"
            :page="pagination.page + 1"
            :show-select="false"
            item-key="sessionId"
            :headers="headers"
            v-model:items-per-page="pagination.pageSize"
            :items-length="total"
            @update:options="
              onPageChange({
                pageSize: $event.itemsPerPage,
                page: $event.page - 1,
              })
            "
          >
            <template #headers="{ columns }">
              <tr>
                <th v-for="header of columns" :key="header.key as string">
                  <span
                    v-if="!header.sortable && header.value !== 'data-table-select'"
                    :data-testid="`table-${header.value}-header`"
                  >
                    {{ header.title }}
                  </span>
                </th>
              </tr>
            </template>

            <template #item="{ item }">
              <tr>
                <td class="w-30">
                  <div class="d-flex align-center">
                    <div class="mr-6">
                      <v-icon size="48" icon="$thirdPartyApp"></v-icon>
                    </div>
                    <div>
                      <span class="subtitle2">{{ item.name }}</span>

                      <div
                        :class="
                          item.status === ThirdPartyAppStatus.ENABLED
                            ? 'text-green-dark'
                            : 'text-red-dark'
                        "
                      >
                        {{ $t(`thirdPartyApps.status.${item.status}`) }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="body2">
                  <div class="d-flex align-center">
                    <coro-icon
                      class="service-icon__sm mr-2"
                      :icon-name="item.cloudApplication"
                    ></coro-icon>
                    {{ $t(`services.${item.cloudApplication}`) }}
                  </div>
                </td>
                <td class="body2">
                  {{ $t("thirdPartyApps.users", { n: item.protectedUsers }) }}
                </td>
                <td class="w-5">
                  <v-btn
                    :color="item.status === ThirdPartyAppStatus.ENABLED ? 'primary' : ''"
                    rounded
                    :variant="item.status === ThirdPartyAppStatus.DISABLED ? 'outlined' : 'flat'"
                    @click="allowBlockApp(item)"
                  >
                    {{
                      $t(
                        `thirdPartyApps.${
                          item.status === ThirdPartyAppStatus.DISABLED ? "allow" : "block"
                        }`
                      )
                    }}
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table-server>
        </table-wrapper>
      </v-card>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { Service, ServiceAction, ThirdPartyAppsAction } from "@/constants/cloud-apps";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";

import TableWrapper from "@/components/TableWrapper.vue";
import CoroIcon from "@/components/CoroIcon.vue";
import { useI18n } from "vue-i18n";
import { useCloudAppsStore } from "@/_store/cloud-apps/cloud-apps.module";
import { storeToRefs } from "pinia";
import { defineComponent, onMounted, ref, watch } from "vue";

import { RouteName } from "@/constants/routes";
import {
  ThirdPartyAppStatus,
  useThirdPartyAppsStore,
} from "@/_store/cloud-apps/third-party-apps.module";
import type { ThirdPartyApp } from "@/_store/cloud-apps/third-party-apps.module";
import type { Pagination } from "@/types";
import FilterWrapper from "@/components/FilterWrapper.vue";
import { useFilters } from "@/composables/useFilters";
import { FilterContext } from "@/_store/filters.module";
import { confirmationDialogsConfigConstructor } from "@/_helpers/utils";
import { ModalWidth } from "@/constants/modals";
import { useDialogsStore } from "@/_store/dialogs.module";
import { useRoute, useRouter } from "vue-router";
import isEqual from "lodash/isEqual";

export default defineComponent({
  components: {
    FilterWrapper,
    TableWrapper,
    CoroIcon,
  },
  setup() {
    const dialogsStore = useDialogsStore();
    const i18n = useI18n();
    const route = useRoute();
    const router = useRouter();

    const thirdPartyAppsStore = useThirdPartyAppsStore();
    const serviceSearch = ref("");
    const { getThirdPartyApps, toggleAppStatus, resetPagination, getCloudServicesPermissions } =
      thirdPartyAppsStore;
    const { thirdPartyApps, total, pagination, loading, cloudServicesPermissions } =
      storeToRefs(thirdPartyAppsStore);

    const { localFilters, filtersUpdating, showClearFiltersButton, clearFilters, updateFilters } =
      useFilters(FilterContext.THIRD_PARTY_APPS);

    const cloudAppsStore = useCloudAppsStore();
    const { connectedServices, showSkeletonLoader } = storeToRefs(cloudAppsStore);
    const servicesOptions = [Service.G_SUITE, Service.OFFICE_365];

    const onPageChange = async (pageChange: Pagination) => {
      if (!isEqual(pageChange, pagination.value)) {
        thirdPartyAppsStore.$patch({ pagination: pageChange });
        await getThirdPartyApps(false);
      }
    };

    watch(
      filtersUpdating,
      async (value) => {
        if (value) {
          resetPagination();
          await thirdPartyAppsStore.getThirdPartyApps(false);
        }
      },
      { deep: true, immediate: true }
    );

    onMounted(async () => {
      const cloudApplicationsParam = route.query.cloudApplications;

      await getCloudServicesPermissions();
      if (cloudApplicationsParam && servicesOptions.includes(cloudApplicationsParam as Service)) {
        updateFilters({
          ...{ ...localFilters.value },
          cloudApplications: [cloudApplicationsParam as Service],
        });
      } else {
        await getThirdPartyApps(false);
      }

      router.replace({ query: {} });
    });

    const allowBlockApp = async (app: ThirdPartyApp) => {
      if (app.status === ThirdPartyAppStatus.DISABLED) {
        await toggleAppStatus(app, ThirdPartyAppStatus.ENABLED);
      } else {
        dialogsStore.openDialog({
          ...confirmationDialogsConfigConstructor({
            action: ThirdPartyAppsAction.BLOCK_APP,
            callback: async () => await toggleAppStatus(app, ThirdPartyAppStatus.DISABLED),
            item: { ...app, cloudAppName: i18n.t(`services.${app.cloudApplication}`) },
            width: ModalWidth.SMALL,
          }),
          scrollable: true,
        });
      }
    };

    return {
      ServiceAction,
      coronetSkeletonLoaderTypes,
      connectedServices,
      allowBlockApp,
      servicesOptions,
      showSkeletonLoader,
      RouteName,
      onPageChange,
      localFilters,
      pagination,
      ThirdPartyAppStatus,
      Service,
      loading,
      total,
      showClearFiltersButton,
      cloudServicesPermissions,
      clearFilters,
      thirdPartyApps,
      serviceSearch,
      headers: [
        {
          title: i18n.t("thirdPartyApps.columns.thirdPartyApp"),
          key: "name",
          sortable: false,
        },
        {
          title: i18n.t("thirdPartyApps.columns.cloudApplication"),
          key: "plan",
          sortable: false,
        },
        {
          title: i18n.t("thirdPartyApps.columns.protectedUsers"),
          key: "createdOn",
          sortable: false,
        },
        {},
      ],
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
tr {
  height: 76px;
}

thead {
  tr {
    height: inherit;
  }
}

.company-logo {
  height: 32px;
  width: 32px;
}

.filter-menu {
  width: 140px;
}

.service-icon {
  height: 40px;
  width: 40px;

  &__sm {
    height: 24px;
    width: 24px;
  }
}
</style>
