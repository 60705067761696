<template>
  <div class="activity-logs-page content-md margin-auto">
    <v-breadcrumbs :items="breadcrumbs" class="mt-3" />
    <v-skeleton-loader
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.USERS_SETTINGS"
      class="skeleton-loader--table"
    >
      <div class="settings-header-with-btn">
        <div class="d-flex align-center">
          <span class="headline5">
            {{ $t("activityLogs.title") }}
          </span>
          <v-chip class="ml-2 chip--counter">
            {{ total }}
          </v-chip>
        </div>
        <div @click="exportCsv()" class="coro-link item-clickable d-flex align-center">
          <v-icon icon="$export" />
          <span>{{ $t("general.exportCsv") }}</span>
        </div>
      </div>

      <div class="d-flex justify-end mb-5">
        <div class="w-70">
          <!-- menu-icon is empty because an icon that is not rendered still takes some space -->
          <filter-wrapper
            :show-clear-button="showClearFiltersButton"
            @clear-filters-clicked="clearFilters()"
          >
            <v-select
              v-model="localFilters.types"
              :items="activityLogTypes"
              bg-color="white"
              menu-icon="icon-triangle"
              :menu-props="{ maxHeight: '300' }"
              class="types-filter filter-menu mr-2"
              :class="{ 'filter-active': localFilters.types?.length }"
              :placeholder="$t('activityLogs.filters.types')"
              data-testid="devices-page-label-filter"
              density="compact"
              variant="outlined"
              multiple
              rounded
              hide-details
              background-color="white"
            >
              <template #selection="{ index }">
                <span v-if="index === 0" class="body2">{{ $t("activityLogs.filters.types") }}</span>
              </template>
              <template #label>
                <span v-if="localFilters.types?.length" class="filter-label">
                  {{ localFilters.types.length }}
                </span>
              </template>
              <template #item="{ item, props }">
                <v-list-subheader v-if="item.raw.type === 'subheader'" :title="item.title" />
                <v-list-item v-else v-bind="props" title="">
                  <template v-slot:prepend="{ isActive }">
                    <v-list-item-action start>
                      <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                    </v-list-item-action>
                  </template>

                  <v-list-item-title
                    :data-testid="`devices-page-label-filter-${item}-item`"
                    title=""
                  >
                    {{ $t(`activityLogs.filters.type.${item.title}`) }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
            <date-range-picker
              v-model="localFilters.timeFrame"
              :preset-dates="presets"
              :placeholder="$t('activityLogs.filters.dateRange')"
              class="mr-2 range-picker"
            ></date-range-picker>
            <v-combobox
              v-model="localFilters.search"
              variant="outlined"
              clearable
              clear-icon="icon-x"
              background-color="white"
              :class="{ 'filter-active': localFilters.search && localFilters.search.length > 0 }"
              :items="suggestions"
              :placeholder="$t('general.search')"
              class="search-field search-filter"
              prepend-inner-icon="icon-search"
              menu-icon=""
              hide-details
              density="compact"
              rounded
              bg-color="white"
              data-testid="tickets-page-search-field"
            >
            </v-combobox>
          </filter-wrapper>
        </div>
      </div>

      <activity-logs-table
        :items="items"
        :total="total"
        :loading="loading"
        :pagination="pagination"
        :showPagination="true"
        @pagination="handlePaginationChange($event)"
        @undo-action="onUndoAction($event)"
      />
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useActivityLogsStore } from "@/_store/activity-logs.module";
import { storeToRefs } from "pinia";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import ActivityLogsTable from "@/components/tables/ActivityLogsTable.vue";
import type { Pagination } from "@/types";
import { SearchSuggestion } from "@/constants/general";
import { FilterContext } from "@/_store/filters.module";
import { useFilters } from "@/composables/useFilters";
import { useRoute, useRouter } from "vue-router";
import FilterWrapper from "@/components/FilterWrapper.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import { ActivityLogsType } from "@/constants/activity-logs";
import moment from "moment";

interface ActivityLogsTypeSelectItem {
  type?: "subheader";
  title: ActivityLogsType | string;
  value?: ActivityLogsType;
}

export default defineComponent({
  components: {
    FilterWrapper,
    ActivityLogsTable,
    DateRangePicker,
  },
  setup() {
    const i18n = useI18n();
    const presets = computed(() => [
      {
        label: i18n.t("dateRangePicker.today"),
        value: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
      {
        label: i18n.t("dateRangePicker.yesterday"),
        value: [
          moment().subtract(1, "days").format("YYYY-MM-DD"),
          moment().subtract(1, "days").format("YYYY-MM-DD"),
        ],
      },
      {
        label: i18n.t("dateRangePicker.lastSevenDays"),
        value: [moment().subtract(7, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
      {
        label: i18n.t("dateRangePicker.lastThirtyDays"),
        value: [moment().subtract(30, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
      {
        label: i18n.t("dateRangePicker.lastNinetyDays"),
        value: [moment().subtract(90, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
    ]);

    const activityLogsStore = useActivityLogsStore();
    const route = useRoute();
    const router = useRouter();
    const { setPagination, resetPagination, getActivityLogs, undoAction, exportCsv } =
      activityLogsStore;
    const { showSkeletonLoader, loading, total, items, pagination } =
      storeToRefs(activityLogsStore);
    const { localFilters, filtersUpdating, showClearFiltersButton, clearFilters } = useFilters(
      FilterContext.ACTIVITY_LOGS
    );

    const handlePaginationChange = async (pagination: Pagination) => {
      setPagination(pagination);
      await getActivityLogs(false);
    };

    const breadcrumbs = [
      {
        title: i18n.t("general.backToControlPanel"),
        disabled: false,
        to: { path: "/portal/settings" },
      },
    ];

    watch(
      filtersUpdating,
      async (val) => {
        if (val) {
          resetPagination();
          await getActivityLogs(false);
        }
      },
      { deep: true }
    );

    onMounted(async () => {
      localFilters.value.search = (route.query.search as string) ?? localFilters.value.search;
      await router.replace({ query: undefined });
    });

    const suggestions = [
      SearchSuggestion.TICKET_ID,
      SearchSuggestion.USER,
      SearchSuggestion.ENROLLMENT_CODE,
    ];

    const onUndoAction = async (val: string) => {
      await undoAction(val);
    };

    const getActivityLogsSelectValues = () => {
      const activityLogsValues = Object.values(ActivityLogsType);
      const activityLogTypes: ActivityLogsTypeSelectItem[] = [
        ...activityLogsValues
          .filter((val) => val !== ActivityLogsType.SIEM_CONFIGS)
          .map((val) => ({ title: val, value: val })),
      ];

      const mdmActionIndex = activityLogsValues.indexOf(ActivityLogsType.MDM_DEVICE);
      activityLogTypes.splice(mdmActionIndex, 0, {
        type: "subheader",
        title: i18n.t("activityLogs.filters.type.mdm"),
      });

      return activityLogTypes;
    };

    return {
      breadcrumbs,
      showSkeletonLoader,
      loading,
      coronetSkeletonLoaderTypes,
      total,
      items,
      pagination,
      handlePaginationChange,
      onUndoAction,
      getActivityLogs,
      suggestions,
      localFilters,
      showClearFiltersButton,
      clearFilters,
      activityLogTypes: getActivityLogsSelectValues(),
      presets,
      exportCsv,
    };
  },
});
</script>

<style lang="scss" scoped>
.types-filter {
  width: 150px !important;
}
.range-picker {
  max-width: 240px;
}
.search-field {
  width: 400px;
}

.icon-export:before {
  font-size: 16px;
  color: rgb(var(--v-theme-anchor-base)) !important;
}
</style>
