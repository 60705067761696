<template>
  <v-form ref="form" v-model="valid" @submit.prevent>
    <div class="subtitle2 mb-4">
      {{ $t("modals.addSecurityAwarenessConfiguration.sectionsTitles.details") }}
    </div>
    <v-text-field
      v-model="localValue.name"
      :rules="[required()]"
      :label="$t('modals.addSecurityAwarenessConfiguration.labels.name')"
    />
    <div class="subtitle2 mb-4">
      {{ $t("modals.addSecurityAwarenessConfiguration.sectionsTitles.emailAndDomain") }}
    </div>
    <v-combobox
      ref="combobox"
      v-model="localValue.terms"
      v-model:search="currentInputValue"
      class="mt-4 mb-4 coro-scrollable-combobox"
      :label="$t('modals.addSecurityAwarenessConfiguration.labels.emailAndDomain')"
      :placeholder="$t('modals.allowBlockListSwg.urlPlaceholder')"
      hide-details="auto"
      variant="outlined"
      :rules="[termsValidator()]"
      multiple
      chips
      no-filter
      hide-no-data
      @blur="handleBlur"
    >
      <template #chip="{ props, item }">
        <v-chip
          v-bind="props"
          variant="flat"
          color="indigo-faint"
          closable
          close-icon="$closeCircle"
        >
          <div class="d-flex align-center justify-space-between">
            <span class="ml-1 mr-2" v-text="item.raw" />
          </div>
        </v-chip>
      </template>
    </v-combobox>
    <div class="subtitle2 mb-4">
      {{ $t("modals.addSecurityAwarenessConfiguration.sectionsTitles.emailHeader") }}
    </div>
    <key-value-input
      class="mt-10 mb-4"
      v-model:value="headers"
      :key-label="$t('modals.addSecurityAwarenessConfiguration.labels.name')"
      :value-label="$t('modals.addSecurityAwarenessConfiguration.labels.value')"
      :show-add-btn="false"
      :key-config="{
        cols: 6,
        field: $t('modals.addSecurityAwarenessConfiguration.labels.name').toLowerCase(),
        md: 6,
        rules: [headersKeyValidator()],
      }"
      :value-config="{
        cols: 6,
        md: 6,
        rules: [headersValueValidator()],
      }"
      :add-btn-label="$t('modals.addSiemConnector.addBtn')"
    >
    </key-value-input>
    <span class="coro-link" @click="onAddNewHeader">{{
      $t("modals.addSecurityAwarenessConfiguration.addHeaderBtn")
    }}</span>
  </v-form>
</template>
<script lang="ts">
import { computed, defineComponent, type PropType, type Ref, ref, watch } from "vue";
import { hasSeparator } from "@/_helpers/utils";
import { required } from "@/_helpers/validators";
import KeyValueInput from "@/components/KeyValueInput.vue";
import Patterns from "@/constants/patterns";
import { useI18n } from "vue-i18n";
import type { DialogDataConfig } from "@/_store/dialogs.module";

export interface SecurityAwarenessHeader {
  name: string;
  value: string;
}

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig>,
      default: () => ({}),
    },
  },
  components: { KeyValueInput },
  emits: ["update:localValue", "update:valid"],
  setup(props, { emit }) {
    const localValue: Ref<{
      name: string;
      terms: string[];
      headers?: { name: string; value: string }[] | null;
    }> = ref(
      props.config.item
        ? { ...props.config.item }
        : {
            name: "",
            terms: [],
          }
    );
    const headers = ref<SecurityAwarenessHeader[]>(
      localValue.value.headers ? [...localValue.value.headers] : []
    );
    const currentInputValue = ref("");
    const valid = ref(true);
    const form = ref();
    const { t } = useI18n();

    const handleBlur = () => {
      if (!currentInputValue.value) return;
      const hasCommaSeparator = hasSeparator(currentInputValue.value);
      if (hasCommaSeparator) {
        currentInputValue.value = "";
      }
    };

    const isTermValid = (term: string) => {
      return [Patterns.IP_WITH_OPTIONAL_CIDR, Patterns.DOMAIN].some((pattern) =>
        pattern.test(term)
      );
    };

    const termsValidator = () => {
      return (values: string[]) => {
        const areTermsValid = values.every(isTermValid);
        if (values.length > 0 && !areTermsValid) {
          return t("validations.domainOrIp");
        }
        if (!headerKeyOrTermsValidationRule.value) {
          return t(
            "modals.addSecurityAwarenessConfiguration.validations.atLeastOneHeaderOrTermIsRequired"
          );
        }
        return true;
      };
    };

    const headersKeyValidator = () => {
      return () => {
        if (!headerKeyOrTermsValidationRule.value) {
          return t(
            "modals.addSecurityAwarenessConfiguration.validations.atLeastOneHeaderOrTermIsRequired"
          );
        }
        if (!headerNameValidationRule.value) {
          return t("validations.required");
        }
        return true;
      };
    };

    const headersValueValidator = () => {
      return (value: string) => {
        if (value) return true;
        if (!headersValueValidationRule.value) {
          return t("validations.required");
        }
        return true;
      };
    };

    const validHeader = (header: SecurityAwarenessHeader) => {
      return Object.values(header).some((val) => val);
    };

    const validate = async () => {
      const { valid } = await form.value.validate();
      return valid;
    };

    const onAddNewHeader = () => {
      headers.value.push({
        name: "",
        value: "",
      });
    };

    const headerKeyOrTermsValidationRule = computed(() => {
      const areTermsValid = localValue.value.terms.some(isTermValid);
      const areHeadersValid = localValue.value.headers
        ? localValue.value.headers.some(validHeader)
        : false;
      return areTermsValid || areHeadersValid;
    });

    const headersValueValidationRule = computed(() => {
      return localValue.value.headers
        ? localValue.value.headers.every((header: SecurityAwarenessHeader) => header.value)
        : true;
    });

    const headerNameValidationRule = computed(() => {
      return localValue.value.headers
        ? localValue.value.headers.every((header: SecurityAwarenessHeader) => header.name)
        : true;
    });

    watch(
      localValue,
      () => {
        emit("update:localValue", localValue.value);
      },
      { deep: true, immediate: true }
    );

    watch(
      () => [
        headerKeyOrTermsValidationRule.value,
        headersValueValidationRule.value,
        headerNameValidationRule.value,
      ],
      async () => {
        await form.value.validate();
      }
    );

    watch(
      headers,
      () => {
        const validHeaders = headers.value.filter((header) => validHeader(header));
        if (validHeaders.length) {
          localValue.value.headers = [...validHeaders];
        } else {
          localValue.value.headers = null;
        }
      },
      { deep: true }
    );

    watch(
      valid,
      (newVal: boolean) => {
        if (newVal === null) return emit("update:valid", true);
        emit("update:valid", newVal && headerKeyOrTermsValidationRule.value);
      },
      { immediate: true }
    );

    return {
      onAddNewHeader,
      handleBlur,
      required,
      localValue,
      valid,
      currentInputValue,
      termsValidator,
      headersKeyValidator,
      headers,
      form,
      validate,
      headerKeyOrTermsValidationRule,
      headersValueValidator,
    };
  },
});
</script>
