<template>
  <table-wrapper>
    <div class="coronet-table--loader">
      <v-progress-linear
        v-if="loading"
        data-testid="table-progress-bar"
        indeterminate
        height="2px"
      ></v-progress-linear>
    </div>
    <v-data-table-server
      class="coronet-table--highlightable"
      :items="items"
      :headers="headers"
      :items-per-page="pagination.pageSize"
      :items-length="totalItems"
      item-value="userId"
      :show-expand="true"
      v-model:expanded="expanded"
      @update:options="
        $emit('page-changed', {
          pageSize: $event.itemsPerPage,
          page: $event.page - 1,
        })
      "
    >
      <template #headers>
        <tr>
          <th></th>
          <th v-for="header of headers" :key="header.key as string">
            <span :data-testid="`table-${header.key}-header`">
              {{ $t(`usersSettings.usersTab.tableHeaders.${header.key}`) }}
            </span>
          </th>
          <th class="checkbox-col text-center">
            <users-sync-btn :disabled="actionNotAllowed" />
          </th>
        </tr>
      </template>
      <!--Slot for rows-->
      <template #item="{ item, index, toggleExpand, isExpanded, internalItem }">
        <tr
          :key="item.userId"
          :id="`${index}`"
          :data-testid="`table-row-${index}`"
          class="service-row"
        >
          <td class="w-5">
            <v-icon
              v-if="item.connectedCloudApplications?.length > 1"
              class="mr-2 cursor-pointer"
              icon="$dropdown"
              color="primary"
              :class="{ rotated: isExpanded(internalItem) }"
              @click="toggleExpand(internalItem)"
            />
          </td>
          <td :data-testid="`users-table-user-col-row-${index}`">
            <div>
              <b
                :class="{
                  strikethrough: getIsExcepted(item),
                }"
                >{{ item.email }}</b
              >
              <span v-if="getIsExcepted(item)">
                {{ $t(`usersSettings.usersTab.excluded`) }}
              </span>
            </div>
            <email-aliases :aliases="item.aliases" />
          </td>
          <td :data-testid="`users-table-joined-via-col-row-${index}`">
            <!-- Joined via INDIVIDUALLY, CSV, ADMIN -->
            {{ getLabelForMultipleJoinedVia(item) }}
            <!-- Joined via GROUP -->
            <div
              class="d-flex align-center"
              v-if="item.joinedVia.includes(UserSettingsJoinedVia.GROUP)"
            >
              <coro-icon
                class="service-icon"
                :class="{
                  'service-icon-excepted': item.exceptedUser,
                }"
                :icon-name="item.groups[0]?.service"
              ></coro-icon>
              <span class="ml-1 mr-2">
                {{ item.groups[0]?.name }}
                <coro-popover
                  :items="item.groups"
                  :title="$t('usersSettings.usersTab.groups')"
                  :threshold="1"
                >
                  <template #custom-activator="{ items }">
                    {{ `+ ${items.length} ${$t("general.group", items.length)}` }}
                  </template>
                  <template #content="{ items }">
                    <div
                      v-for="group of items"
                      :key="group.name"
                      class="d-flex align-center text-primary"
                    >
                      <coro-icon class="service-icon" :icon-name="group.service"></coro-icon>
                      {{ group.name }}
                    </div>
                  </template>
                </coro-popover>
              </span>
            </div>
            <!-- Joined via INBOUND_GATEWAY -->
            <div v-if="item.joinedVia.includes(UserSettingsJoinedVia.INBOUND_GATEWAY)">
              <v-icon
                size="30"
                icon="$globe"
                :class="{
                  'service-icon-excepted': item.exceptedUser,
                }"
              />
              <span class="ml-1 mr-2">
                {{ getDomainFromEmail(item.email) }}
              </span>
            </div>
          </td>

          <td :data-testid="`users-table-protected-by-col-row-${index}`">
            <div class="d-flex align-center" v-if="item.connectedCloudApplications">
              <template
                v-for="protectionService in item.connectedCloudApplications.slice(
                  0,
                  MAX_ICONS_SHOWN
                )"
                :key="protectionService.appName"
              >
                <coro-icon
                  class="service-icon"
                  v-if="protectionService.appName !== Service.PROXY || protectionService.active"
                  :icon-name="protectionService.appName"
                ></coro-icon>
              </template>
              <v-tooltip open-delay="300" location="top">
                <template #activator="{ props }">
                  <span
                    v-if="item.connectedCloudApplications.length > MAX_ICONS_SHOWN"
                    v-bind="props"
                  >
                    {{ `+${item.connectedCloudApplications.length - MAX_ICONS_SHOWN}` }}
                  </span>
                </template>
                <div>
                  {{ getConnectedAppsTooltipData(item.connectedCloudApplications) }}
                </div>
              </v-tooltip>
            </div>
          </td>

          <td class="w-10">
            <div v-if="item.connectedCloudApplications?.length < 2">
              <span
                v-if="item.connectedCloudApplications[0]"
                :class="getStatusColor(item.connectedCloudApplications[0]?.active)"
              >
                {{
                  $t(
                    `usersSettings.usersTab.${
                      item.connectedCloudApplications[0]?.active
                        ? UserSettingsAppStatus.ACTIVE
                        : UserSettingsAppStatus.INACTIVE
                    }`
                  )
                }}
              </span>
            </div>
          </td>

          <td :data-testid="`users-table-protection-granted-col-row-${index}`">
            {{ getFormattedDateTime(item.protectionGrantedTime, "ddd, MMM D YYYY, hh:mm A") }}
          </td>
          <td @click="$event.stopPropagation()" class="w-5">
            <v-menu v-if="showAllActions(item)" bottom left>
              <template #activator="{ props }">
                <!--hiding toggler for non-protectable users-->
                <v-btn
                  dark
                  icon
                  :data-testid="`users-table-action-btn-row-${index}`"
                  v-bind="props"
                >
                  <v-icon icon="$dots" />
                </v-btn>
              </template>

              <v-list>
                <template v-for="userAction in getActions(item)" :key="userAction">
                  <v-list-item @click="handleItemAction(userAction, item)">
                    <v-list-item-title :data-testid="`users-table-${userAction}-action`">
                      {{ $t(`usersSettings.usersTab.userActions.${userAction}`, 1) }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
      <template #expanded-row="{ item }">
        <tr
          v-for="protectionService in item.connectedCloudApplications"
          :key="protectionService.appName"
        >
          <td colspan="3"></td>
          <td>
            <coro-icon
              class="service-icon"
              :key="protectionService.appName"
              :icon-name="protectionService.appName"
            ></coro-icon>
          </td>
          <td>
            <div>
              <span :class="getStatusColor(protectionService.active)">
                {{
                  $t(`usersSettings.usersTab.${protectionService.active ? "active" : "inactive"}`)
                }}
              </span>
            </div>
          </td>
          <td colspan="2"></td>
        </tr>
      </template>

      <template #no-data>
        <v-icon class="mt-3 mb-3" icon="$users" size="80" />
        <div class="headline5 mb-3">
          {{ $t("usersSettings.usersTab.noUsers") }}
        </div>
        <a
          data-testid="users-table-add-users-btn"
          class="coro-link body1"
          @click="handleItemAction(userActions.ADD_USERS)"
          >{{ $t("usersSettings.addUsersBtn") }}</a
        >
      </template>
    </v-data-table-server>
  </table-wrapper>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType, ref } from "vue";
import CoroIcon from "@/components/CoroIcon.vue";
import TableWrapper from "@/components/TableWrapper.vue";
import UsersSyncBtn from "@/components/UsersSyncBtn.vue";
import EmailAliases from "@/components/EmailAliases.vue";

import {
  getDomainFromEmail,
  getFormattedDateTime,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { UserAction } from "@/constants/users";
import { CoroIcons } from "@/constants/coro-icon";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import {
  UserSettingsAppStatus,
  UserSettingsJoinedVia,
  type UserSettingsListItem,
  type UserSettingsProtectedApp,
} from "@/_store/users-settings/users-tab.module";
import type { Pagination } from "@/types";
import { useI18n } from "vue-i18n";
import CoroPopover from "@/components/CoroPopover.vue";
import { Service } from "@/constants/cloud-apps.ts";

export default defineComponent({
  components: {
    CoroIcon,
    CoroPopover,
    TableWrapper,
    UsersSyncBtn,
    EmailAliases,
  },
  props: {
    items: {
      type: Array as PropType<UserSettingsListItem[]>,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      default: () => {
        return {
          page: 0,
          pageSize: 15,
        };
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["item-action-applied", "page-changed"],
  setup(props, { emit }) {
    const MAX_ICONS_SHOWN = 3;

    const i18n = useI18n();
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.USERS
      );
    });

    const getActions = (item: UserSettingsListItem) => {
      const actions = [];
      if (!item.isAdmin) {
        actions.push(UserAction.REMOVE_USERS);
      }
      if (!item?.exceptedUser) {
        actions.push(UserAction.ADD_TO_EXCEPTION);
      } else {
        actions.push(UserAction.REMOVE_EXCEPTION);
      }
      return actions;
    };

    const getIsExcepted = (item: UserSettingsListItem) => {
      return item.exceptedUser;
    };

    const showAllActions = (item: UserSettingsListItem) => {
      return !!getActions(item)?.length;
    };

    const handleItemAction = (action: string, item?: UserSettingsListItem) => {
      emit("item-action-applied", {
        action,
        item,
      });
    };

    const expanded = ref<string[]>([]);

    const getStatusColor = (status: boolean) => {
      return status ? "text-green-dark" : "text-indigo-medium";
    };

    const getConnectedAppsTooltipData = (connectedApps: UserSettingsProtectedApp[]): string => {
      const itemsToShowTooltipFor = connectedApps.slice(MAX_ICONS_SHOWN, connectedApps.length);
      return itemsToShowTooltipFor.map(({ appName }) => i18n.t(`services.${appName}`)).join(", ");
    };

    const getLabelForMultipleJoinedVia = (item: UserSettingsListItem) => {
      const result = [];

      if (item.joinedVia.includes(UserSettingsJoinedVia.CSV)) {
        result.push(i18n.t("usersSettings.usersTab.addedViaCsv"));
      }

      if (item.joinedVia.includes(UserSettingsJoinedVia.INDIVIDUALLY)) {
        result.push(i18n.t("usersSettings.usersTab.directMember"));
      }

      if (item.joinedVia.includes(UserSettingsJoinedVia.ADMIN)) {
        result.push(i18n.t("general.admin"));
      }

      // If we have several comma separated items - ensure only first one is upper case
      return result.map((item, index) => (index > 0 ? item.toLowerCase() : item)).join(", ");
    };

    return {
      MAX_ICONS_SHOWN,
      getConnectedAppsTooltipData,
      getStatusColor,
      getLabelForMultipleJoinedVia,
      userActions: UserAction,
      UserSettingsAppStatus,
      coronetIcons: CoroIcons,
      Service,
      headers: [
        {
          key: "user",
          sortable: false,
        },
        {
          key: "joinedVia",
          sortable: false,
        },
        {
          key: "appsProtected",
          sortable: false,
        },
        {
          key: "status",
          sortable: false,
        },
        {
          key: "protectionGranted",
          sortable: false,
        },
      ],
      actionNotAllowed,
      getIsExcepted,
      getActions,
      showAllActions,
      handleItemAction,
      getFormattedDateTime,
      expanded,
      UserSettingsJoinedVia,
      getDomainFromEmail,
    };
  },
});
</script>

<style scoped lang="scss">
.strikethrough {
  text-decoration: line-through;
}
.service-icon-excepted {
  filter: grayscale(1) saturate(0%);
}
.service-icon {
  height: 30px;
  width: 30px;
}
</style>
