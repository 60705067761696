import { camelCase, get, escape } from "lodash";
import type {
  Location,
  MalwareMetaData,
  ProcessGroup,
  TicketDetails,
} from "@/_store/tickets/tickets.module";
import { TicketsModal, TicketTrigger, TicketType, ViolationDirection } from "@/constants/tickets";
import { convertSize, getFormattedDateTime } from "@/_helpers/utils";
import { i18n } from "@/plugins/i18n";

export interface TicketKeyDetail<T extends object = any> {
  value: string | string[] | Array<T & { key: keyof T; icon?: string }> | undefined;
  titleKey: string;
  ticketModal?: TicketsModal;
  loopWithIcon?: boolean;
  showItemsNumber?: number;
}
/**
 *
 * @param ticket
 * @returns {{value: *, titleKey: string}[]}
 */
export const mapKeyDetails = (ticket: TicketDetails): TicketKeyDetail[] => {
  if (!ticket) return [];
  if (ticket.eventType === TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_EMAIL) {
    return mapSuspectedDataComplianceViolationByEmailDlp(ticket);
  }
  if (ticket.eventType === TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_DEVICE) {
    return mapDeviceDlp(ticket);
  }
  if (ticket.eventType === TicketType.SUSPECTED_DATA_EXPOSURE_BY_EMAIL) {
    return mapSuspectedExposureOfCriticalDataByEmail(ticket);
  }
  if (ticket.eventType === TicketType.WIFI_FORBIDDEN_NETWORK) {
    return mapWifiForbiddenNetwork(ticket);
  }

  if (ticket.eventType === TicketType.SIMULATED_EMAIL_PHISHING) {
    return mapSimulatedEmailPhishing(ticket);
  }

  if (ticket.eventType === TicketType.SECURITY_TRAINING_FAILURE) {
    return mapSecurityTrainingFailure(ticket);
  }

  if (
    [
      TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_SHARING,
      TicketType.SUSPECTED_DATA_EXPOSURE_BY_SHARING,
    ].includes(ticket.eventType)
  ) {
    return mapExposureBySharing(ticket);
  }
  if (
    [TicketType.ENDPOINT_VULNERABILITY, TicketType.MALWARE_IN_DEVICE].includes(ticket.eventType)
  ) {
    switch (ticket.eventTrigger) {
      case TicketTrigger.MALWARE_ON_ENDPOINT:
        return [...mapMalwareOnEndpoint(ticket), ...mapDeviceGenericDetails(ticket)];
      case TicketTrigger.INFECTED_PROCESS:
        return [...mapInfectedProcess(ticket), ...mapDeviceGenericDetails(ticket)];
      case TicketTrigger.VSS_BACKUP_PROTECTION:
        return [...mapVSSBackupProtection(ticket), ...mapDeviceGenericDetails(ticket)];
      case TicketTrigger.ENCRYPTION_DISABLED:
        return [...mapUnencryptedDrive(ticket), ...mapDeviceGenericDetails(ticket)];
      default:
        return mapDeviceGenericDetails(ticket);
    }
  }
  switch (ticket.eventTrigger) {
    case TicketTrigger.MALWARE_IN_CLOUD_DRIVE:
      return mapMalwareInCloudDrive(ticket);
    case TicketTrigger.ACCESS_PERMISSIONS_VIOLATION:
    case TicketTrigger.SUSPECTED_IDENTITY_COMPROMISE:
    case TicketTrigger.ABNORMAL_ADMIN_ACTIVITY:
      return mapIpAddresses(ticket);
    case TicketTrigger.MALWARE_IN_EMAIL_ATTACHMENTS:
      return mapMalwareInEmail(ticket);
    case TicketTrigger.EMAIL_PHISHING:
    case TicketTrigger.MISSING_REQUIRED_AUTHENTICATION:
    case TicketTrigger.BLOCKLISTED_SENDER:
    case TicketTrigger.CROWDBLOCKED_SENDER:
    case TicketTrigger.USER_IMPERSONATION:
    case TicketTrigger.DOMAIN_IMPERSONATION:
    case TicketTrigger.BRAND_IMPERSONATION:
    case TicketTrigger.SUSPICIOUS_CONTENT:
    case TicketTrigger.SPAM:
    case TicketTrigger.FORBIDDEN_ATTACHMENT_TYPE:
    case TicketTrigger.DOMAIN_SPOOFING:
    case TicketTrigger.REPORTED_BY_USER:
    case TicketTrigger.SUSPICIOUS_METADATA:
      return mapEmailPhishing(ticket);
    case TicketTrigger.WIFI_PHISHING:
      return mapWifiPhishing(ticket);
    case TicketTrigger.ABNORMAL_DATA_MANIPULATION:
    case TicketTrigger.MASS_DOWNLOAD:
    case TicketTrigger.MASS_DELETE:
      return mapAbnormalDataManipulation(ticket);
    case TicketTrigger.SUSPECTED_BOT_ATTACKS:
      return mapSuspectedBotAttacks(ticket);
    default:
      return [];
  }
};

function mapSuspectedDataComplianceViolationByEmailDlp(ticket: TicketDetails): TicketKeyDetail[] {
  const emailMetaData = ticket.sections.emailMetaData;
  // for old dlp events the default direction was SHARE
  const violationDirection = get(
    ticket,
    "sections.userDataAccessViolation.violationDirection",
    ViolationDirection.SHARE
  );
  const senderAndRecipient = [
    {
      titleKey: i18n.global.t("ticketDetails.senderName"),
      value: escape(emailMetaData.senderName),
    },
    {
      titleKey: i18n.global.t("ticketDetails.senderEmail"),
      value: escape(emailMetaData.senderEmail),
    },
    {
      titleKey: i18n.global.t("ticketDetails.senderIp"),
      value: escape(emailMetaData.senderIp),
    },
    {
      titleKey: i18n.global.t("ticketDetails.recipients"),
      value: emailMetaData.recipients,
      ticketModal: TicketsModal.RECIPIENTS,
    },
  ];
  const recipient = [
    {
      titleKey: i18n.global.t("ticketDetails.recipients"),
      value: emailMetaData.recipients,
      ticketModal: TicketsModal.RECIPIENTS,
    },
  ];
  const senderOrRecipient =
    violationDirection === ViolationDirection.ACCESS ? senderAndRecipient : recipient;

  return [
    ...senderOrRecipient,
    {
      titleKey: i18n.global.t("ticketDetails.subject"),
      value: escape(emailMetaData.subject),
    },
    {
      titleKey: i18n.global.t("ticketDetails.fileName"),
      value: emailMetaData.attachment?.fileName,
    },
    {
      titleKey: i18n.global.t("ticketDetails.fileSize"),
      value: convertSize(emailMetaData.attachment?.fileSize!, 1),
    },
    {
      titleKey: i18n.global.t("ticketDetails.internetMessageId"),
      value: escape(emailMetaData.internetMessageId),
    },
  ];
}

function mapSuspectedExposureOfCriticalDataByEmail(ticket: TicketDetails): TicketKeyDetail[] {
  const emailMetaData = ticket.sections.emailMetaData;
  // for old dlp events the default direction was SHARE
  const violationDirection = get(
    ticket,
    "sections.userDataAccessViolation.violationDirection",
    ViolationDirection.SHARE
  );
  const senderAndRecipient = [
    {
      titleKey: i18n.global.t("ticketDetails.senderName"),
      value: escape(emailMetaData.senderName),
    },
    {
      titleKey: i18n.global.t("ticketDetails.senderEmail"),
      value: escape(emailMetaData.senderEmail),
    },
    {
      titleKey: i18n.global.t("ticketDetails.senderIp"),
      value: escape(emailMetaData.senderIp),
    },
    {
      titleKey: i18n.global.t("ticketDetails.recipients"),
      value: emailMetaData.recipients,
      ticketModal: TicketsModal.RECIPIENTS,
    },
  ];
  const recipient = [
    {
      titleKey: i18n.global.t("ticketDetails.recipients"),
      value: emailMetaData.recipients,
      ticketModal: TicketsModal.RECIPIENTS,
    },
  ];
  const senderOrRecipient =
    violationDirection === ViolationDirection.ACCESS ? senderAndRecipient : recipient;

  return [
    ...senderOrRecipient,
    {
      titleKey: i18n.global.t("ticketDetails.subject"),
      value: escape(emailMetaData.subject),
    },
    {
      titleKey: i18n.global.t("ticketDetails.fileName"),
      value: emailMetaData.attachment?.fileName,
    },
    {
      titleKey: i18n.global.t("ticketDetails.fileSize"),
      value: convertSize(emailMetaData.attachment?.fileSize!, 1),
    },
    {
      titleKey: i18n.global.t("ticketDetails.internetMessageId"),
      value: escape(emailMetaData.internetMessageId),
    },
  ];
}

function mapExposureBySharing(ticket: TicketDetails): TicketKeyDetail[] {
  const filesMetaData = ticket.sections.filesMetaData?.[0];
  if (!filesMetaData) return [];
  // for old dlp events the default direction was SHARE
  const violationDirection = get(
    ticket,
    "sections.userDataAccessViolation.violationDirection",
    ViolationDirection.SHARE
  );
  const sharedByAndSharedWith = [
    {
      titleKey: i18n.global.t("ticketDetails.sharedBy"),
      value: filesMetaData.sharedBy,
    },
    {
      titleKey: i18n.global.t("ticketDetails.sharedWith"),
      value: filesMetaData.sharedWith,
      ticketModal: TicketsModal.SHARED_WITH,
    },
  ];
  const sharedWith = [
    {
      titleKey: i18n.global.t("ticketDetails.sharedWith"),
      value: filesMetaData.sharedWith,
      ticketModal: TicketsModal.SHARED_WITH,
    },
  ];
  const sharedWithOrBy =
    violationDirection === ViolationDirection.ACCESS ? sharedByAndSharedWith : sharedWith;

  return [
    ...sharedWithOrBy,
    {
      titleKey: i18n.global.t("ticketDetails.fileName"),
      value: filesMetaData.fileName,
    },
    {
      titleKey: i18n.global.t("ticketDetails.fileSize"),
      value: convertSize(filesMetaData.fileSize, 1),
    },
    {
      titleKey: i18n.global.t("ticketDetails.filePath"),
      value: filesMetaData.filePath || filesMetaData.path,
    },
    {
      titleKey: i18n.global.t("ticketDetails.owner"),
      value: filesMetaData.owner,
    },
    {
      titleKey: i18n.global.t("ticketDetails.lastModifiedBy"),
      value: filesMetaData.lastModifiedBy,
    },
    {
      titleKey: i18n.global.t("ticketDetails.lastModifiedTime"),
      value: getFormattedDateTime(filesMetaData.lastModifiedTime, "ll | LT"),
    },
  ];
}

function mapIpAddresses(ticket: TicketDetails): TicketKeyDetail[] {
  return [
    mapIps(ticket.sections.locations),
    {
      titleKey: i18n.global.t("ticketDetails.eventHappenedMessages.default"),
      value: i18n.global.t(
        "ticketDetails.times",
        {
          n: ticket.sections.counter,
        },
        ticket.sections.counter
      ),
    },
  ];
}

function mapMalwareOnEndpoint(ticket: TicketDetails): TicketKeyDetail[] {
  const malwareMetaData = ticket.sections.malwareMetaData;
  const keys: (keyof MalwareMetaData)[] = ["fileName", "path", "virusName", "fileHash"];
  const values = keys.map((key) => ({
    titleKey: i18n.global.t(`ticketDetails.${key}`),
    value: malwareMetaData[key],
  })) as TicketKeyDetail[];
  return [
    {
      titleKey: i18n.global.t("ticketDetails.threatType"),
      value: malwareMetaData.threatType
        ? i18n.global.t(`ticketDetails.threatTypes.${camelCase(malwareMetaData.threatType)}`)
        : undefined,
    },
    ...(values as TicketKeyDetail[]),
  ];
}

function mapInfectedProcess(ticket: TicketDetails): TicketKeyDetail<ProcessGroup>[] {
  const processMetaData = ticket.sections.infectedProccessMetaData;

  return [
    {
      titleKey: i18n.global.t("ticketDetails.processGroup"),
      loopWithIcon: true,
      value: processMetaData.processGroups.map(
        (group): ProcessGroup & { key: keyof ProcessGroup; icon?: string } => ({
          ...group,
          key: "path", // Ensure 'key' is a valid key of ProcessGroup
          icon: group.detected ? "icon-warning" : undefined,
        })
      ),
    },
    {
      titleKey: i18n.global.t("ticketDetails.suspiciousCharacteristics"),
      value: processMetaData.suspiciousCharacteristics, // This can be a string
    },
    {
      titleKey: i18n.global.t("ticketDetails.suspectedMaliciousProcessName"),
      value: processMetaData.suspectedMaliciousProcessName, // This can be a string
    },
  ];
}

function mapVSSBackupProtection(ticket: TicketDetails): TicketKeyDetail[] {
  const processMetaData = ticket.sections.infectedProccessMetaData;
  return [
    {
      titleKey: i18n.global.t("ticketDetails.suspiciousCharacteristics"),
      value: processMetaData.suspiciousCharacteristics,
    },
    {
      titleKey: i18n.global.t("ticketDetails.suspectedMaliciousProcessName"),
      value: processMetaData.suspectedMaliciousProcessName,
    },
  ];
}

function mapUnencryptedDrive(ticket: TicketDetails): TicketKeyDetail[] {
  const unencryptedFileStorage = ticket.sections.deviceMetaData.unencryptedFileStorage;
  if (!unencryptedFileStorage) return [];
  return [
    {
      titleKey: i18n.global.t("ticketDetails.driveName"),
      value: unencryptedFileStorage.name,
    },
    {
      titleKey: i18n.global.t("ticketDetails.driveModel"),
      value: unencryptedFileStorage.model,
    },
    {
      titleKey: i18n.global.t("ticketDetails.interfaceType"),
      value: unencryptedFileStorage.interfaceType,
    },
  ];
}

function mapDeviceDlp(ticket: TicketDetails): TicketKeyDetail[] {
  const scannedFileStorage = ticket.sections.deviceMetaData?.scannedFileStorage;
  if (!scannedFileStorage) return [];
  return [
    {
      titleKey: i18n.global.t("ticketDetails.driveName"),
      value: scannedFileStorage.name,
    },
    {
      titleKey: i18n.global.t("ticketDetails.driveModel"),
      value: scannedFileStorage.model,
    },
    {
      titleKey: i18n.global.t("ticketDetails.interfaceType"),
      value: scannedFileStorage.interfaceType,
    },
    mapIps(ticket.sections.locations),
  ];
}

function mapMalwareInCloudDrive(ticket: TicketDetails): TicketKeyDetail[] {
  const malwareMetaData = ticket.sections.malwareMetaData;
  if (malwareMetaData.virusName === "unknown") {
    const detectedBy = i18n.global.t(
      `ticketDetails.fullDetails.${camelCase(malwareMetaData.detectedBy)}`
    );
    malwareMetaData.virusName = i18n.global.t("ticketDetails.fullDetails.unknownDetectedBy", {
      detectedBy,
    });
  }
  const keys = ["fileName", "path", "virusName", "fileHash"] as const;
  return keys.map(
    (key): TicketKeyDetail => ({
      titleKey: i18n.global.t(`ticketDetails.${key}`),
      value: malwareMetaData[key],
    })
  );
}

function mapMalwareInEmail(ticket: TicketDetails): TicketKeyDetail[] {
  const emailMetaData = ticket.sections.emailMetaData;
  const malwareMetaData = ticket.sections.malwareMetaData;

  return [
    {
      titleKey: i18n.global.t("ticketDetails.senderName"),
      value: escape(emailMetaData.senderName),
    },
    {
      titleKey: i18n.global.t("ticketDetails.senderEmail"),
      value: escape(emailMetaData.senderEmail),
    },
    {
      titleKey: i18n.global.t("ticketDetails.senderIp"),
      value: escape(emailMetaData.senderIp),
    },
    {
      titleKey: i18n.global.t("ticketDetails.subject"),
      value: escape(emailMetaData.subject),
    },
    {
      titleKey: i18n.global.t("ticketDetails.internetMessageId"),
      value: escape(emailMetaData.internetMessageId),
    },
    {
      titleKey: i18n.global.t("ticketDetails.virusName"),
      value: malwareMetaData?.virusName,
    },
  ];
}

function mapEmailPhishing(ticket: TicketDetails): TicketKeyDetail[] {
  const emailMetaData = ticket.sections.emailMetaData;
  return [
    {
      titleKey: i18n.global.t("ticketDetails.subject"),
      value: escape(emailMetaData.subject),
    },
    {
      titleKey: i18n.global.t("ticketDetails.senderName"),
      value: escape(emailMetaData.senderName),
    },
    {
      titleKey: i18n.global.t("ticketDetails.senderEmail"),
      value: escape(emailMetaData.senderEmail),
    },
    {
      titleKey: i18n.global.t("ticketDetails.senderIp"),
      value: escape(emailMetaData.senderIp),
    },
    {
      titleKey: i18n.global.t("ticketDetails.internetMessageId"),
      value: escape(emailMetaData.internetMessageId),
    },
    mapIps(ticket.sections.locations),
  ];
}

function mapWifiPhishing(ticket: TicketDetails): TicketKeyDetail[] {
  const networkMetaData = ticket.sections.networkMetaData;
  return [
    {
      titleKey: i18n.global.t("ticketDetails.ssid"),
      value: networkMetaData.ssid,
    },
    {
      titleKey: i18n.global.t("ticketDetails.bssid"),
      value: networkMetaData.bssids,
      ticketModal: TicketsModal.BSSID_MODAL,
    },
    {
      titleKey: i18n.global.t("ticketDetails.security"),
      value: networkMetaData.networkSecurity,
    },
    {
      titleKey: i18n.global.t("ticketDetails.ticketDetails"),
      value: networkMetaData.indicators
        .map((explanation) => `<div>${i18n.global.t(`incidentsExplanations.${explanation}`)}</div>`)
        .join(),
    },
  ];
}
function mapWifiForbiddenNetwork(ticket: TicketDetails): TicketKeyDetail[] {
  const networkMetaData = ticket.sections.networkMetaData;
  return [
    {
      titleKey: i18n.global.t("ticketDetails.ssid"),
      value: networkMetaData.ssid,
    },
    {
      titleKey: i18n.global.t("ticketDetails.security"),
      value: networkMetaData.networkSecurity,
    },
  ];
}

function mapSimulatedEmailPhishing(ticket: TicketDetails): TicketKeyDetail[] {
  const metadata = ticket.sections.securityAwarenessMetaData!;

  return [
    {
      titleKey: i18n.global.t("ticketDetails.campaignName"),
      value: metadata.campaignName,
    },
    {
      titleKey: i18n.global.t("ticketDetails.emailTemplate"),
      value: metadata.emailTemplate,
    },
    {
      titleKey: i18n.global.t("ticketDetails.emailSender"),
      value: metadata.emailSender,
    },
    {
      titleKey: i18n.global.t("ticketDetails.attachmentType"),
      value: metadata.attachmentType,
    },
  ];
}

function mapSecurityTrainingFailure(ticket: TicketDetails): TicketKeyDetail[] {
  const metadata = ticket.sections.securityAwarenessMetaData!;

  return [
    {
      titleKey: i18n.global.t("ticketDetails.campaignName"),
      value: metadata.campaignName,
    },
    {
      titleKey: i18n.global.t("ticketDetails.contents"),
      value: metadata.securityTrainingContents,
      showItemsNumber: 3,
      ticketModal: TicketsModal.CONTENTS,
    },
  ];
}

function mapAbnormalDataManipulation(ticket: TicketDetails): TicketKeyDetail[] {
  return [mapIps(ticket.sections.locations)];
}

function mapSuspectedBotAttacks(ticket: TicketDetails): TicketKeyDetail[] {
  return [
    mapIps(ticket.sections.locations),
    {
      titleKey: i18n.global.t("ticketDetails.eventHappenedMessages.suspectedBotAttacks"),
      value: i18n.global.t(
        "ticketDetails.times",
        {
          n: ticket.sections.counter,
        },
        ticket.sections.counter
      ),
    },
  ];
}

function mapIps(ips: Location[]): TicketKeyDetail {
  return {
    titleKey: i18n.global.t("ticketDetails.ipAndCountry"),
    value: ips
      .map((location) => {
        if (location.regionName) {
          return `<div>${location.ip} (${location.regionName}, ${location.countryName})</div>`;
        }
        return `<div>${location.ip} (${location.countryName})</div>`;
      })
      .join(""),
  };
}

function mapDeviceGenericDetails(ticket: TicketDetails): TicketKeyDetail[] {
  return ticket.sections.locations.length ? [mapIps(ticket.sections.locations)] : [];
}
