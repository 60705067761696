<template>
  <div class="devices-page content-md margin-auto mt-4">
    <div>
      <v-breadcrumbs :items="breadcrumbsItems"></v-breadcrumbs>
    </div>
    <div class="headline5 settings-header-with-btn">
      {{ $t("network.title") }}
    </div>
    <v-skeleton-loader
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.OVERVIEW_WIDGETS"
    >
      <template v-if="vpnConnected">
        <page-tabs :tabs="tabs" :with-translation="false" class="nav-tabs mb-4"> </page-tabs>
        <router-view></router-view>
      </template>
      <network-initial-setup-settings v-else />
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { isAccessRestricted, isAddonDisabled } from "@/_helpers/utils";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { SubscriptionAddon } from "@/constants/workplaces";
import { RolePermissionsScope } from "@/_store/roles.module";
import { computed, defineComponent, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import PageTabs, { type CoroTab } from "@/components/PageTabs.vue";
import { storeToRefs } from "pinia";
import { useAccountStore } from "@/_store";
import { useVirtualOfficeStore } from "@/_store/network/virtual-office.module";
import NetworkInitialSetupSettings from "@/components/network/NetworkInitialSetupSettings.vue";
import { RouteName } from "@/constants/routes";

export default defineComponent({
  components: {
    NetworkInitialSetupSettings,
    PageTabs,
  },
  setup() {
    const i18n = useI18n();
    const { account } = storeToRefs(useAccountStore());
    const virtualOfficeStore = useVirtualOfficeStore();
    const { showSkeletonLoader, vpnConnected } = storeToRefs(virtualOfficeStore);
    const tabs = computed(() => {
      const tabsArray: CoroTab[] = [
        {
          id: 1,
          name: i18n.t("network.virtualOffice.title"),
          route: { name: RouteName.NETWORK_VIRTUAL_OFFICE_TAB },
        },
        {
          id: 2,
          name: i18n.t("network.settings.title"),
          route: { name: RouteName.NETWORK_SETTINGS_TAB },
        },
        {
          id: 2,
          name: i18n.t("network.siteToSite.title"),
          route: { name: RouteName.NETWORK_SITE_TO_SITE_TAB },
        },
      ];
      const showSwgTab =
        !isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionAddon.SWG) &&
        (account.value.showDisabledModules || !isAddonDisabled(SubscriptionAddon.SWG));
      if (showSwgTab) {
        tabsArray.push({
          id: 3,
          name: i18n.t("network.secureWebGateway.title"),
          route: { name: RouteName.NETWORK_SECURE_WEB_GATEWAY_TAB },
          disabled: isAddonDisabled(SubscriptionAddon.SWG),
        });
      }
      return tabsArray;
    });

    onMounted(async () => {
      await virtualOfficeStore.getVpnConnection();
    });

    return {
      account,
      showSkeletonLoader,
      vpnConnected,
      tabs,
      breadcrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
      coronetSkeletonLoaderTypes,
    };
  },
});
</script>
<style lang="scss" scoped>
:deep(*) {
  .bar.nav-tabs {
    .wrapper {
      z-index: 0 !important;
    }
  }
}
</style>
