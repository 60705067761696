import { defineStore } from "pinia";
import { useMyAccountStore } from "@/_store/my-account.module";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { MfaStatus } from "@/constants/account";

export interface MfaConfig {
  mfaConfig: MfaStatus;
  otpSecret: string;
  uri: string;
}

interface MfaState {
  mfaConfig: MfaConfig;
  phoneNumber: string;
  skeletonLoader: boolean;
  loading: boolean;
  mfaFlowToken: string;
}

const defaultMfaState = {
  mfaConfig: {} as MfaConfig,
  phoneNumber: "",
  skeletonLoader: false,
  loading: false,
  mfaFlowToken: "",
};

export const useMfaStore = defineStore("mfa", {
  state: (): MfaState => ({
    ...defaultMfaState,
  }),
  actions: {
    async toggleMFAConfig(payload?: { disable: boolean }) {
      this.skeletonLoader = true;
      try {
        const request = {
          ...api.mfaConfig,
        };
        const res = await axiosInstance.request(request);
        this.mfaConfig = res.data;
        this.skeletonLoader = false;
        this.$patch({
          mfaConfig: res.data,
        });
        if (payload?.disable) {
          const myAccountStore = useMyAccountStore(); // Assuming useMyAccountStore is your myAccount store
          await myAccountStore.getProfileData();
        }
      } catch (err) {
        console.error(err);
        this.skeletonLoader = false;
      }
    },

    async completeMFA(payload: string) {
      this.loading = true;
      try {
        const request = {
          ...api.completeMFA(payload),
        };
        await axiosInstance.request(request);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
        throw err;
      }
    },

    async addMFAFallback(phoneNumber: string) {
      this.loading = true;
      try {
        const request = {
          ...api.addMFAFallback,
          data: {
            phoneNumber,
          },
        };
        const res = await axiosInstance.request(request);
        this.mfaFlowToken = res.data;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },

    async getMFAFallback() {
      this.skeletonLoader = true;
      try {
        const request = {
          ...api.getMFAFallback,
        };
        const res = await axiosInstance.request(request);
        this.phoneNumber = res.data;
        this.skeletonLoader = false;
      } catch (err) {
        console.error(err);
        this.skeletonLoader = false;
      }
    },

    async completeMFAFallback(verificationCode: string) {
      this.loading = true;
      try {
        const request = {
          ...api.completeMFAFallback,
          data: {
            verificationCode,
            mfaFlowToken: this.mfaFlowToken,
          },
        };
        await axiosInstance.request(request);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },

    async verifyMFACode(verificationCode: string) {
      this.loading = true;
      try {
        const request = {
          ...api.verifyMFACode,
          data: {
            verificationCode,
            mfaFlowToken: this.mfaFlowToken,
          },
        };
        const res = await axiosInstance.request(request);
        this.loading = false;
        return res;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },

    async resetMFAConfig() {
      this.skeletonLoader = true;
      try {
        const request = {
          ...api.resetMFAConfig,
          data: {
            mfaFlowToken: this.mfaFlowToken,
          },
        };
        const res = await axiosInstance.request(request);
        this.mfaFlowToken = res.data;
        this.skeletonLoader = false;
        return res;
      } catch (err) {
        this.skeletonLoader = false;
        console.error(err);
      }
    },

    async completeMFAReset(verificationCode: string) {
      this.loading = true;
      try {
        const request = {
          ...api.completeMFAReset,
          data: {
            verificationCode,
            mfaFlowToken: this.mfaFlowToken,
          },
        };
        const res = await axiosInstance.request(request);
        this.loading = false;
        return res;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },

    async resendSMS() {
      try {
        const request = {
          ...api.resendSMS,
          data: {
            mfaFlowToken: this.mfaFlowToken,
          },
        };
        await axiosInstance.request(request);
      } catch (err) {
        console.error(err);
      }
    },

    resetState() {
      this.$reset();
    },
  },
});
