<template>
  <div class="headline6 mb-2" v-text="settings.region" />
  <div class="body1 mb-4">
    <span class="text--semitransparent mr-3">
      {{ $t("network.virtualOffice.ipAddresses", settings.servers.length) }}: {{ ips }}</span
    >
    <span v-if="hasManyServers" role="button" class="body2 coro-link" @click="onShowAllIpsClick"
      >{{ $t("general.viewAll") }} ›</span
    >
  </div>
  <v-card>
    <v-card-text class="px-8">
      <v-form ref="form">
        <v-switch
          :model-value="enabled"
          class="v-input--reverse"
          color="primary lighten-1"
          hide-details
          :ripple="false"
          @click.prevent="onActionClick({ action: ZtnaAction.TOGGLE_ZTNA })"
        >
          <template #label>
            <span
              class="subtitle1 text--black mr-2"
              v-text="$t('network.virtualOffice.ztna.ztnaActivationLabel')"
            />
          </template>
        </v-switch>
        <span class="body2" v-text="$t('network.virtualOffice.ztna.ztnaActivationDescription')"
      /></v-form>
    </v-card-text>
  </v-card>
  <div class="d-flex justify-space-between align-center mt-8 mb-4">
    <span class="subtitle1" v-text="$t('network.virtualOffice.ztna.ztnaResourceList')" />
    <v-menu>
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          class="add-btn"
          color="primary"
          rounded
          size="large"
          @click="onActionClick({ action: ZtnaAction.ADD_ZTNA_RULE })"
        >
          <v-icon icon="$add" color="white" />
          <span v-text="$t('general.add')" />
        </v-btn>
      </template>
    </v-menu>
  </div>

  <div v-if="!enabled" class="warning-block d-flex align-center my-4">
    <v-icon class="mr-3 ml-4" icon="$warning"> </v-icon>
    <span class="body2">{{ $t("network.virtualOffice.ztna.alert") }}</span>
  </div>

  <ztna-rules-table
    :pagination="pagination"
    :items="rules"
    :loading="loading"
    :total-items="rules.length"
    @page-change="onPageChange"
    @action-click="onActionClick"
  />
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, type PropType } from "vue";

import { useZtnaStore, type ZtnaRule } from "@/_store/network/ztna.module";
import { type DialogConfig, useDialogsStore } from "@/_store/dialogs.module";

import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
} from "@/_helpers/utils";

import AddZtnaRuleModal from "@/components/modals/network/AddZtnaRuleModal.vue";
import { ModalWidth } from "@/constants/modals";
import { ZtnaAction } from "@/constants/network";
import ZtnaRulesTable from "@/components/tables/ZtnaRulesTable.vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import type { VirtualOfficeSettings } from "@/_store/network/virtual-office.module";

export default defineComponent({
  components: { ZtnaRulesTable },
  props: {
    settings: {
      type: Object as PropType<VirtualOfficeSettings>,
      required: true,
    },
  },
  emits: ["show-all-ips"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { openDialog } = useDialogsStore();

    const ztnaStore = useZtnaStore();
    const { toggleZtnaEnabled, saveZtnaRule, getZtnaRules, updateZtnaRule, deleteZtnaRule } =
      useZtnaStore();

    const { pagination, rules, loading, enabled } = storeToRefs(ztnaStore);

    const onToggleZtnaEnabled = () => {
      toggleZtnaEnabled(!enabled.value);
    };

    const ips = computed(() => (props.settings?.servers || []).slice(0, 5).join(", "));

    const hasManyServers = computed((): boolean => props.settings?.servers.length > 4);

    const onShowAllIpsClick = () => {
      emit("show-all-ips");
    };
    const onPageChange = async (pageObj: { page: number; itemsPerPage: number }): Promise<void> => {
      ztnaStore.$patch({
        pagination: {
          page: pageObj.page - 1,
          pageSize: pageObj.itemsPerPage,
        },
      });
      await getZtnaRules();
    };

    const onActionClick = ({ action, item }: { action: ZtnaAction; item?: ZtnaRule }) => {
      let modalConfig;
      switch (action) {
        case ZtnaAction.ADD_ZTNA_RULE:
          modalConfig = {
            ...componentDialogsConfigConstructor({
              component: AddZtnaRuleModal,
              width: ModalWidth.MEDIUM,
              action: ZtnaAction.ADD_ZTNA_RULE,
              callback: saveZtnaRule,
            }),
          };
          break;
        case ZtnaAction.DELETE_ZTNA_RULE:
          modalConfig = {
            ...confirmationDialogsConfigConstructor({
              item,
              action,
              callback: () => deleteZtnaRule(item!),
              width: ModalWidth.SMALL,
            }),
            header: {
              title: t(`modals.${action}.title`),
              titleClass: "text-red-dark",
              titleIcon: "warning",
            },
          };
          break;
        case ZtnaAction.EDIT_ZTNA_RULE:
          modalConfig = {
            ...componentDialogsConfigConstructor({
              component: AddZtnaRuleModal,
              width: ModalWidth.MEDIUM,
              item,
              action: ZtnaAction.EDIT_ZTNA_RULE,
              callback: updateZtnaRule,
            }),
          };
          break;
        case ZtnaAction.TOGGLE_ZTNA:
          modalConfig = {
            ...confirmationDialogsConfigConstructor({
              callback: onToggleZtnaEnabled,
              width: ModalWidth.SMALL,
              action: enabled.value ? ZtnaAction.DISABLE_ZTNA : ZtnaAction.ENABLE_ZTNA,
              item: {},
            }),
            header: {
              title: t(
                `modals.${enabled.value ? ZtnaAction.DISABLE_ZTNA : ZtnaAction.ENABLE_ZTNA}.title`
              ),
              titleClass: "text-red-dark",
              titleIcon: "warning",
            },
          };
          break;
      }
      openDialog(modalConfig as DialogConfig);
    };

    onMounted(async () => {
      await getZtnaRules();
    });

    return {
      onShowAllIpsClick,
      hasManyServers,
      ips,
      enabled,
      onPageChange,
      onActionClick,
      onToggleZtnaEnabled,
      ZtnaAction,
      pagination,
      rules,
      loading,
    };
  },
});
</script>
