<template>
  <div class="content-md margin-auto mt-12">
    <v-skeleton-loader
      :loading="myAccount.skeletonLoader"
      :tile="true"
      :type="coronetSkeletonLoaderTypes.ACCOUNT_SETTINGS"
    >
      <div v-if="showName" class="d-flex align-center ml-4 mb-3">
        <div class="user-circle">{{ initials }}</div>
        <div class="headline5">
          {{ `${myAccount.profileData.firstName} ${myAccount.profileData.lastName}` }}
        </div>
      </div>

      <page-tabs :tabs="tabs" :with-translation="false" class="nav-tabs ml-4 mb-4" />
      <router-view></router-view>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from "pinia";
import { computed, defineComponent, onMounted } from "vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import PageTabs, { type CoroTab } from "@/components/PageTabs.vue";
import { useMyAccountStore } from "@/_store/my-account.module";
import { useI18n } from "vue-i18n";
import { RouteName } from "@/constants/routes";

export default defineComponent({
  components: { PageTabs },
  setup() {
    const i18n = useI18n();
    const myAccountStore = useMyAccountStore();
    const { getNotificationsSettings, getProfileData } = myAccountStore;
    const { myAccount } = storeToRefs(myAccountStore);

    const showName = computed(
      () => myAccount.value.profileData.firstName || myAccount.value.profileData.lastName
    );
    const initials = computed(() => {
      return (
        (myAccount.value.profileData?.firstName ?? "").substring(0, 1) +
        (myAccount.value.profileData?.lastName ?? "").substring(0, 1)
      );
    });
    const tabs = computed<CoroTab[]>(() => {
      const result: CoroTab[] = [
        {
          id: 1,
          name: i18n.t("myAccount.tabs.profile"),
          route: { name: RouteName.MY_ACCOUNT_PROFILE_TAB },
        },
        {
          id: 2,
          name: i18n.t("myAccount.tabs.password"),
          route: { name: RouteName.MY_ACCOUNT_CHANGE_PASSWORD_TAB },
        },
      ];
      if (myAccount.value.profileData?.allowMfa) {
        result.push({
          id: 3,
          name: i18n.t("myAccount.tabs.mfa"),
          route: { name: RouteName.MY_ACCOUNT_MFA_TAB },
        });
      }

      if (myAccount.value.notificationsSettings?.couldBeUpdated) {
        result.push({
          id: 4,
          name: i18n.t("myAccount.tabs.notifications"),
          route: { name: RouteName.MY_ACCOUNT_NOTIFICATIONS_TAB },
        });
      }
      return result;
    });

    onMounted(async () => {
      await getProfileData();
      await getNotificationsSettings();
    });
    return {
      initials,
      showName,
      tabs,
      myAccount,
      coronetSkeletonLoaderTypes,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .account-form__wrapper {
    max-width: 568px !important;
  }
}
</style>
