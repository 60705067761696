<template>
  <div class="content-md margin-auto">
    <v-skeleton-loader
      class="skeleton-loader--cloud-apps"
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.CLOUD_APPS_SETTINGS"
    >
      <v-breadcrumbs class="mt-2" :items="breadCrumbsItems" divider="<"></v-breadcrumbs>
      <div class="settings-header-with-btn">
        <div class="headline5">{{ $t("cloudApplications.title") }}</div>
        <v-btn
          color="primary"
          rounded
          density="default"
          size="large"
          v-if="notConnectedServices.length"
          @click="openModal(ServiceAction.CONNECT, {})"
        >
          <v-icon class="mr-2" icon="$add" />
          {{ $t("cloudApplications.connect") }}
        </v-btn>
      </div>
      <div v-if="hasConnectedServices" class="setting-description mb-4">
        {{ $t("cloudApplications.description") }}
      </div>
      <v-card v-if="hasConnectedServices">
        <table-wrapper class="service-table__wrapper">
          <v-table>
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("cloudApplications.cloudApplication") }}
                  </th>
                  <th class="text-left">
                    {{ $t("cloudApplications.protectedUsers") }}
                  </th>
                  <th class="text-left">
                    {{ $t("cloudApplications.thirdPartyApps") }}
                  </th>
                  <th class="text-left">
                    {{ $t("cloudApplications.connectionStatus") }}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in filterServicesByStatus(true)"
                  :key="item.name"
                  class="service-row"
                  :data-testid="`service-row-${item.name}`"
                >
                  <td>
                    <div class="d-flex">
                      <coro-icon class="service-icon mr-4" :icon-name="item.name"></coro-icon>
                      <div class="subtitle1 service-name d-flex align-center">
                        {{ $t(`cloudApplications.${item.name}`) }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <router-link :to="getProtectedUsersRedirectLink(item.name)" class="coro-link">
                      {{
                        $t("cloudApplications.numberProtectedUsers", {
                          n: item.activeProtectedUsers + item.inactiveProtectedUsers || 0,
                        })
                      }}
                    </router-link>
                    <div class="text-indigo-medium">
                      {{
                        $t("cloudApplications.activeUsers", {
                          n: item.activeProtectedUsers || 0,
                        })
                      }}
                      /
                      {{
                        $t("cloudApplications.inactiveUsers", {
                          n: item.inactiveProtectedUsers || 0,
                        })
                      }}
                    </div>
                  </td>
                  <td>
                    <router-link
                      v-if="isMicrosoftOrGoogle(item.name)"
                      :to="getThirdPartyAppsRedirectLink(item.name)"
                      class="coro-link"
                    >
                      {{
                        $t("cloudApplications.numberConnected", {
                          n: item.connectedThirdPartyApps || 0,
                        })
                      }}
                    </router-link>
                  </td>
                  <td>
                    <div
                      v-if="item.serviceStatus !== ServiceStatus.INCOMPLETE"
                      class="inner-circle mr-4"
                      :class="getConnectionStatusColorClass(item.serviceStatus)"
                    ></div>
                    <span
                      :class="{
                        'coro-link':
                          item.serviceStatus !== ServiceStatus.INCOMPLETE &&
                          isMicrosoftOrGoogle(item.name),
                        'text-indigo-medium': item.serviceStatus === ServiceStatus.INCOMPLETE,
                      }"
                      @click="openConnectionStatusDialog(item)"
                    >
                      {{ $t(`services.status.${item.serviceStatus}`) }}
                    </span>
                  </td>
                  <td class="text-right" @click="$event.stopPropagation()">
                    <v-menu bottom left>
                      <template #activator="{ props }">
                        <v-icon icon="$dots" v-bind="props" />
                      </template>

                      <v-list>
                        <template v-for="(action, index) in getActionsList(item)" :key="index">
                          <v-list-item @click="openModal(action, item)">
                            <span v-text="$t(`cloudApplications.buttons.${action}`)" />
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-table>
        </table-wrapper>
      </v-card>

      <div v-if="filterServicesByStatus(false).length" class="subtitle1 mt-6">
        {{ $t("cloudApplications.connectCloudApplication") }}
      </div>
      <v-chip-group v-model="applicationToConnect" class="justify-center mt-4 mb-6">
        <v-chip
          v-for="item in filterServicesByStatus(false)"
          :key="item.name"
          :value="item.name"
          variant="outlined"
          :disabled="actionNotAllowed"
        >
          <div
            class="d-flex flex-column align-center w-100 provider-selection-chip"
            :data-testid="`${item.name}-chip`"
          >
            <coro-icon class="service-icon" :icon-name="item.name"></coro-icon>
            <div class="subtitle1">
              {{ $t(`cloudApplications.${item.name}`) }}
            </div>
          </div>
        </v-chip>
      </v-chip-group>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import TableWrapper from "@/components/TableWrapper.vue";
import CoroIcon from "@/components/CoroIcon.vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { type CloudAppService, useCloudAppsStore } from "@/_store/cloud-apps/cloud-apps.module";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { Service, ServiceAction, ServiceStatus } from "@/constants/cloud-apps";
import { ModalWidth } from "@/constants/modals";
import { useDialogsStore } from "@/_store/dialogs.module";
import ConnectServiceModal from "@/views/settings/cloud-apps/modals/ConnectServiceModal.vue";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/constants/routes";
import CloudAppConnectionStatusModal from "@/views/settings/cloud-apps/modals/CloudAppConnectionStatusModal.vue";
import type { WorkspaceLocale } from "@/constants/workplaces";

export interface ConnectAppModalConfigItem extends CloudAppService {
  partialScan: boolean;
  signup?: boolean;
  languageCode?: WorkspaceLocale;
}

export interface ConnectAppModalConfig {
  action: ServiceAction;
  item: Partial<ConnectAppModalConfigItem>;
}

export default defineComponent({
  components: {
    TableWrapper,
    CoroIcon,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const cloudAppsStore = useCloudAppsStore();
    const { showSkeletonLoader, services, notConnectedServices } = storeToRefs(cloudAppsStore);
    const dialogsStore = useDialogsStore();
    const { getServices } = cloudAppsStore;

    const applicationToConnect = ref();
    const i18n = useI18n();

    const hasConnectedServices = computed(() => {
      return services.value.some(
        (service) => service.serviceStatus !== ServiceStatus.NOT_CONNECTED
      );
    });

    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.CLOUD_APPS
      );
    });

    watch(applicationToConnect, (val) => {
      if (!val) {
        return;
      }

      openModal(ServiceAction.CONNECT, {
        name: val,
      });

      applicationToConnect.value = undefined;
    });

    const openModal = (action: ServiceAction, item: Partial<ConnectAppModalConfigItem>) => {
      switch (action) {
        case ServiceAction.GRANT_PRIVILEGES:
        case ServiceAction.CONNECT: {
          const config = componentDialogsConfigConstructor({
            width: ModalWidth.LARGE,
            action,
            item: {
              ...item,
              partialScan: true,
            },
            component: ConnectServiceModal,
            disable: actionNotAllowed.value,
            callback: () => {},
            closeCallback: () => {
              router.replace({ query: {} });
            },
          });

          dialogsStore.openDialog({
            ...config,
            header: {
              title: i18n.t(`modals.${ServiceAction.CONNECT}.noServiceTitle`),
              close: true,
            },
            footer: null,
          });
          break;
        }
        case ServiceAction.REMOVE:
        case ServiceAction.DISCONNECT: {
          dialogsStore.openDialog({
            ...confirmationDialogsConfigConstructor({
              action: ServiceAction.DISCONNECT,
              text: i18n.t(`modals.${ServiceAction.DISCONNECT}.description`, {
                service: i18n.t(`services.${item.name}`),
              }),
              callback: () => cloudAppsStore.disconnect(item.name!),
              disable: actionNotAllowed.value,
            }),
          });
          break;
        }
      }
    };

    onMounted(async () => {
      await getServices();

      if (route.query.isNextStep === "true" && route.query.grantPermissions === "true") {
        openModal(ServiceAction.GRANT_PRIVILEGES, { name: route.query.service as Service });

        return;
      }

      if (route.query.service === Service.OFFICE_365 && route.query.grantPermissions === "true") {
        openModal(ServiceAction.CONNECT, {
          name: route.query.service as Service,
          serviceStatus: ServiceStatus.CONNECTED,
        });

        return;
      }

      if (route.query.isNextStep === "true") {
        openModal(ServiceAction.CONNECT, { name: route.query.service as Service });

        return;
      }

      if (route.query.noServicesConnected === "true") {
        openModal(ServiceAction.CONNECT, {});

        router.replace({ query: {} });

        return;
      }

      if ([Service.DROPBOX, Service.SLACK].includes(route.query.service as Service)) {
        openModal(ServiceAction.CONNECT, {
          name: route.query.service as Service,
          serviceStatus: ServiceStatus.CONNECTED,
        });
      }
    });

    const isConnected = (serviceStatus: ServiceStatus) => {
      return serviceStatus === ServiceStatus.CONNECTED;
    };

    const isIncompleteOrDisconnected = (serviceStatus: ServiceStatus) => {
      return [ServiceStatus.INCOMPLETE, ServiceStatus.DISCONNECTED].includes(serviceStatus);
    };

    const filterServicesByStatus = (shouldBeConnected: boolean) => {
      return services.value.filter(({ serviceStatus }) =>
        shouldBeConnected
          ? serviceStatus !== ServiceStatus.NOT_CONNECTED
          : serviceStatus === ServiceStatus.NOT_CONNECTED
      );
    };

    const getConnectionStatusColorClass = (connectionStatus: ServiceStatus) => {
      return {
        "border-0 bg-green-dark": connectionStatus === ServiceStatus.CONNECTED,
        "border-0 bg-yellow-light": connectionStatus === ServiceStatus.CONNECTED_NOT_SECURE,
        "border-0 bg-red-dark": connectionStatus === ServiceStatus.DISCONNECTED,
      };
    };

    const getActionsList = (item: CloudAppService) => {
      if ([ServiceStatus.INCOMPLETE, ServiceStatus.DISCONNECTED].includes(item.serviceStatus)) {
        return [ServiceAction.CONNECT, ServiceAction.REMOVE];
      }

      if (
        [ServiceStatus.CONNECTED, ServiceStatus.CONNECTED_NOT_SECURE].includes(item.serviceStatus)
      ) {
        return [ServiceAction.DISCONNECT];
      }

      return [];
    };

    const openConnectionStatusDialog = (item: CloudAppService) => {
      if (!isMicrosoftOrGoogle(item.name) || item.serviceStatus === ServiceStatus.INCOMPLETE) {
        return;
      }

      const config = componentDialogsConfigConstructor({
        width: ModalWidth.LARGE,
        action: ServiceAction.CONNECTION_STATUS,
        item,
        component: CloudAppConnectionStatusModal,
        disable: false,
        callback: () => {},
        hideFooter: true,
      });

      dialogsStore.openDialog(config);
    };

    const getThirdPartyAppsRedirectLink = (name: Service) => {
      return {
        name: RouteName.CLOUD_SECURITY_THIRD_PARTY_APPS_TAB,
        query: {
          cloudApplications: name,
        },
      };
    };

    const getProtectedUsersRedirectLink = (name: Service) => {
      return {
        name: RouteName.USERS_SETTINGS_USERS_TAB,
        query: {
          connectedApps: name,
        },
      };
    };

    const isMicrosoftOrGoogle = (name: Service) => {
      return [Service.G_SUITE, Service.OFFICE_365].includes(name);
    };

    return {
      isMicrosoftOrGoogle,
      getThirdPartyAppsRedirectLink,
      getActionsList,
      isConnected,
      openConnectionStatusDialog,
      isIncompleteOrDisconnected,
      openModal,
      getProtectedUsersRedirectLink,
      applicationToConnect,
      showSkeletonLoader,
      getConnectionStatusColorClass,
      notConnectedServices,
      ServiceAction,
      RouteName,
      services,
      ServiceStatus,
      coronetSkeletonLoaderTypes,
      hasConnectedServices,
      actionNotAllowed,
      filterServicesByStatus,
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .service-row {
    height: 80px;
  }

  .v-slide-group__content {
    flex-wrap: wrap;
    width: 100%;
  }
}

.service-icon {
  height: 48px;
  width: 48px;
}

.service-name {
  font-size: 18px;
  line-height: 24px;
}

.service-status {
  font-size: 16px;
  color: rgb(var(--v-theme-indigo-medium));
}

.v-chip {
  width: 275px;
  height: 160px !important;
  border-radius: 6px;
  background-color: rgb(var(--v-theme-white));
  border: 0;
  justify-content: center;
}

.v-chip .v-chip__content {
  width: 100% !important;
  text-align: center;
}

.v-chip-group .v-chip--active {
  border: 2px solid rgb(var(--v-theme-primary)) !important;
}

.status-color {
  &-connected {
    border: 0;
    background-color: rgb(var(--v-theme-green-base));
  }
  &-connectedNotSecure {
    border: 0;
    background-color: rgb(var(--v-theme-yellow-light));
  }
  &-disconnected {
    border: 0;
    background-color: rgb(var(--v-theme-red-dark));
  }
}
</style>
