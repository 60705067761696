import {
  ActionTypeTarget,
  DeviceAction,
  type DriveEncryptionStatus,
  DuplicateDeviceMergeState,
  type IsolationStatus,
  type OsType,
} from "@/constants/devices";
import { type DeviceLabel, useDevicesSettingsStore } from "@/_store/devices-settings.module";
import type { ActivityLogItem } from "@/_store/activity-logs.module";
import type { EntityTicket } from "@/components/EntityTicketsList.vue";
import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import { useFiltersStore } from "@/_store/filters.module";
import { getSelectionAsStringsArray, isAccessRestricted } from "@/_helpers/utils";
import { i18n } from "@/plugins/i18n";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { useSnackbarStore } from "@/_store/snackbar.module";
import { type ISelection, useSelectorStore } from "@/_store/selector.module";
import { useTerminalStore } from "@/_store/terminal.module";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import type { Pagination } from "@/types";
import type { TicketTrigger } from "@/constants/tickets";
import { SubscriptionModule } from "@/constants/workplaces";
import type { Selection } from "@/types";

export interface DeviceListItem {
  id: string;
  hostName: string;
  osType?: OsType;
  osDisplayName: string;
  enrollmentCode: string;
  hasOpenTickets: boolean;
  vulnerabilities: TicketTrigger[];
  blockModeEnabled: boolean;
  antiTamperingProtectionEnabled: boolean;
  tamperResistanceDisabledByInstaller: boolean;
  workspaceId: string;
  appVersion: string;
  malwareScanInProgress: boolean;
  lastLoggedInUsers: string[];
  isolationStatus: IsolationStatus;
  dlpScanInProgress: boolean;
  labels: DeviceLabel[];
  outdated: boolean;
  offline: boolean;
  removed: boolean;
  marketingVersion: string;
  email?: string;
}

export interface DeviceDetails {
  name: string;
  email: string;
  enrollmentCode: string;
  device: {
    id: string;
    model: string;
    hostname: string;
  };
  lastActivity: number;
  vulnerabilities: string[];
  removed: boolean;
  firstActivity: number;
  lastRemoteScan: number;
  antiTamperingProtectionEnabled?: boolean;
  malwareScanInProgress: boolean;
  logsUploaded: boolean;
  blockModeEnabled: boolean;
  rebootRequired: boolean;
  lastLogsUploadedAt: number;
  activationTime: number;
  appVersion: string;
  osType?: OsType;
  osVersion?: string;
  osDisplayName: string;
  lastLoggedInUsers: string[];
  drives: DeviceDrive[];
  isolationStatus?: IsolationStatus;
  dlpScanInProgress: boolean;
  labels: DeviceLabel[];
  vpnIp: string;
  offline: boolean;
  appOutdated: boolean;
  marketingVersion: string;
  deviceSerialNumber?: string;
  lastKnownIp?: string;
  deviceModelId?: string;
  tickets: EntityTicket[];
  activityLogs: ActivityLogItem[];
  upn?: string;
  machineId?: string;
  mergeState?: DuplicateDeviceMergeState;
}

export interface DeviceDrive {
  isRemovable: boolean;
  name: string;
  status: DriveEncryptionStatus;
  password?: string;
  encryptionInProgress?: boolean;
  model?: string;
  serialKey?: string;
  friendlyName: string;
}

export type DeviceStatusFilterItem = ({ name: string; color: string; id: string } | DeviceLabel) & {
  statusLabel: boolean;
};

export interface DeviceOsOrClientVersions {
  windows?: string[];
  osx?: string[];
  android?: string[];
  ios?: string[];
  linux?: string[];
}

export interface DevicesState {
  devices: DeviceListItem[];
  statuses: DeviceStatusFilterItem[];
  pagination: Pagination;
  totalDevices: number;
  deviceDetailsLoading: boolean;
  activityLogsLoading: boolean;
  clientVersions: DeviceOsOrClientVersions;
  osVersions: DeviceOsOrClientVersions;
  deviceDrivesLoading: boolean;
  deviceDetails: DeviceDetails;
  showSkeletonLoader: boolean;
  loading: boolean;
}

const defaultDevicesSettingsState: DevicesState = {
  statuses: [],
  devices: [],
  pagination: {
    page: 0,
    pageSize: 25,
  },
  showSkeletonLoader: true,
  loading: true,
  totalDevices: 0,
  deviceDetailsLoading: false,
  activityLogsLoading: false,
  clientVersions: {},
  osVersions: {},
  deviceDrivesLoading: false,
  deviceDetails: {
    antiTamperingProtectionEnabled: false,
    appOutdated: false,
    blockModeEnabled: false,
    deviceModelId: "",
    deviceSerialNumber: "",
    dlpScanInProgress: false,
    isolationStatus: undefined,
    labels: [],
    lastKnownIp: "",
    lastLogsUploadedAt: 0,
    logsUploaded: false,
    machineId: "",
    malwareScanInProgress: false,
    marketingVersion: "",
    offline: false,
    osDisplayName: "",
    osVersion: "",
    rebootRequired: false,
    removed: false,
    upn: "",
    vpnIp: "",
    name: "",
    email: "",
    enrollmentCode: "",
    osType: undefined,
    lastActivity: 0,
    firstActivity: 0,
    lastRemoteScan: 0,
    appVersion: "",
    vulnerabilities: [],
    device: { id: "", model: "", hostname: "" },
    tickets: [],
    activityLogs: [],
    lastLoggedInUsers: [],
    drives: [],
    activationTime: 0,
  },
};

export const useDevicesStore = defineStore("devices", {
  state: () => ({ ...defaultDevicesSettingsState }),
  actions: {
    setPagination(pagination: Pagination) {
      this.pagination = pagination;
    },
    resetPagination() {
      this.pagination = { ...defaultDevicesSettingsState.pagination };
    },
    async init() {
      const deviceSettingsStore = useDevicesSettingsStore();
      return await Promise.all([
        deviceSettingsStore.getDevicesSettings(),
        deviceSettingsStore.getLabels(),
        this.getOSVersions(),
        this.getClientVersions(),
        this.getDeviceStatuses(),
      ]);
    },
    async getDevices(showSkeletonLoader = false) {
      this.loading = true;
      this.showSkeletonLoader = showSkeletonLoader;
      const filtersStore = useFiltersStore();
      const {
        hasVulnerabilities,
        osType,
        osVersion,
        clientVersion,
        vulnerabilities,
        search,
        labels: groups,
        widget,
      } = filtersStore.filters.devicesFilters;
      const { labels, deviceStatuses } = convertDevicesGroupsFilter(groups);
      const request = {
        ...api.devices(),
        params: {
          vulnerabilities: vulnerabilities?.join(","),
          hasVulnerabilities,
          osType,
          osVersion,
          deviceStatuses: deviceStatuses?.join(","),
          clientVersion,
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
          labels: labels?.join(","),
          search,
          widget,
        },
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.devices = data.items;
        this.totalDevices = data.total;
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
      this.showSkeletonLoader = false;
    },
    async getDeviceDetails(enrollmentCode: string) {
      this.deviceDetailsLoading = true;

      const deviceInfoRequest = {
        ...api.getDevice(enrollmentCode),
      };
      const deviceTicketsRequest = {
        ...api.getDeviceTickets(enrollmentCode),
      };
      const deviceActivityLogsRequest = {
        ...api.getDeviceActivityLogs(enrollmentCode),
        params: {
          page: 0,
          pageSize: 5,
        },
      };
      const activityLogsAccessRestricted = isAccessRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.ACTIVITY_LOGS
      );
      const ticketsRestricted = (
        [
          SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
          SubscriptionModule.EDR,
          SubscriptionModule.ENDPOINT_SECURITY,
        ] as const
      ).every((module) => isAccessRestricted(RolePermissionsScope.TICKETS, module));
      Promise.all([
        axiosInstance.request(deviceInfoRequest),
        ticketsRestricted ? null : axiosInstance.request(deviceTicketsRequest),
        activityLogsAccessRestricted ? null : axiosInstance.request(deviceActivityLogsRequest),
      ])
        .then(([deviceInfoResponse, deviceTicketsResponse, deviceActivityLogsResponse]) => {
          const activityLogs = deviceActivityLogsResponse
            ? [...deviceActivityLogsResponse.data.items]
            : [];
          this.deviceDetails = {
            ...deviceInfoResponse.data,
            tickets: deviceTicketsResponse ? [...deviceTicketsResponse.data] : [],
            activityLogs,
          };
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.deviceDetailsLoading = false;
        });
    },
    async undoActivityLog(enrollmentCode: string, activityLogId: string) {
      this.activityLogsLoading = true;

      try {
        const request = { ...api.activityLogsUndo(activityLogId) };

        await axiosInstance.request(request);

        const deviceActivityLogsRequest = {
          ...api.getDeviceActivityLogs(enrollmentCode),
          params: {
            page: 0,
            pageSize: 5,
          },
        };

        const { data } = await axiosInstance.request(deviceActivityLogsRequest);
        this.deviceDetails.activityLogs = data.items;
        await this.getDeviceDetails(enrollmentCode);
      } catch (error) {
        console.error(error);
      }
      this.activityLogsLoading = false;
    },
    async downloadLogs(deviceId: string) {
      const request: AxiosRequestConfig = {
        ...api.getDevicesLogs(deviceId),
        responseType: "arraybuffer",
      };
      useSnackbarStore().addGenericSuccess(i18n.global.t("snackbar.messages.devices.downloadLogs"));

      axiosInstance
        .request(request)
        .then(async (response) => {
          const blob = new Blob([response.data], { type: "octet/stream" });
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.setAttribute("href", downloadUrl);
          link.setAttribute("download", `logs-${deviceId}.zip`);
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async openRemoteShellSession(payload: {
      action: DeviceAction;
      selection: ISelection<DeviceListItem>;
      sshSessionId?: string;
    }) {
      const requestData = {
        action: payload.action,
        selection: getSelectionAsStringsArray(payload.selection, "enrollmentCode"),
      };
      const request = {
        ...api.devicesAction,
        data: requestData,
      };

      return axiosInstance
        .request(request)
        .then((response) => {
          const firstDeviceEnrollmentCode = Object.keys(response.data.actionResults)[0];
          const terminalStore = useTerminalStore();
          terminalStore.setSessionId(response.data.actionResults[firstDeviceEnrollmentCode]);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async closeRemoteShellSession({
      action,
      selection,
      sshSessionId,
    }: {
      action: DeviceAction;
      selection: ISelection;
      sshSessionId: string;
    }) {
      const requestData = {
        action,
        selection: getSelectionAsStringsArray(selection, "enrollmentCode"),
        metadata: {
          sshSessionId,
        },
      };
      const request = {
        ...api.devicesAction,
        data: requestData,
      };
      useTerminalStore().resetState();
      return axiosInstance.request(request).catch((err) => {
        console.error(err);
      });
    },
    async applyDeviceAction(payload: {
      action?: DeviceAction;
      item: {
        action: DeviceAction;
        selection: ISelection;
        partialScan?: boolean;
        metadata?: {
          [key: string]: unknown;
        };
      };
    }) {
      const action = payload.action || payload.item.action;
      const snackbarMessage = (quantity: number) =>
        i18n.global.t(`snackbar.messages.event.${action}`, { quantity }, quantity);
      const filtersStore = useFiltersStore();
      const devicesFilters = { ...filtersStore.filters.devicesFilters };
      const { labels, deviceStatuses } = convertDevicesGroupsFilter(devicesFilters.labels);
      const requestData: {
        action: DeviceAction;
        selection: ISelection;
        filter?: { [key: string]: unknown };
        metadata?: { [key: string]: unknown };
      } = {
        action,
        selection: getSelectionAsStringsArray(payload.item.selection, "enrollmentCode"),
        filter: {
          ...devicesFilters,
          labels,
          deviceStatuses,
        },
      };
      if (payload.item.metadata) {
        requestData.metadata = payload.item.metadata;
      }
      if (action === DeviceAction.DLP_PARTIAL_SCAN && !payload.item.partialScan) {
        requestData.action = DeviceAction.DLP_FULL_SCAN;
      }

      const request = {
        ...api.devicesAction,
        data: requestData,
      };
      try {
        const { data } = await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(snackbarMessage(data.appliedActions));
        await this.getDevices();
        useSelectorStore().deselectAllPages();
      } catch (e) {
        console.error(e);
      }
    },
    async exportDevicesToCsv(payload: {
      item: {
        selection: ISelection;
      };
    }) {
      const filtersStore = useFiltersStore();
      const devicesFilters = { ...filtersStore.filters.devicesFilters };
      const { labels, deviceStatuses } = convertDevicesGroupsFilter(devicesFilters.labels);
      const requestData: {
        selection: ISelection;
        filter: { [key: string]: unknown };
      } = {
        selection: getSelectionAsStringsArray(payload.item.selection, "enrollmentCode"),
        filter: {
          ...devicesFilters,
          labels,
          deviceStatuses,
        },
      };
      const request = {
        ...api.devicesCsvExport,
        data: requestData,
      };
      try {
        await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.general.exportCsvStarted")
        );
        useSelectorStore().deselectAllPages();
      } catch (e) {
        console.error(e);
      }
    },
    async getOSVersions() {
      const request = {
        ...api.devicesOsVersions,
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.osVersions = data;
      } catch (err) {
        console.error(err);
      }
    },
    async getClientVersions() {
      const request = {
        ...api.devicesClientVersions,
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.clientVersions = data;
      } catch (err) {
        console.error(err);
      }
    },
    async encryptDrive(payload: { enrollmentCode: string; driveName: string }) {
      const actionPayload = {
        action: DeviceAction.ENCRYPT_DRIVE,
        item: {
          action: DeviceAction.ENCRYPT_DRIVE,
          selection: {
            isAllPages: false,
            include: [{ enrollmentCode: payload.enrollmentCode }],
            exclude: [],
          },
          metadata: {
            driveLetter: payload.driveName,
          },
        },
      };
      await this.applyDeviceAction(actionPayload);
    },
    async getDeviceStatuses() {
      const request = {
        ...api.devicesStatuses,
      };
      try {
        const { data } = await axiosInstance.request(request);
        // create an ID and a flag that will indicate this is a status label as statuses are used in same dropdown as labels
        this.statuses = (data ?? []).map((v: Pick<DeviceStatusFilterItem, "name" | "color">) => ({
          ...v,
          id: v.name,
          statusLabel: true,
        }));
      } catch (err) {
        console.error(err);
      }
    },
    async getDeviceAdvancedInfo(enrollmentCode: string) {
      const request = {
        ...api.deviceAdvancedInfo(enrollmentCode),
      };

      try {
        const { data } = await axiosInstance.request(request);
        const blob = new Blob([data], { type: "text/xml" });
        const url = URL.createObjectURL(blob);
        window.open(url);
        URL.revokeObjectURL(url);
      } catch (err) {
        console.error(err);
      }
    },
    async mergeDuplicateDevices(enrollmentCode: string) {
      const request = {
        ...api.mergeDuplicateDevices(enrollmentCode),
      };

      try {
        await axiosInstance.request(request);
        await this.getDeviceDetails(enrollmentCode);
        await this.getDevices();
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.devices.mergeSuccessful")
        );
      } catch (err) {
        console.error(err);
      }
    },
    async ignoreDuplicateDevicesMerge(enrollmentCode: string) {
      const request = {
        ...api.ignoreDuplicateDevicesMerge(enrollmentCode),
      };

      try {
        await axiosInstance.request(request);
        await this.getDeviceDetails(enrollmentCode);
      } catch (err) {
        console.error(err);
      }
    },
    getActions({
      selection,
      target,
    }: {
      selection: Selection<DeviceListItem>;
      target: ActionTypeTarget;
    }): Promise<AxiosResponse<{ items: DeviceAction[] }>> {
      const filtersStore = useFiltersStore();
      const {
        hasVulnerabilities,
        osType,
        osVersion,
        clientVersion,
        vulnerabilities,
        search,
        labels: groups,
        widget,
      } = filtersStore.filters.devicesFilters;
      const { labels, deviceStatuses } = convertDevicesGroupsFilter(groups);
      const request = {
        ...api.availableDeviceActions,
        data: {
          filters: {
            vulnerabilities,
            hasVulnerabilities,
            osType,
            osVersion,
            deviceStatuses,
            clientVersion,
            labels,
            search,
            widget,
          },
          selection: {
            ...getSelectionAsStringsArray(selection, "enrollmentCode"),
          },
          target,
        },
      };

      return axiosInstance.request(request);
    },
  },
});

export function convertDevicesGroupsFilter(groups: DeviceStatusFilterItem[] | undefined = []): {
  deviceStatuses: string[];
  labels: string[];
} {
  const deviceStatuses = (groups ?? []).filter((group) => group.statusLabel).map((v) => v.name);
  const labels = (groups ?? []).filter((group) => !group.statusLabel).map((v) => v.id);
  return {
    deviceStatuses,
    labels,
  };
}
